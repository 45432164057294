import * as actionTypes from "../actions/actionTypes";

const initialState = {
  tutors: [],
  isLoading: false
};

const quickCallsFavtTutorReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_FAVT_TUTOR:
      return {
        ...state,
        isLoading: true, // Set isLoading to true when the action is triggered
        tutors: [action.payload.tutors],
      };

    case actionTypes.GET_FAVT_TUTOR_SUCCESS:
      return {
        ...state,
        isLoading: false, // Set isLoading to false when the action is successful
        tutors: [action.payload.tutors],
      };

    case actionTypes.GET_FAVT_TUTOR_FAILURE:
      return {
        ...state,
        isLoading: false, // Set isLoading to false when the action fails
        error: action.payload.error
      };

    default:
      return state;
  }
};

export default quickCallsFavtTutorReducer;
