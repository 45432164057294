import * as actionTypes from "./actionTypes";
import * as apiCall from "../services/api";
import { API_BASE_URL } from "../config/apiConfig";

const isObjectEmpty = (objectName) => {
  return Object.keys(objectName).length === 0
}

const getQuickCallsRecentSessionsSuccess = (data) => {
  const noRecentSessions = isObjectEmpty(data.Data)
  return {
    type: actionTypes.GET_QUICK_CALLS_RECENT_SESSIONS,
    payload: {
      sessions: noRecentSessions ? [] : data.Data,
    },
  };
};

export const getQuickCallsRecentSessions = () => async (dispatch) => {
  return await apiCall
    .axiosGet(`${API_BASE_URL}/GetStudentQuickCall`)
    .then((response) => {
      if (response) {
        dispatch(getQuickCallsRecentSessionsSuccess(response.data));
      }
    })
    .catch((error) => {
      console.log('Error: ', error);
    });
};

export const toggleStatus=(tutorID)=> {
  console.log(tutorID)
  return {
    type: actionTypes.TOGGLE_TUTOR_ONLINE_STATUS,
    payload: {
      TutorID: tutorID,
    },
  };
}

