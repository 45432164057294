import React, { useState } from 'react';
import './EditProfile.css';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import Paper from '@mui/material/Paper';
import LogoutIcon from '@mui/icons-material/Logout';
import {
	Button, Checkbox, FormControl, FormControlLabel, FormLabel,
	InputAdornment, OutlinedInput,
	Radio, RadioGroup, Select
} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExamBoard } from '../../actions/getExamBoardAction';
import { getClassesList } from '../../actions/getClassesAction';
import { getTargetExam } from '../../actions/getTargetExamAction';
import { getStudentProfile } from '../../actions/studentProfileAction';
import { getAppLanguage } from '../../actions/getAppLanguageAction';
import { getTeachingLanguage } from '../../actions/getTeachingLanguageAction';
import { updateStudentProfile } from '../../actions/updateStudentProfileAction';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';
import useTranslations from '../../custom-hooks/useTranslations';
import YasaHeader from '../common-components/YasaHeader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditRegistration = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	// const examBoard = useSelector(({ getExamBoardReducer }) => getExamBoardReducer.board);
	const classes = useSelector(({ getClassesListReducer }) => getClassesListReducer.classes);
	const targetExams = useSelector(({ getTargetExamReducer }) => getTargetExamReducer.exam);
	const appLanguage = useSelector(({ AppLanguageReducer }) => AppLanguageReducer.applanguage);
	// const teachingLanguage = useSelector(({ getTeachingLanguageReducer }) => getTeachingLanguageReducer.teachinglanguage);
	const helperData = useSelector(({ helperDataReducer }) => helperDataReducer?.helperData);
	const boards = useSelector(({ examBoardsReducer }) => examBoardsReducer?.boards);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [selectedGenderValue, setSelectedGenderValue] = useState('');
	const [selectedClass, setSelectedClass] = useState(0);
	const [selectedExaminationBoard, setSelectedExaminationBoard] = useState("0");
	const [selectedSubExaminationBoard, setSelectedSubExaminationBoard] = useState("0");
	const [selectedTargetExam, setSelectedTargetExam] = useState("0");
	const [selectedAppLanguage, setSelectedAppLanguage] = useState("0");
	const [selectedTeachingLanguage, setSelectedTeachingLanguage] = useState("0");
	const [examination, setExamination] = useState({});
	const [teachingLanguage, setTeachingLanguage] = useState([]);
	const [examBoard, setExamBoard] = useState([]);
	const user = JSON.parse(sessionStorage.getItem("userInfo"));
	const profileInfo = JSON.parse(sessionStorage.getItem("ProfileInfo"));
	const translations = useTranslations();
	const translation = translations[2]?.EditProfile;

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	function TabPanel(props) {
		const { children, value, index, ...other } = props;

		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}
			>
				{value === index && (
					<Box sx={{ p: 3 }}>
						<Typography>{children}</Typography>
					</Box>
				)}
			</div>
		);
	}

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}
	const [value, setValue] = React.useState(0);
	const handleChange2 = (event, newValue) => {
		setValue(newValue);
	};


	const [state, setState] = React.useState({
		recent: true,
		jason: false,
		antoine: true,
	});

	const handleChange3 = (event) => {
		setState({
			...state,
			[event.target.name]: event.target.checked,
		});
	};



	const options = ['Options 1', 'Options 2', 'Options 3'];

	const [openDropDown, setOpenDropDown] = React.useState(false);
	const anchorRef = React.useRef(null);
	const [selectedIndex, setSelectedIndex] = React.useState(1);

	const handleMenuItemClick = (event, index) => {
		setSelectedIndex(index);
		setOpenDropDown(false);
	};

	// const handleToggle4 = () => {
	// 	setOpenDropDown((prevOpenDropDowsetSelectedTargetExam

	const onBackButtonClickHandler = () => {
		navigate('/')
	}

	const studentProfile = useSelector(({ studentProfileReducer }) => studentProfileReducer.student);

	const handleExaminationBoardChange = (event) => {
		const selectExamBoard = examBoard?.find((examItem) => examItem.ID === Number(event.target.value))
		// dispatch(getClassesList({ExamID: Number(event.target.value)}))
		setExamination(selectExamBoard);
		setSelectedExaminationBoard(event.target.value);
	};

	const handleChangeForGender = (event) => {
		setSelectedGenderValue(event.target.value);
	};

	const handleClassChange = (event) => {
		setSelectedClass(event.target.value);
	};

	const handleSubExaminationBoardChange = (event) => {
		setSelectedSubExaminationBoard(event.target.value);
	}

	const handleTargetExamChange = (event) => {
		setSelectedTargetExam(event.target.value)
	}

	const handleAppLanguage = (event) => {
		setSelectedAppLanguage(event.target.value)
	}

	const handleTeachingLanguage = (event) => {
		setSelectedTeachingLanguage(event.target.value);
	}

	const [userName, setUserName] = useState('');

	const onChangeHandler = (e) => {
		setUserName(e.target.value);
	};

	const onSubmitHandler = () => {
		const obj = {
			"UserName": userName,
			"email": studentProfile[0]?.Email,
			"StudentGenderID": selectedGenderValue,
			"StudentGradeID": selectedClass,
			"StudentExaminationID": selectedExaminationBoard,
			"StudentTargetExamID": selectedTargetExam,
			"StudentSubExaminationID": selectedSubExaminationBoard || "",
			"AppLanguage": selectedAppLanguage,
			"TeachingLanguage": selectedTeachingLanguage
		}

		dispatch(updateStudentProfile(obj))
		toast(translation?.ProfileEditSuccessfully)
	}

	const onLogoClickHandler = () => {
		navigate('/');
	}

	const handleLogout = () => {
		sessionStorage.removeItem("loginToken");
		signOut(auth);
		navigate("/login");
	};

	const onBackButtonHandler = () => {
		navigate('/');
	}

	useEffect(() => {
		if (helperData) {
			setTeachingLanguage(helperData.TeachingLanguage);
			
		}
	
	}, [helperData])

	useEffect(() => {
		if (boards) {
			setExamBoard(boards)
			const selectExamBoard = boards?.find((examItem) => examItem.ID === Number(studentProfile[0]?.StudentExaminationID))
			setExamination(selectExamBoard)
		}

	}, [boards, studentProfile])

	useEffect(() => {
		// dispatch(getExamBoard());
		// dispatch(getClassesList({ExamID: profileInfo?.StudentExaminationID}));
		dispatch(getTargetExam());
		dispatch(getStudentProfile());
		dispatch(getAppLanguage());
		// dispatch(getTeachingLanguage());
	}, [dispatch]);

	useEffect(() => {
		setSelectedClass(studentProfile[0]?.StudentGradeID);
		setSelectedExaminationBoard(studentProfile[0]?.StudentExaminationID);
		setSelectedTargetExam(studentProfile[0]?.StudentTargetExamID);
		setSelectedSubExaminationBoard(studentProfile[0]?.StudentSubExaminationID);
		setSelectedGenderValue(studentProfile[0]?.GenderID);
		setSelectedTeachingLanguage(studentProfile[0]?.TeachingLanguageID);
		setSelectedAppLanguage(studentProfile[0]?.AppLanguageID);
		setUserName(studentProfile[0]?.UserName);
	}, [studentProfile, appLanguage])


	return (
		<>
			<YasaHeader />
			<ToastContainer
				position="top-right"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="dark"
			/>

			<img src='../../images/cloud.png' alt='' className='cloud-img' />
			<div className="container mb-5">
				<Button
					size="large"
					startIcon={<ArrowBackIcon className="backarrow" />}
					sx={{ px: 2, py: 1.25 }}
					onClick={() => onBackButtonHandler()}
					className="back-btn"
				>
					{translation?.ProfileBackBtn}
				</Button>
			</div>

			<div className='container'>
				<div className='welcome-box-wrap'>

					<div className='whitebox-wrap cis-schedule-cards pt-0'>
						<Grid item xs={12} sm={12} md={12} className="cis-tabs">
							<Box sx={{ width: '100%' }}>
								<Box sx={{ borderBottom: 1, borderColor: 'devider' }}>
									<Tabs value={value} onChange={handleChange2} aria-label="basic tabs example">
										<Tab label={translation?.PersonalInfo} {...a11yProps(0)} />
										<Tab label={translation?.AcademicInfo} {...a11yProps(1)} />
										<Tab label={translation?.LanguagePreference} {...a11yProps(2)} />
										{/* <Tab label="Current coaching or tuition" {...a11yProps(3)} /> */}
									</Tabs>
								</Box>
								<TabPanel value={value} index={0}>
									<Box component="form" noValidate sx={{ mt: 1 }}>
										<div className='mb-20'>
											<h3 className='cis-title-1 mb-0'>{translation?.PersonalInfo}</h3>
											<p className='title-info'>{translation?.PersonalInfoDetails}</p>
										</div>
										<Grid container spacing={2} mb={2}>
											<Grid item xs={12} sm={6} md={4}>
												<div fullWidth>
													<FormControl variant="outlined" fullWidth>
														<OutlinedInput placeholder={translation?.EnterYourName} label=""
															name="UserName"
															value={userName}
															onChange={onChangeHandler}
															sx={{
																input: {
																	color: '#999999',
																	"&::placeholder": {
																		opacity: 1,
																	},
																},
															}}
														/>
													</FormControl>
												</div>
											</Grid>
											<Grid item xs={12} sm={6} md={4}>
												<div fullWidth>
													<FormControl variant="outlined" fullWidth>
														<OutlinedInput placeholder={translation?.MobileNum} label="" disabled
															defaultValue={studentProfile[0]?.Phone}
															sx={{
																input: {
																	color: '#999999',
																	"&::placeholder": {
																		opacity: 1,
																	},
																},
															}}
														/>
													</FormControl>
												</div>
											</Grid>

											<Grid item xs={12} sm={6} md={4}>
												<div fullWidth>
													<FormControl variant="outlined" fullWidth>
														<OutlinedInput placeholder="Email" label="" disabled
															defaultValue={studentProfile[0]?.Email}
															sx={{
																input: {
																	color: '#999999',
																	"&::placeholder": {
																		opacity: 1,
																	},
																},
															}}
														/>
													</FormControl>
												</div>
											</Grid>


											<Grid item xs={12} sm={12} md={12}>
												<div fullWidth>
													<FormControl className='select-gender-field'>
														<FormLabel className='cis-FormLabel'>{translation?.GenderName}</FormLabel>
														<RadioGroup
															row
															name="row-radio-buttons-group"
															onChange={handleChangeForGender}
															value={selectedGenderValue}
														>
															<FormControlLabel value="1" control={<Radio />} label={translation?.MaleGender} />
															<FormControlLabel value="2" control={<Radio />} label={translation?.FemaleGender} />
														</RadioGroup>
													</FormControl>
												</div>
											</Grid>
										</Grid>


										<div className="cis-btn-group inner-gap-15">
											<Button
												type="button"
												variant="contained"
												sx={{ m: 0, pt: 1.5, pb: 1.5, fontSize: '20px', textTransform: "none" }}
												size="large"
												className='cis-btn'
												onClick={() => onSubmitHandler()}
											>
												{translation?.Save}
											</Button>
											{/* <Button
												type="button"
												onClick={() => onBackButtonClickHandler()}
												variant="outlined"
												sx={{ m: 0, pt: 1.5, pb: 1.5, fontSize: '20px', textTransform: "none" }}
												size='large'>{translation?.BackToHome}</Button> */}
										</div>
									</Box>
								</TabPanel>

								<TabPanel value={value} index={1}>
									<Box component="form" noValidate sx={{ mt: 1 }}>
										<div className='mb-20'>
											<h3 className='cis-title-1 mb-0'>{translation?.AcademicInfo}</h3>
											<p className='title-info'>{translation?.AcademicInfoDetails}</p>
										</div>
										<Grid container spacing={2} mb={2}>
											<Grid item xs={12} sm={6} md={4}>
												<div fullWidth>
													<FormControl fullWidth>
														<Select value={selectedExaminationBoard} onChange={handleExaminationBoardChange}>
															<MenuItem value="0">{translation?.SelectExamination}</MenuItem>
															{examBoard?.map((examItem) => (
																<MenuItem key={examItem.ID} value={examItem?.ID}>
																	{examItem?.Value}
																</MenuItem>
															))}
														</Select>
													</FormControl>
												</div>
											</Grid>
											<Grid item xs={12} sm={6} md={4}>
												<div fullWidth>
													<FormControl fullWidth>
														<Select value={selectedClass} onChange={handleClassChange}>
															<MenuItem value={0}>{translation?.SelectClass}</MenuItem>
															{examination?.Grades?.map((classItem) => (
																<MenuItem key={classItem.ID} value={classItem.ID}>
																	{classItem?.Value}
																</MenuItem>
															))}
														</Select>
													</FormControl>
												</div>
											</Grid>
											
											{examination?.SubBoards?.length > 0 && (
												<Grid item xs={12} sm={6} md={4}>
													<div fullWidth>
														<FormControl fullWidth>
															<Select value={selectedSubExaminationBoard} onChange={handleSubExaminationBoardChange}>
																<MenuItem value="0">{translation?.SelectSubExamination}</MenuItem>
																{examination?.SubBoards.map((subBoardItem) => (
																	<MenuItem key={subBoardItem.ID} value={subBoardItem.ID}>
																		{subBoardItem.Value}
																	</MenuItem>
																))}
															</Select>																
														</FormControl>
													</div>
												</Grid>
											)}

											{/* <Grid container spacing={2} mb={2}> */}
											<Grid item xs={12} sm={6} md={4}>
												<div fullWidth>
													<FormControl fullWidth>
														<Select value={selectedTargetExam} onChange={handleTargetExamChange}>
															<MenuItem value={0}>{translation?.SelectTargetexm}</MenuItem>
															{targetExams?.map((target) => (
																<MenuItem key={target.ID} value={target.ID}>
																	{target?.Value}
																</MenuItem>
															))}
														</Select>
													</FormControl>
												</div>
											</Grid>
											{/* </Grid> */}

										</Grid>

										<div className="cis-btn-group inner-gap-15">
											<Button
												type="button"
												variant="contained"
												sx={{ m: 0, pt: 1.5, pb: 1.5, fontSize: '20px', textTransform: "none" }}
												size="large"
												className='cis-btn'
												onClick={() => onSubmitHandler()}>
												Save
											</Button>
											{/* <Button
												type="button"
												onClick={() => onBackButtonClickHandler()}
												variant="outlined"
												sx={{ m: 0, pt: 1.5, pb: 1.5, fontSize: '20px', textTransform: "none" }}
												size='large'>{translation?.BackToHome}</Button> */}
										</div>
									</Box>
								</TabPanel>

								<TabPanel value={value} index={2}>
									<Box component="form" noValidate sx={{ mt: 1 }}>
										<div className='mb-20'>
											<h3 className='cis-title-1 mb-0'>{translation?.LanguagePreference}</h3>
											<p className='title-info'>{translation?.LanguageInfoDetails}</p>
										</div>

										<Grid container spacing={2} mb={2}>
											<Grid item xs={12} sm={6} md={6}>
												<div fullWidth>
													<FormControl fullWidth>
														<Select value={selectedAppLanguage} onChange={handleAppLanguage}>
															<MenuItem value={0}>{translation?.SelectAppLanguage}</MenuItem>
															{
																appLanguage[0]?.map((language) => (
																	<MenuItem key={language.ID} value={language.ID}>{language?.Value}</MenuItem>
																))
															}
														</Select>
													</FormControl>
												</div>
											</Grid>
											<Grid item xs={12} sm={6} md={6}>
												<div fullWidth>
													<FormControl fullWidth>
														<Select value={selectedTeachingLanguage} onChange={handleTeachingLanguage}>
															<MenuItem value={0}>{translation?.SelectTeachingLanguage}</MenuItem>
															{
																teachingLanguage?.map((language) => (
																	<MenuItem key={language.ID} value={language.ID}>{language?.Value}</MenuItem>
																))
															}
														</Select>
													</FormControl>
												</div>
											</Grid>
										</Grid>

										<div className="cis-btn-group inner-gap-15">
											<Button
												type="button"
												variant="contained"
												sx={{ m: 0, pt: 1.5, pb: 1.5, fontSize: '20px', textTransform: "none" }}
												size="large"
												className='cis-btn'
												onClick={() => onSubmitHandler()}
											>
												{translation?.Save}
											</Button>
											{/* <Button
												type="button"
												onClick={() => onBackButtonClickHandler()}
												variant="outlined"
												sx={{ m: 0, pt: 1.5, pb: 1.5, fontSize: '20px', textTransform: "none" }}
												size='large'
											>{translation?.BackToHome}</Button> */}
										</div>
									</Box>
								</TabPanel>
							</Box>
						</Grid>

					</div>






				</div>
			</div>

			<img src='../../images/bottom-bg.png' alt='' className='cloud-img' />
		</>
	);
}

export default EditRegistration;