import React, { useState } from 'react';
import './registration.css';
import Paper from '@mui/material/Paper';
import {
	Button, Chip, CssBaseline, FormControl, FormControlLabel, FormLabel,
	OutlinedInput,
	Radio, RadioGroup,
} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExamBoard } from '../../actions/getExamBoardAction';
import { getClassesList } from '../../actions/getClassesAction';
import { getTargetExam } from '../../actions/getTargetExamAction';
import { studentRegistration } from '../../actions/studentRegistrationAction';
import { useNavigate } from 'react-router-dom';
import useTranslations from '../../custom-hooks/useTranslations';
import { getStudentProfile } from '../../actions/studentProfileAction';
import { API_BASE_URL } from '../../config/apiConfig';
import { axiosPost } from '../../services/api';
import { useForm, Controller } from 'react-hook-form';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import useSocket from '../../custom-hooks/useSocket';
import { getCountryCode } from '../../actions/getCountryCodeAction';
import { getGender } from '../../actions/getGenderAction';


const initialState = {
	StudentName: '',
	StudentGenderID: '',
	StudentGradeID: '',
	StudentExaminationID: '',
	StudentSubExaminationID: '',
};


function Registration() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { socket, isConnected } = useSocket();
	const [personalInfo, setPersonalInfo] = useState(false);
	const [targetExam, setTargetExam] = useState(true);
	const [examBoard, setExamBoard] = useState([]);
	const [myClass, setMyClasses] = useState(true);
	const [grade, setGrade] = useState(true);
	const [gender, setGender] = useState();
	const [genderList, setGenderList] = useState([]);
	const [selectedBoard, setSelectedBoard] = useState(null);
	const [selectedSubBoard, setSelectedSubBoard] = useState(null);
	const [selectedClass, setSelectedClass] = useState(null);
	const [userName, setUserName] = useState();
	const [topicID, setTopicID] = useState()
	const [examBoardID, setExamBoardID] = useState();
	const [subExamBoardID, setSubExamBoardID] = useState();
	const [selectedTargetExam, setSelectedTargetExam] = useState({Value: 'School Exams', ID: 9});
	const [selectedSubTopic, setSelectedSubTopic] = useState(null);
	const [selectedClassItem, setSelectedClassItem] = useState(null);
	const translations = useTranslations();
	const translation = translations[2]?.EditProfile;
	const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
	const loginToken = JSON.parse(sessionStorage.getItem('loginToken'));
	const profileInfo = JSON.parse(sessionStorage.getItem("ProfileInfo"));
	const [error, setError] = useState("");
	const [isApiCalling, setIsApiCalling] = useState(false);
	const [activeStep, setActiveStep] = useState(1);
	const [dialCode, setDialCode] = useState([]);
	console.log('dialCode: ', dialCode);

	const {
		control,
		trigger,
		getValues,
		formState: { errors },
	} = useForm({
		defaultValues: initialState,
		mode: 'onChange'
	});

	// const genderList = useSelector(({ getGenderReducer }) => getGenderReducer.gender);
	const helperData = useSelector(({ helperDataReducer }) => helperDataReducer?.helperData);
	const boards = useSelector(({ examBoardsReducer }) => examBoardsReducer?.boards);
	// const examBoard = useSelector(({ getExamBoardReducer }) => getExamBoardReducer.board);
	const classes = useSelector(({ getClassesListReducer }) => getClassesListReducer.classes);
	const targetExams = useSelector(({ getTargetExamReducer }) => getTargetExamReducer.exam);
	const topics = useSelector(({ getTopicsReducer }) => getTopicsReducer.topics);
	// const dialCode = useSelector(({ getCountryCodeReducer }) => getCountryCodeReducer);
	const studentInfo = useSelector(({ studentRegistrationReducer }) => studentRegistrationReducer.student);

	const handleGradeClick = (classItem) => {
		setSelectedClassItem(classItem); // Fix the function to update selectedClassItem
	};

	const handleBack = () => {
		setActiveStep((prevStep) => prevStep - 1);
	};

	const handleNext = async () => {
		const isValid = await trigger();
		if (isValid) {
			setActiveStep((prevStep) => prevStep + 1);
		} else {
			console.log('Validation failed. Please fill in all required fields.');
		}
	};


	const onPersonalInfoClickHandler =  () => {
		setPersonalInfo(true);
		setMyClasses(false);
	}

	const onClassNextClickHandler = () => {
		setPersonalInfo(true);
		setMyClasses(true);
		setTargetExam(false)
	}

	const onSubmitClickHandler = async () => {
		const isValid = await trigger();
		if (isValid) {
			const formData = getValues();
			const phNumber = localStorage.getItem("PhoneNumber")
			const email = `${phNumber?.substr(1)}@getyasa.com`

			const studentObj = {
				StudentUID: userInfo?.uid ? userInfo?.uid : loginToken?.localId,
				StudentEmail: email,
				StudentName: formData.StudentName,
				StudentGradeID: Number(formData.StudentGradeID),
				StudentGenderID: Number(formData.StudentGenderID),
				StudentCountryID: Number(dialCode[0]?.ID),
				StudentExaminationID: Number(formData.StudentExaminationID),
				StudentSubExaminationID: Number(formData.StudentSubExaminationID),
				StudentTargetExamID: selectedTargetExam.ID,
				PhoneNumber: phNumber?.replace(/^\+(91|965)/, '') || '' ,
			};


		dispatch(studentRegistration(studentObj))
			.then(() => {
				// Code to run after successful registration response
				console.log('Registration successful');
				// Further code can go here...
				sessionSave(loginToken?.localId);
			})
			.catch(error => {
				console.error('Error during registration:', error);
			});
			
		} else {
			console.log('Validation failed. Please fill in all required fields.');
		}
	};

	// const onTargetExamClickHandler = async () => {
	// 	console.log("fsafknsakfksabf.ksdabfkbaf.ksdavbfkabf.kABF.KAB ", dialCode)
	// 	const phNumber = localStorage.getItem("PhoneNumber")
	// 	// setMyClasses(true);
	// 	const email = `${phNumber?.substr(1)}@getyasa.com`
	// 	setExamBoard(true);
	// 	// setTargetExam(true);

	// 	const studentObj = {
	// 		StudentUID: userInfo?.uid ? userInfo?.uid : loginToken?.localId,
	// 		StudentEmail: email,
	// 		StudentGradeID: selectedClassItem?.ID,
	// 		StudentExaminationID: selectedBoard?.ID || '',
	// 		StudentGenderID: parseInt(gender),
	// 		StudentName: userName,
	// 		StudentCountryID:dialCode.country[0].ID,
	// 		StudentTargetExamID: selectedTargetExam?.ID || '',
	// 		StudentSubExaminationID: selectedSubBoard?.ID || '',
	// 		PhoneNumber: phNumber?.replace(/^\+(91|965)/, '') || '' ,
	// 	};


	// 	dispatch(studentRegistration(studentObj))
	// 		.then(response => {
	// 			// Code to run after successful registration response
	// 			console.log('Registration successful:', response);
	// 			// Further code can go here...
	// 			dispatch(getStudentProfile());
	// 			sessionSave(loginToken?.localId);
	// 		})
	// 		.catch(error => {
	// 			console.error('Error during registration:', error);
	// 		});
	// };

	function generateUniqueString() {
		let result = "";
		const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		for (let i = 0; i < 16; i++) {
			result += characters.charAt(
				Math.floor(Math.random() * characters.length)
			);
		}
		return result;
	}

	const sessionSave = async (uid) => {
		setIsApiCalling(true);
		const deviceId = generateUniqueString();
		localStorage.setItem("deviceId", deviceId);
		const data = {
			UserUID: uid,
			Maker: navigator.platform,
			Model: navigator.userAgent.split(" ")[0],
			authorization: JSON.parse(sessionStorage.getItem('loginToken')).idToken,
			DeviceID: deviceId,
			Platform: "Web",
			AppVersion: "1.0",
		};
		axiosPost(`${API_BASE_URL}/SaveLoginSession`, data)
			.then((res) => {
				console.log('res: ', res);
				setIsApiCalling(false);
				const mySocketData = {
					authorization: JSON.parse(sessionStorage.getItem('loginToken')).idToken,
					DeviceID: deviceId,
					IsActive: "true"
				}
				socket.emit("LoginSession", JSON.stringify(mySocketData))
				navigate("/");
			})
			.catch((err) => {
				setError(err.message);
			});
	};

	const onChangeHandler = (e) => {
		setUserName(e.target.value)
	};

	const onGenderSelect = (e) => {
		setGender(e.target.value)
	}

	const handleExamBoardSelect = (board) => {
		setSelectedBoard(board);
		setExamBoardID(board.ID);
		// dispatch(getClassesList({ExamID: Number(board.ID)}));
	};

	const onSubExamBoardSelect = (exam) => {
		setSelectedSubBoard(exam);
		setSubExamBoardID(exam.ID);
	}

	const handleExamBoard = (exam) => {
		setSelectedTargetExam(exam);
	}

	useEffect(() => {
		if (helperData) {
			const country = helperData?.Country.filter((countryItem) => countryItem?.ID.toString() === localStorage.getItem('CountryID'))
			setGenderList(helperData.Gender)
      		setDialCode(country)
		}

	}, [helperData])

	useEffect(() => {
		if (boards) {
			setExamBoard(boards)
		}

	}, [boards])

	useEffect(() => {
		// dispatch(getGender());
		// dispatch(getClassesList());
		// dispatch(getExamBoard());
		// dispatch(getCountryCode());
		// dispatch(getTargetExam());
	}, []);

	return (
		<>
			<div className='container'>
				<form>
					<Grid container className="formblock" component={Paper} elevation={6}>
						<CssBaseline />
						<Grid
							item
							xs={false}
							sm={12}
							md={6}
							sx={{
								backgroundImage: 'url(../../images/loginBg.png)',
								backgroundRepeat: 'no-repeat',
								backgroundColor: (t) =>
									t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
								backgroundSize: 'cover',
								backgroundPosition: 'top center',
							}}
						/>
						<Grid item xs={12} sm={12} md={6} className='form-wrap'>

							{/* ********************* Personal info *********************** */}
							{
								activeStep === 1 &&
								<Box component="form" noValidate sx={{ mt: 1 }}>
									<div className='mb-20'>

										<h2 className='title2'>{translation?.PersonalInfo}</h2>
									</div>
									<div fullWidth className='mb-20'>
										<Controller
											name="StudentName"
											control={control}
											defaultValue=""
											rules={{ required: 'Name is required' }} 
											render={({ field }) => (
												<FormControl variant="outlined" fullWidth>
													<OutlinedInput {...field} placeholder={translation?.EnterYourName} label="" name="StudentName"
														sx={{
															input: {
																color: '#999999',
																"&::placeholder": {
																	opacity: 1,
																},
															},
														}}
													/>
												</FormControl>
											)}
										/>
										{errors.StudentName && <small className="text-danger">{errors.StudentName.message}</small>}
									</div>
									<div fullWidth className='mb-20'>
										<Controller
											name="StudentGenderID"
											control={control}
											defaultValue=""
											rules={{ required: 'Gender is required' }} 
											render={({ field }) => (
												<FormControl className='select-gender-field'>
													<FormLabel className='cis-FormLabel'>{translation?.GenderName}</FormLabel>
													<RadioGroup
														row
														{...field} 
														name="row-radio-buttons-group"
														onClick={(e) => field.onChange(e.ID)}
													>

													{
														genderList?.map((e)=> <FormControlLabel key={e.ID} value={e.ID} control={<Radio />} label={e.Value} />)
													}
													</RadioGroup>
												</FormControl>
											)}
										/>
										{errors.StudentGenderID && <small className="text-danger">{errors.StudentGenderID.message}</small>}
									</div>

									<Button
										fullWidth
										variant="contained"
										sx={{ mt: 3, mb: 3, pt: 1.5, pb: 1.5, fontSize: '20px', textTransform: "none" }}
										size="large"
										className='cis-btn'
										onClick={handleNext}
									>
										{translation?.NextBtn}
									</Button>
								</Box> 
							}

							{/* ************* Select Class ***********************/}
							{
								activeStep === 3 &&
								<Box component="form" noValidate sx={{ mt: 1 }}>
								<Button className='mb-10' onClick={handleBack}><ArrowBackIosNewIcon /> {translation?.ProfileBackBtn}</Button>
									<div className='mb-20'>

										<h2 className='title2'>{translation?.SelectClass}</h2>
									</div>

									<div fullWidth className='mb-20'>
										<Controller
											name="StudentGradeID"
											control={control}
											defaultValue=""
											rules={{ required: 'Grade is required' }} 
											render={({ field }) => (
											<FormControl className='select-gender-field'>
												<FormLabel className='cis-FormLabel'>{translation?.SelectClass}</FormLabel>
												<Stack direction="row" {...field} spacing={1} mt={1} sx={{ flexWrap: 'wrap', gap: 1 }}>
													{selectedBoard?.Grades?.map((classItem) => (
														<Chip
															key={classItem?.ID}
															label={classItem?.Value}
															variant="outlined"
															className={field.value === classItem.ID ? "cis-selected" : "default"}
															onClick={() => field.onChange(classItem.ID)}
														/>
													))}
												</Stack>
											</FormControl>
											)}
										/>
										{errors.StudentGradeID && <small className="text-danger">{errors.StudentGradeID.message}</small>}
									</div>

									<Button
										fullWidth
										variant="contained"
										sx={{ mt: 3, mb: 3, pt: 1.5, pb: 1.5, fontSize: '20px', textTransform: "none" }}
										size="large"
										className='cis-btn'
										onClick={onSubmitClickHandler}
									>
										{translation?.NextBtn}
									</Button>

								</Box>
							}

							{/* *************** Select Exam Board *********************/}
							{
								activeStep === 2 &&
								<Box component="form" noValidate sx={{ mt: 1 }}>
									<Button className='mb-10' onClick={handleBack}><ArrowBackIosNewIcon /> {translation?.ProfileBackBtn}</Button>
									<div className='mb-20'>

										<h2 className='title2'>{translation?.SelectExamination}</h2>
										<p className='title-info '>{translation?.YourKeyToUnlocking}</p>
									</div>

									<div fullWidth className='mb-20'>
										<Controller
											name="StudentExaminationID"
											control={control}
											defaultValue=""
											rules={{ required: 'Examination board is required' }}
											render={({ field }) => (
											<FormControl className='select-gender-field'>
												<FormLabel className='cis-FormLabel'>{translation?.SelectExamination}</FormLabel>

												<div className='cis-btn-wrap'>
													{examBoard?.map((board) => (
														<React.Fragment key={board?.ID}>
															<Button
																{...field}
																variant='outlined'
																sx={{ px: 3 }}
																// onClick={() => handleExamBoardSelect(board)}
																className={field.value === board?.ID ? 'cis-selected' : ''}
																onClick={() => {
																	field.onChange(board?.ID)
																	handleExamBoardSelect(board)
																}}
															>
																{board?.Value}
															</Button>
														</React.Fragment>
													))}
												</div>									
												{errors.StudentExaminationID && <small className="text-danger">{errors.StudentExaminationID.message}</small>}
											</FormControl>
											)}
										/>
										</div>

										{ selectedBoard?.SubBoards.length > 0 ?
											<div fullWidth className='mb-20'>
											<Controller
												name="StudentSubExaminationID"
												control={control}
												defaultValue=""
												rules={{ required: 'Sub examination board is required' }} 
												render={({ field }) => (
												<FormControl className='select-gender-field'>
													<FormLabel className='cis-FormLabel mb-10'>{translation?.SelectSubExamination}</FormLabel>
													<div className='cis-btn-wrap'>
														{selectedBoard && selectedBoard?.SubBoards?.map((subboard) => (
															<div className='cis-btn-wrap mt-15' key={subboard?.Value}>
																<Button
																	{...field}
																	variant='outlined'
																	sx={{ px: 3 }}
																	onClick={() => field.onChange(subboard?.ID)}
																	className={field.value === subboard?.ID ? 'cis-selected' : 'default'}
																>
																	{subboard?.Value}
																</Button>
															</div>
														))}
														

													</div>
													{errors.StudentSubExaminationID && <small className="text-danger">{errors.StudentSubExaminationID.message}</small>}
													</FormControl>
													)}
												/>
											</div> : <></>
										}
									

									{/* <Button
										fullWidth
										variant="contained"
										sx={{ mt: 3, mb: 3, pt: 1.5, pb: 1.5, fontSize: '20px', textTransform: "none" }}
										size="large"
										className='cis-btn'
										onClick={() => { onExamNextClickHandler() }}
									>
										{translation?.NextBtn}
									</Button> */}
									<Button
										fullWidth
										variant="contained"
										sx={{ mt: 3, mb: 3, pt: 1.5, pb: 1.5, fontSize: '20px', textTransform: "none" }}
										size="large"
										className='cis-btn'
										onClick={handleNext}
									>
										{translation?.NextBtn}
									</Button>

								</Box>
							}

							{/* *****************Target Exam Board*********************** */}
							{/* <Box component="form" noValidate sx={{ mt: 1 }} hidden={targetExam}> */}
								{/* <div className='mb-20'>

									<h2 className='title2'>{translation?.SelectTargetexm}</h2>
									<p className='title-info '>{translation?.ChooseAppropriate}</p>
								</div> */}

								{/* <div fullWidth className='mb-20'> */}
									{/* <FormControl className='select-gender-field'> */}
										{/* <FormLabel className='cis-FormLabel mb-10'>{translation?.PleaseSelectTargetTest}</FormLabel> */}
										{/* <div className='cis-btn-wrap'>
											{targetExams?.map((exam) => (
												<React.Fragment key={exam?.ID}>
													<Button
														variant='outlined'
														sx={{ px: 3 }}
														onClick={() => handleExamBoard(exam)}
														className={selectedTargetExam === exam ? 'cis-selected' : ''}
													>
														{exam?.Value}
													</Button>
												</React.Fragment>
											))}
										</div> */}

										{/* <div className='cis-btn-wrap mt-15'>
											{selectedTopic && selectedSubTopic?.SubTopics?.map((subTopics) => (
												<div className='cis-btn-wrap mt-15' key={subTopics?.Value}>
													<Button
														variant='outlined'
														sx={{ px: 3 }}
														onClick={() => {
															onSubTopicSeleted(subTopics);
														}}
														className='cis-selected'
													>
														{subTopics?.Value}
													</Button>
												</div>
											))}
										</div> */}

									{/* </FormControl> */}
								{/* </div> */}

								{/* <Button
									fullWidth
									type="button"
									variant="contained"
									sx={{ mt: 3, mb: 3, pt: 1.5, pb: 1.5, fontSize: '20px', textTransform: "none" }}
									size="large"
									className='cis-btn'
									onClick={() => { onTargetExamClickHandler() }}
								>
									{translation?.NextBtn}
								</Button> */}
							{/* </Box> */}
						</Grid>
					</Grid>
				</form>
			</div>

			<img src='../../images/bottom-bg.png' alt='' className='cloud-img' />
		</>
	);
}

export default Registration;