import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import MyForm from "./MyForm";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../../config/apiConfig";
import { axiosPost } from "../../../services/api";
import { decryptData } from "../../../services/encryptDecrypt";

// Function to open the image in a modal
export const openImage = (imageSrc) => {
  const modal = document.getElementById("zoomImageModal");
  const modalImage = document.getElementById("modalImage");
  modal.style.display = "block";
  modalImage.src = imageSrc;
};

// Function to close the modal
export const closeImage = () => {
  const modal = document.getElementById("zoomImageModal");
  modal.style.display = "none";
};

const Events = ({ events }) => {
  const { encryptClassId } = useParams();
  const classId = decryptData(encryptClassId);

  const userName = JSON.parse(sessionStorage.getItem("userInfo"))?.displayName;
  const userUid = JSON.parse(sessionStorage.getItem("userInfo"))?.uid;

  const [classParticipantList, setClassParticipantList] = useState([]);

  const fetchParticipantList = () => {
    const data = {
      ClassID: classId,
    };
    axiosPost(`${API_BASE_URL}/GetParticipanetsList`, data).then((resp) => {
      if (resp.data.Message === "User is not validated") {
        sessionStorage.clear();
        window.location.href = "/login";
      } else setClassParticipantList(resp.data.Data);
    });
  };

  useEffect(() => {
    fetchParticipantList();
  }, []);

  useEffect(() => {
    const chatScreen = document.getElementById("chatScreen");
    chatScreen.scrollTo(0, chatScreen.scrollHeight);
  }, [events]);

  return (
    <>
      <div className="cis-chat-wrap">
        <div className="cis-users-list">
          <List className="cis-userlist-wrap">
            {classParticipantList?.map((participant) => (
              <ListItem className="cis-userlist-card cis-user-online">
                <ListItemButton>
                  <ListItemAvatar>
                    <Avatar alt="" src={participant?.ProfilePictureUrl} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={participant?.UserName}
                    // secondary="online"
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </div>
        <div className="cis-chat-card">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <div id="chatScreen" className="cis-chat">
                {events?.map((event) => (
                  <ChatDisplay userUid={userUid} event={event} />
                ))}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="cis-chat-action">
                <MyForm classId={classId} />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <ZoomImageContainer />
    </>
  );
};

export default Events;

export const ChatDisplay = ({ userUid, event }) => {
  const isImage = event?.message?.includes("https://storage.googleapis.com");
  const currentUser = event.UserUID === userUid;
  const msgTime = event?.time?.split(" ")?.find((el) => el.includes(":"));
  return (
    <>
      {currentUser ? (
        <div className="">
          <div className="cis-chat-username">
            {event.person} <span>{msgTime}</span>
          </div>
          <MessageBox
            currentUser={currentUser}
            isImage={isImage}
            msg={event?.message}
          />
        </div>
      ) : (
        <div className="">
          <div className="cis-chat-other-username">{event.person}</div>
          <MessageBox
            currentUser={currentUser}
            isImage={isImage}
            msg={event?.message}
          />
        </div>
      )}
    </>
  );
};

export const MessageBox = ({ currentUser, isImage, msg }) => {
  const sx = currentUser
    ? {
        display: "flex",
        justifyContent: "end",
        flexDirection: "row",
      }
    : { display: "flex", flexDirection: "row" };
  return (
    <Box sx={sx}>
      {isImage ? (
        <img
          style={{ maxWidth: "200px" }}
          src={msg}
          onClick={() => openImage(msg)}
          alt="picture"
        />
      ) : (
        <div
          className={currentUser ? "cis-send-message" : "cis-recieved-message"}
        >
          {msg}
        </div>
      )}
    </Box>
  );
};

export const ZoomImageContainer = () => {
  return (
    <div id="zoomImageModal" className="zoom-image-modal">
      <span className="close" onClick={closeImage}>
        &times;
      </span>
      <img className="zoom-image-modal-content" id="modalImage" />
    </div>
  );
};
