import { API_BASE_URL } from "../config/apiConfig";
import { axiosPost } from "../services/api";
import { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getTranslationsSuccess } from "../actions/getTranslationAction";

const useTranslations = () => {
  const dispatch = useDispatch();
  const translationsFromRedux = useSelector(({ getTranslationsReducer }) => getTranslationsReducer.translations);
  const [translations, setTranslations] = useState(translationsFromRedux || []);

  const memoizedTranslations = useMemo(() => translationsFromRedux || translations, [translationsFromRedux, translations]);

  const getLocalizationString = useCallback(() => {
    const data = { deviceType: 1 };

    if (memoizedTranslations?.length === 0) {
      axiosPost(`${API_BASE_URL}/GetLocalizationsString`, data)
        .then((res) => {
          dispatch(getTranslationsSuccess(res.data));
          setTranslations(res.data.Data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [memoizedTranslations, dispatch]);

  useEffect(() => {
    getLocalizationString();
  }, [getLocalizationString]);

  return memoizedTranslations;
};

export default useTranslations;
