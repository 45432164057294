import React, { useState, useEffect } from "react";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { useNavigate } from "react-router-dom";
import { encryptData } from "../../services/encryptDecrypt";
import generateAgoraToken from "../../services/AgoraTokenService";

const PrivateClassCard = (props) => {
  const navigate = useNavigate();
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const { classId, session, handleSessionDetail, classDeatilTranslations } =
    props;
  const statusBtnColor = (status) => {
    switch (status) {
      case "Schedule":
        return "cis-schedule-btn";
      case "Complete":
        return "cis-complete-btn";
      case "Cancelled":
        return "cis-detail-btn";

      default:
        return "cis-ongoing-btn";
    }
  };

  console.log('classDeatilTranslations :', classDeatilTranslations)

  const userUid = userInfo?.uid;
  const channelName = `channel${session?.ClassID}${session?.ID}`;
  generateAgoraToken(userUid, 2, channelName).then((res) => {
    localStorage.setItem("rtmToken", JSON.stringify(res.RtmToken));
    localStorage.setItem("rtcToken", JSON.stringify(res.RtcToken));
  });

  const launchClassRoom = () => {
    navigate(`/classroom/${encryptData(classId)}/${encryptData(session.ID)}`);
  };

  return (
    <div className="cis-schedule-card">
      <Grid container spacing={2}>
        <Grid item xs={12} md={7} lg={8}>
          <Card sx={{ display: "flex" }} className="cis-card">
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CardContent sx={{ flex: "1 1 auto" }}>
                <Typography component="div" variant="h5">
                  {session?.Value}{" "}
                  {/* TODO: Show the "Join Now" button only when session status is "On Going"
                      otherwise just hide the button  */}
                </Typography>
                {!props.testScreen && session?.SessionStatus === "On Going" && (
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ px: 3, mx: 0, mt: 1, py: 1.1 }}
                    className="cis-join-now-btn"
                    onClick={launchClassRoom}
                  >
                    {classDeatilTranslations?.JoinSession}
                  </Button>
                )}
                {session?.SessionStatus === "Complete" && (
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ px: 3, mx: 0, mt: 1, py: 1.1 }}
                    className="cis-detail-btn"
                    onClick={() => handleSessionDetail(session)}
                  >
                    {classDeatilTranslations?.ShowDetails}
                  </Button>
                )}
              </CardContent>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
          <Box className="cis-teacher-card">
            <div className="cis-teacher-info">
              <div className="cis-content">
                {
                  session?.SessionStatus === 'Schedule'?  <> <span className="status-info status-schedule mb-1">{classDeatilTranslations?.Schedule}</span> <br /></> : 
                  session?.SessionStatus === 'On Going' ? <><span className="status-info status-onGoing mb-1">{classDeatilTranslations?.OnGoing}</span> <br /></>: 
                  session?.SessionStatus === 'Cancelled' ? <><span className="status-info status-cancelled mb-1">{classDeatilTranslations?.Cancelled}</span> <br /></>:
                  session?.SessionStatus === 'Complete' && <> <span className="status-info status-completed mb-1">{classDeatilTranslations?.CompletedSession}</span> <br /></>
                }
                {/* <span className="status-info status-onGoing mb-1">On Going</span> <br />
                <span className="status-info status-cancelled mb-1">Cancelled</span> <br />
                <span className="status-info status-completed mb-1">Completed</span> <br /> */}
                {/* <Button
                  variant="contained"
                  sx={{ px: 2 }}
                  size="small"
                  className={statusBtnColor(session?.SessionStatus)}
                >

                  {
                    classDeatilTranslations?.[
                      convertPascalCase(session?.SessionStatus)
                    ]
                  }
                </Button> */}
                <ul className="cis-info-list">
                  <li>
                    <CalendarTodayIcon />
                    {session?.ClassesDate}
                  </li>
                  <li>
                    <WatchLaterIcon />
                    {session?.ClassesTimmings}
                  </li>
                </ul>
              </div>
            </div>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default PrivateClassCard;