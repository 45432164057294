import * as actionTypes from "./actionTypes";
import * as apiCall from "../services/api";
import { API_BASE_URL } from "../config/apiConfig";

const getFeedBackOptionSuccess = (data) => {
  return {
    type: actionTypes.FEED_BACK_OPTION,
    payload: {
      feedback: data.Data,
    },
  };
};

export const getFeedBackOption = (data) => async (dispatch) => {
  return await apiCall
    .axiosPost(`${API_BASE_URL}/GetFeedbackOption`, data)
    .then((response) => {
      if (response) {
        dispatch(getFeedBackOptionSuccess(response.data));
      }
    })
    .catch((error) => {
      console.log('Error: ', error);
    });
};