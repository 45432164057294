import { API_BASE_URL } from "../../config/apiConfig";
import { axiosPost } from "../../services/api";

function convertCallDurationToSeconds(callDuration) {
  // Split the call duration string into minutes and seconds
  const [minutes, seconds] = callDuration.split(' : ').map(num => parseInt(num));

  // Convert minutes to seconds and add with the seconds
  const totalSeconds = minutes * 60 + seconds;

  return totalSeconds;
}

export const agoraSessionEnd = async (roomType, sessionId, pathName) => {
  const callDuration = localStorage.getItem('CallDuration');
  const callDurationSeconds = convertCallDurationToSeconds(callDuration);
  const url = `${API_BASE_URL}/AgoraSessionEnd`;
  const data = {
    SessionType: roomType === 0 ? "QC" : "PT",
    CallDuration: callDurationSeconds.toString(),
    SessionID: sessionId,
    
  }
  sessionStorage.setItem("StudentSessionEnd", true);
  try {
    const res = await axiosPost(url, data);
    window.location.pathname = pathName;
  } catch (e) {
    window.location.pathname = pathName;
  }
}