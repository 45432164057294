import { combineReducers } from "redux";

import loginReducer from "./loginReducer.js";
import classReducer from "./classReducer.js";
import modalReducer from "./modalReducer.js";
import paymentReducer from "./paymentReducer.js";
import topicsReducer from "./quickCallsReducer.js";
import studentDashboardReducer from './studentDashboardReducer';
import quickCallReducer from './submitQuickQuestionReducer';
import activeTutorReducer from './activeTutorReducer';
import studentProfileReducer from './studentProfileReducer';
import quickCallsRecentSessionReducer from './quickCallsRecentSessionReducer';
import quickCallsFavtTutorReducer from './quickCallsFavtTutorReducer';
import feedBackOptionReducer from './feedBackOptionReducer';
import submitFeedBackReducer from './submitFeedBackReducer';
import getTutorDurationReducer from './getTutorDurationReducer';
import getExamBoardReducer from './getExamBoardReducer';
import getClassesListReducer from './getClassesListReducer';
import getTargetExamReducer from './getTargetExamReducer';
import getTopicsReducer from './getTopicsReducer';
import studentRegistrationReducer from './studentRegistrationReducer';
import AppLanguageReducer from './AppLanguageReducer';
import getTeachingLanguageReducer from './getTeachingLanguageReducer';
import updateStudentProfileReducer from './updateStudentProfileReducer';
import addToFavtTutorReducer from './addToFavtTutorReducer';
import getFavtTutorStatusReducer from './getFavtTutorStatusReducer';
import getCountryCodeReducer from './getCountryCodeReducer';
import getTranslationsReducer from './getTranslationsReducer.js';
import getGenderReducer from './getGenderReducer.js';
import helperDataReducer from './getHelperDataReducer.js';
import examBoardsReducer from './getExamBoardsReducer.js';
import studentSessionDetailsReducer from "./studentSessionDetailsReducer.js";
import privateTutorInformationReducer from "./privateTutorInformationReducer.js";

export default combineReducers({
  loginReducer,
  classReducer,
  modalReducer,
  paymentReducer,
  topicsReducer,
  studentDashboardReducer,
  quickCallReducer,
  activeTutorReducer,
  studentProfileReducer,
  quickCallsRecentSessionReducer,
  quickCallsFavtTutorReducer,
  feedBackOptionReducer,
  submitFeedBackReducer,
  getTutorDurationReducer,
  getExamBoardReducer,
  getClassesListReducer,
  getTargetExamReducer,
  getTopicsReducer,
  studentRegistrationReducer,
  AppLanguageReducer,
  getTeachingLanguageReducer,
  updateStudentProfileReducer,
  addToFavtTutorReducer,
  getFavtTutorStatusReducer,
  getCountryCodeReducer,
  getTranslationsReducer,
  getGenderReducer,
  helperDataReducer,
  examBoardsReducer,
  studentSessionDetailsReducer,
  privateTutorInformationReducer
});
