import * as actionTypes from "./actionTypes";
import * as apiCall from "../services/api";
import { API_BASE_URL } from "../config/apiConfig";

const getPaymentSuccess = (data) => {
  return {
    type: actionTypes.GET_PAYMENTS,
    payload: {
      payments: data.Data,
    },
  };
};

export const getPayments = (classId) => async (dispatch) => {
  const data = {
    ClassID: Number(classId),
  };
  return await apiCall
    .axiosPost(`${API_BASE_URL}/GetClassPayments`, data)
    .then((response) => {
      if (response) {
        dispatch(getPaymentSuccess(response.data));
      }
    })
    .catch((error) => {
      console.log('Error: ', error);
    });
};
