import React from "react";

import { Button, Chip } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Link, useNavigate } from "react-router-dom";
import useTranslations from "../../../custom-hooks/useTranslations";
import { encryptData } from "../../../services/encryptDecrypt";
import { useEffect } from "react";

const PackageDetailCard = (props) => {
  const { classId, coursePackage, handlePackageDetail, translations } = props;
  const loginToken = JSON.parse(sessionStorage.getItem("loginToken"))["idToken"]
  const deviceId = localStorage.getItem("deviceId")
  const navigate = useNavigate();
  console.log(translations, 'translations')

  useEffect(()=> {
    console.log('coursePackage: ', coursePackage);
  },[coursePackage])

  return (
    <div className="cis-private-class">
      <Grid container spacing={2}>
        <Grid item xs={12} md={7} lg={8}>
          <Card sx={{ display: "flex" }} className="cis-card">
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CardContent sx={{ flex: "1 1 auto" }}>
                <div className="cis-Chips-2">
                  {/* <Chip
                    icon={<WatchLaterIcon />}
                    variant="outlined"
                    label="10 mins ago"
                  /> */}
                  <Chip
                    icon={<CalendarTodayIcon />}
                    variant="outlined"
                    label="Starts on 23rd, Jan, 2023"
                  />
                </div>

                <Typography component="div" variant="h5">
                  {coursePackage?.TotalPayments}KD
                </Typography>

                <p className="mb-5">{`${translations?.PrivateCourseOf} ${coursePackage?.TotalHours} hours`}</p>
                <p className="mb-5">{coursePackage?.PaymentRefID}</p>
                <Button
                  variant="contained"
                  sx={{ px: 2, mb: 2, minWidth: "120px" }}
                  size="small"
                  className="cis-ongoing-btn"
                  onClick={props.handlePackageDetail}
                >
                  {translations?.Details}
                </Button>
              </CardContent>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
          <Box className="cis-teacher-card">
            <div className="cis-teacher-info">
              <div className="cis-content">
                <Button
                  variant="contained"
                  sx={{ px: 2, mb: 2, minWidth: "120px" }}
                  size="small"
                  className={
                    coursePackage?.PaymentStatus === "success"
                      ? "cis-complete-btn"
                      : "cis-payment-pending-btn"
                  }
                >
                  {coursePackage?.PaymentStatus}
                  {/* {coursePackage?.PaymentStatus === null
                    ? translations?.Pending
                    : translations?.[coursePackage?.PaymentStatus]} */}
                </Button>
                {/* { coursePackage?.PaymentStatus === null ?  ( <Button
                  variant="contained"
                  sx={{ px: 2, mb: 2, minWidth: "120px" }}
                  size="small"
                  className="cis-ongoing-btn"
                >
                  {translation?.Panding}
                </Button>) : (
                   <Button
                   variant="contained"
                   sx={{ px: 2, mb: 2, minWidth: "120px" }}
                   size="small"
                   className="btn btn-success"
                 >
                   {translation?.Done}
                 </Button>
                ) 
                } */}
                <div className="cis-link-wrap">
                  {/* <Link>
                    <ContentCopyIcon /> {translation?.PaymentLink}
                  </Link> */}
                  {coursePackage?.Invoice && (
                    // <a
                    //   href={coursePackage?.InvoiceURL}
                    //   target="_blank"
                    //   download
                    // >
                    //   {translations?.Invoice}
                    // </a>
                    <Button
                      variant="contained"
                      sx={{ px: 2, mb: 2, minWidth: "120px" }}
                      size="small"
                      className="btn btn-success"
                      onClick={() => {
                        window.open(`/class-details/${classId}/invoice/${loginToken}/${deviceId}`, "_blank")  
                        // navigate(`/class-details/${classId}/invoice/${loginToken}`)}
                      
                      } } >
                      {translations?.Invoice}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default PackageDetailCard;
