import React, { useEffect } from "react";
import { agoraSessionEnd } from "./AgoraCommon";
import useTranslations from "../../custom-hooks/useTranslations";

const LaunchHireClassRoom = () => {
  const translations = useTranslations();
	const translation = translations[33]?.ApplicationConfiguration;
  const agoraCallDuration = translation?.AgoraCallDuration;
  const sessionId = sessionStorage.getItem('SessionID');
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const rtmToken = JSON.parse(localStorage.getItem("rtmToken"));
  const roomName = `privateroom${sessionId}`;
  const roomUuid = `privateroomuuid${sessionId}`;
  const channel = `channel${sessionId}`;
  const roomType = 4;
  const userUid = userInfo?.uid;
  const userName = userInfo?.displayName;
  const pathName = "/courses" 

  useEffect(() => {
    // document.querySelector("#fc_widget").style.display = 'none'
    const fcWidget = document.querySelector("#fc_widget");
    if (fcWidget) {
      fcWidget.style.display = 'none';
    }
    const params = { userUid, userName, roomUuid, sessionId, roomName, channel, rtmToken, roomType, pathName, agoraCallDuration, agoraSessionEnd };
    test(params);

  }, []);
  return;
};
export default LaunchHireClassRoom;
