import React, { useState } from 'react';
import './StudentRating.css';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import Paper from '@mui/material/Paper';
import LogoutIcon from '@mui/icons-material/Logout';
import {
	Avatar, Button, Chip, Rating,
} from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFeedBackOption } from '../../actions/feedBackOptionAction';
import { submitFeedBack } from '../../actions/submitFeedBackAction';
import { useNavigate } from 'react-router-dom';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import {addToFavtTutor} from '../../actions/addToFavtTutorAction';
import { getFavtTutorStatus } from '../../actions/getFavtTutorStatusAction';
import useTranslations from "../../custom-hooks/useTranslations";


function StudentRating() {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [rating, setRating] = useState(0);
	const [feedback, setFeedback] = useState([]);
	const [sessionFeedbackMsg, setSessionFeedbackMsg] = useState()
	const helperData = useSelector(({ helperDataReducer }) => helperDataReducer?.helperData);
	const tutorFavtSession = useSelector(({ getFavtTutorStatusReducer }) => getFavtTutorStatusReducer?.tutor);
	const addTutorFavt = useSelector(({ addToFavtTutorReducer }) => addToFavtTutorReducer?.tutor);
	const tutorProfile = JSON.parse(sessionStorage.getItem('TutorResponse'));
	const QuestionID = sessionStorage.getItem('QuestionID');
	const callDuration = localStorage.getItem('CallDuration');
	const [selectedItem, setSelectedItem] = useState(null);
	const translations = useTranslations();
    const translation = translations[26]?.QuickCall;
	const menuScreen = translations[3]?.MenuScreen;
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};


	const handleClose = () => {
		setAnchorEl(null);
	};

	const onClickHandler = (item) => {
		setSessionFeedbackMsg(item.ID);
		// Check if the item is already selected
		const isSelected = selectedItem && selectedItem.ID === item.ID;

		// If the clicked item is already selected, clear the selection
		if (isSelected) {
			setSelectedItem(null);
		} else {
			// If the clicked item is not selected, update the selectedItem state
			setSelectedItem(item);
		}
	};

	const handleRatingChange = (event, newValue) => {
		setRating(newValue);
	};

	const onSubmitRatingsHandler = () => {
		const ratingsObj = {
			SessionID: QuestionID,
			TutorRating: rating,
			SessionFeedbackMsg: sessionFeedbackMsg
		}
		dispatch(submitFeedBack(ratingsObj))
		localStorage.removeItem('CallDuration');
		sessionStorage.removeItem('StudentSessionEnd');
		navigate('/quick-calls');
	};

	const onEditProfileClick = () => {
		navigate('/edit-profile')
	}

	const onMarkFavtTutorClickHandler = (tutor) => {
		dispatch(addToFavtTutor({TutorID: parseInt(tutor?.TutorID)}))
	}

	useEffect(() => {
		if (helperData) {
			const feedbackMessages = helperData.FeedbackMessage.filter((item) => item.RoleID === 3)
			setFeedback(feedbackMessages);
		}

	}, [helperData]) 

	useEffect(()=> {
		dispatch(getFavtTutorStatus({TutorID:parseInt(tutorProfile?.TutorID)}))
	}, [dispatch]);

	return (
		<>
			<header className='mainHeader' component={Paper} elevation={6}>
				<div className='container'>
					<div className='row'>
						<div className='col-left'>
							<div>
								<img src='../../images/logo.png' alt='' className='' />
							</div>
						</div>
						<div className='col-right'>
							<div className=''>
								<div className='loginuser-menu' onClick={handleClick}>
									<img src='../../images/user-img.png' alt='' className='' />
								</div>
								<Menu
									id="simple-menu"
									anchorEl={anchorEl}
									keepMounted
									open={Boolean(anchorEl)}
									onClose={handleClose}
									className="loginuser-menu-wrap"
								>

									<MenuItem onClick={() => onEditProfileClick()}>
										<ListItemIcon>
											<PersonOutlineIcon />
										</ListItemIcon>
										{menuScreen?.Profile}
									</MenuItem>

									<MenuItem>
										<ListItemIcon>
											<LogoutIcon />
										</ListItemIcon>
										{menuScreen?.Logout}
									</MenuItem>
								</Menu>
							</div>
						</div>
					</div>
				</div>
			</header>

			<img src='../../images/cloud.png' alt='' className='cloud-img' />


			<div className='container cis-rating-page'>
				<div className='welcome-box-wrap'>
					{/* 1st Step  */}
					<div className='whitebox-wrap'>
						<Box component="form" noValidate sx={{ mt: 1 }}>
							<div className='mb-20'>
								<h2 className='title2'>{translation?.YourSessionExpired}</h2>
								{callDuration && <p className='title-info recent-session-time'><QueryBuilderIcon /> {callDuration} {translation?.Duration}</p>}
							</div>
							<div fullWidth className='mb-20'>
								<div className='session-student-info'>
									<Avatar alt=""
										src={tutorProfile?.TutorImage !== '' ? tutorProfile?.TutorImage || tutorProfile?.TutorProfilePicture : "../../images/Tutor-2.jpg"}
										sx={{ width: 56, height: 56, mx: "auto" }}
									/>
									<h2 className='title-18'>{tutorProfile?.TutorName}</h2>
									{/* <p className='session-classes'>{tutorProfile?.TutorSubject}</p> */}
									{
										tutorFavtSession?.IsFavourite || addTutorFavt[0]?.Added ?<Button variant="outlined" type='button' onClick={() => onMarkFavtTutorClickHandler(tutorProfile)} size='large'><StarBorderIcon sx={{ color: '#F3C10F', marginLeft: "2px" }} />{translation?.MarkAsFavtTutor}</Button> : 
										<Button  variant="outlined" style={{ borderColor: "grey", color:"grey", background: "transparent", cursor: "pointer" }} type='button' onClick={() => onMarkFavtTutorClickHandler(tutorProfile)} size='large'><StarBorderIcon sx={{ color: 'grey', marginLeft: "2px" }} />{translation?.MarkAsFavtTutor}</Button>
									}
									
								</div>

								<div className='good-bad-rating' dir="ltr">
									<span className='rating_good'>
										{translation?.Good}
									</span>
									<Rating
										name='TutorRating'
										value={rating}
										onChange={handleRatingChange}
										className='student-rating-star' />
									<span className='rating_bad'>
									{translation?.Bad}
									</span>
								</div>

							</div>
							<div fullWidth className='mb-20'>
								<h2 className='title-info mb-10'>{translation?.DidYouLike}</h2>
								<Stack direction="row" spacing={1} className='rating-menus'>
									{feedback?.map((item) => (
										<Chip
											key={item.ID}
											label={item.Value}
											onClick={() => onClickHandler(item)}
											variant="outlined"
											className={selectedItem && selectedItem.ID === item.ID ? "cis-selected" : ""}
										/>
									))}
								</Stack>
							</div>


							<Button
								type="button"
								fullWidth
								variant="contained"
								sx={{ mt: 3, mb: 3, pt: 1.5, pb: 1.5, fontSize: '20px', textTransform: "none" }}
								size="large"
								className='cis-btn'
								disabled={!rating || !sessionFeedbackMsg}
								onClick={() => onSubmitRatingsHandler()}
							>
								{translation?.QuickSubmit}
							</Button>

							<p>{translation?.FacingProblemsMsg}</p>
						</Box>
					</div>
				</div>
			</div>

			<img src='../../images/bottom-bg.png' alt='' className='cloud-img' />
		</>
	);
}

export default StudentRating;