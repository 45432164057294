import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../../../config/apiConfig";
import { axiosPost } from "../../../services/api";
import CircularProgress from "@mui/material/CircularProgress";

const SessionVideoCard = (props) => {
  const { sessionData, translations } = props;
  const [recordedVideos, setRecordedVideos] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const fetchVideoURL = () => {
    setIsLoading(true);
    const data = {
      SessionID: sessionData?.ID,
    };
    axiosPost(`${API_BASE_URL}/GetRecordedSession`, data)
      .then((resp) => {
        setIsLoading(false);
        setRecordedVideos(resp.data.Data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  useEffect(() => {
    fetchVideoURL();
  }, []);
  return (
    <div className="cis-video-card">
      {isLoading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </div>
      ) : (
        <div className="cis-video-box">
          {recordedVideos?.length > 0 ? (
            <div style={{ position: "relative" }}>
              <div className="cis-watermark-video-logo">
                <img src="/favicon.ico" alt="yasa-logo" />
              </div>
              <VideoContainer url={recordedVideos[0]} />
            </div>
          ) : (
            <div className="cis-no-recording">
              {translations?.NoRecordingAvailable}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SessionVideoCard;

const VideoContainer = ({ url }) => {
  return (
    <>
      <video width="320" height="240" controls style={{ width: "99%" }}>
        <source src={url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </>
  );
};
