import * as actionTypes from "./actionTypes";

export const openModal = (errorMessage) => {
  return {
    type: actionTypes.OPEN_MODAL,
    payload: {
      openModal: true,
      errorMessage: errorMessage,
    },
  };
};

export const closeModal = () => {
  return {
    type: actionTypes.CLOSE_MODAL,
    payload: {
      openModal: false,
    },
  };
};
