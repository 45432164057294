import React, { useEffect } from "react";
import { agoraSessionEnd } from "./AgoraCommon";
import useTranslations from "../../custom-hooks/useTranslations";

const LaunchQuickCallRoom = () => {
  const translations = useTranslations();
	const translation = translations[33]?.ApplicationConfiguration;
  const agoraCallDuration = translation?.AgoraCallDuration;
  const sessionId = sessionStorage.getItem('QuestionID');
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const rtmToken = JSON.parse(localStorage.getItem("rtmToken"));
  const roomName = `room${sessionStorage.getItem('QuestionID')}`;
  const roomUuid = `roomuuid${sessionStorage.getItem('QuestionID')}`;
  const channel = `channel${sessionStorage.getItem('QuestionID')}`;
  const roomType = 0;
  const userUid = userInfo?.uid;
  const userName = userInfo?.displayName;
  const pathName = "/student-ratings"


  useEffect(() => {
    // document.querySelector("#fc_widget").style.display = 'none'
    const fcWidget = document.querySelector("#fc_widget");
    if (fcWidget) {
      fcWidget.style.display = 'none';
    }
    const helpChatBox = document.getElementById("fc_frame");
		if (helpChatBox) {
			helpChatBox.style.display = 'none';
		}
    const params = { userUid, userName, roomUuid, sessionId, roomName,channel, rtmToken, roomType, pathName, agoraCallDuration, agoraSessionEnd };
    test(params);
  }, []);
  return;
};
export default LaunchQuickCallRoom;
