import React, { useRef, useState } from 'react';
import './QuickCalls.css';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { getTopics } from '../../actions/quickCallsAction';
import { useDispatch, useSelector } from 'react-redux';
import { getStudentDashBoard } from '../../actions/studentDashboardAction';
import { activeTutorList } from '../../actions/activeTutorAction';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import useTranslations from '../../custom-hooks/useTranslations';
import YasaHeader from '../common-components/YasaHeader';
import useSocket from '../../custom-hooks/useSocket';
import { encryptData } from '../../services/encryptDecrypt';
import generateAgoraToken from '../../services/AgoraTokenService';
import Loading from '../loader/Loading';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import VideoThumbnail from 'react-video-thumbnail';
import { WebSocketURL } from '../../config/apiConfig';
import { axiosPost } from '../../services/api';

const QuickCalls = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { isConnected, socket } = useSocket();
	const [selectedTopic, setSelectedTopic] = useState(null);
	const [selectedSubtopic, setSelectedSubtopic] = useState(null);
	const [openModal, setOpenModal] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const [imageUrl, setImageUrl] = useState('');
	// const [base64Image, setBase64Image] = useState('');
	const [question, setQuestion] = useState("");
	const [timer, setTimer] = useState(60);
	const [topics, setTopics] = useState([]);
	const [showTopics, setShowTopics] = useState(false);
	const [showSubTopics, setShowSubTopics] = useState(false);
	const [connectButton, setConnectButton] = useState(false);
	const [showDefault, setShowDefault] = useState(true)
	const [disableConnect, setDisableConnect] = useState(true);
	const [disableContinue, setDisableContinue] = useState(true);
	const [topicValue, setTopicValue] = useState();
	const [hideModal, setHideModal] = useState(false);
	const translations = useTranslations();
	const translation = translations[26]?.QuickCall;
	const commonFrontEnd = translations[21]?.CommonFrontend;
	const wallet = translations[31]?.Wallet;
	const timerRef = useRef(null);
	const [loading, setLoading] = useState(true);
	const [connectTutorLoading, setConnectTutorLoading] = useState(false);
	const [isFreeExpired, setIsFreeExpired] = useState(false);
	const [isLowBalance, setIsLowBalance] = useState(false);
	const [isOffline, setIsOffline] = useState(false);
	const [favouriteTutor, setFavouriteTutor] = useState([]);
	
	
	const user = JSON.parse(sessionStorage.getItem("userInfo"));
	const studentProfile = useSelector(({ studentProfileReducer }) => studentProfileReducer.student[0]) || JSON.parse(sessionStorage.getItem('ProfileInfo'));
	// const studentProfile = JSON.parse(sessionStorage.getItem("ProfileInfo"));

	const handleTopicClick = (topicID, topic) => {
		setTopicValue(topic.Value);
		setShowDefault(false);
		setConnectButton(true);
		setSelectedTopic(topicID);
		if (topic?.SubTopics?.length === 0) {
			setShowSubTopics(false)
			setDisableConnect(false);
		} else {
			setShowSubTopics(true)
			setDisableConnect(true);
		}
	};


	const handleSubtopicClick = (subtopicID) => {
		setSelectedSubtopic(subtopicID);
		setConnectButton(true);
		setDisableConnect(false);
	};

	// const quickCall = useSelector(({ quickCallReducer }) => quickCallReducer.quickCall);
	const quickCall = JSON.parse(localStorage.getItem('QuickCalls'))
	const activeTutors = useSelector(({ activeTutorReducer }) => activeTutorReducer.tutor);

	const handleClickOpenModal = () => {
		setConnectTutorLoading(true)
		setTimeout(() => {
			setConnectTutorLoading(false)
		}, 2000);
		// const imageUrlWithoutPrefix = imageUrl?.substring("data:image/png;base64,".length);
		const studentProfileQCData = JSON.parse(sessionStorage.getItem('ProfileInfo'))
		const quickCallsObj = {
			StudentID: studentProfileQCData?.ID,
			SubjectID: selectedTopic.toString(),
			SubjectName: topicValue,
			QuestionID: 0,
			TutorID: "",
			StudentName: studentProfileQCData?.UserName,
			StudentImage: studentProfileQCData?.ProfilePictureUrl,
			TutorName: "",
			TutorImage: "",
			IsAccept: false,
			StudentClass: studentProfileQCData?.Grade,
			StudentClassID: studentProfileQCData?.StudentGradeID.toString(),
			EnglishMessage: "",
			TutorSubject: "",
			requestType: "1",
			Notes: question?.Question,
			ReasonID: "",
			ArabicMessage: "",
			SubTopicID: selectedSubtopic.toString(),
			ImageURL: imageUrl,
			IsDecline: false
		}

		const stringifiedJSONQC = JSON.stringify(quickCallsObj)
		socket.emit("QCQuestions", stringifiedJSONQC, 'QC');
		// socket.on("QCQuestions", (response) => {
		// 	const mysocketDataForQc = JSON.parse(response);

		// 	if (mysocketDataForQc?.StatusCode === 200) {
		// 		const QuestionQCData = mysocketDataForQc.Data
		// 		if (QuestionQCData?.requestType == "1" && QuestionQCData?.IsDecline == false) {
		// 			localStorage.setItem('QuickCalls', JSON.stringify(mysocketDataForQc?.Data))
		// 			setOpenModal(true);
		// 			setHideModal(false);
		// 			setTimer(55);
		// 			dispatch(activeTutorList());
		// 		}
		// 		else if (QuestionQCData.requestType == "2" && QuestionQCData.IsAccept == true) {
		// 			const QuestionData = JSON.parse(localStorage.getItem('QuickCalls'))
		// 			if (parseInt(QuestionData?.QuestionID) === parseInt(QuestionQCData?.QuestionID)) {
		// 				sessionStorage.setItem('QuestionID', QuestionData.QuestionID);
		// 				sessionStorage.setItem('TutorResponse', JSON.stringify(QuestionQCData))
		// 				localStorage.setItem('QuickCalls', JSON.stringify(mysocketDataForQc.Data))
		// 				const userUid = user?.uid;
		// 				const channelName = `channel${QuestionData.QuestionID}`;
		// 				generateAgoraToken(userUid, 2, channelName).then((res) => {
		// 					localStorage.setItem("rtmToken", JSON.stringify(res.RtmToken));
		// 					localStorage.setItem("rtcToken", JSON.stringify(res.RtcToken));
		// 					navigate(`/quick-calls-room/${encryptData(QuestionData.QuestionID)}`);
		// 				});
		// 			}
		// 		}


		// 	}
		// })
	};

	const handleFileUpload = async (event) => {
		const file = event.target.files[0];
		if (file) {
			const formData = new FormData();
			formData.append('image', file);
			formData.append('fileType', 'QuickCallImage');

			try {
				const response = await axiosPost(`${WebSocketURL}/FileUpload`, formData, {
					headers: {
						'Access-Control-Allow-Origin': '*',
						'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
					},
				});
				if (response.data) {
					setImageUrl(response.data?.FileUpload);
				}
			} catch (error) {
				console.error('Error uploading image:', error);
			}
		}
	}

	// const handleFileUpload = (event) => {
	// 	const file = event.target.files[0];
	// 	if (file) {
	// 		const reader = new FileReader();
	// 		reader.onloadend = () => {
	// 			setImageUrl(reader.result);
	// 		};
	// 		reader.readAsDataURL(file);
	// 	}
	// };

	const modalhandleClose = (event, reason) => {
		if (reason && reason === "backdropClick" && "escapeKeyDown") {
			return;
		}
		setOpenModal(false);
		cancelRequest();
	};

	const cancelRequest = () => {
		const studentRequest = JSON.parse(localStorage.getItem('QuickCalls'));
		if (!socket || !socket.connected) {
			// If socket is not initialized or not connected, do not emit the event
			console.error('Socket is not connected. Unable to cancel request.');
			return;
		}
		studentRequest.IsDecline = true;
		studentRequest.requestType = "1";
		sessionStorage.removeItem("StudentRequest");
        sessionStorage.removeItem("TutorResponse")
		socket.emit("QCQuestions", JSON.stringify(studentRequest), 'QC');
	};


	const OnChangeHandler = (e) => {
		setQuestion((preValue) => ({
			...preValue,
			[e.target.name]: e.target.value
		}))
		if (e.target.value.length < 10) {
			setDisableContinue(true);
		} else {
			setDisableContinue(false);
		}
	}

	const onContinuehandler = () => {
		setShowTopics(true);
		setShowSubTopics(false);
		setShowDefault(false);
		setConnectButton(true);
	}

	const onTopicsContinueHandler = () => {
		setShowSubTopics(true);
		setShowTopics(false)
	}

	const onSessionClickHandler = (session) => {
		sessionStorage.setItem('SessionID', session.ID);
		sessionStorage.setItem('QuickCallRecordedSession', JSON.stringify(session));
		navigate(`/quick-calls-details/${encryptData(session.ID)}`);
	};

	const onDiscloseFreeCallHandler = () => {
		setIsFreeExpired(false);
	}

	const onDiscloseLowBalanceHandler = () => {
		setIsLowBalance(false);
	}

	// const topics = useSelector(({ topicsReducer }) => topicsReducer.topics);
	const boards = useSelector(({ examBoardsReducer }) => examBoardsReducer?.boards);
	const dashboard = useSelector(({ studentDashboardReducer }) => studentDashboardReducer.dashboard);

	useEffect(() => {
		if (boards) {
			const studentBoard = boards.find((board) => board?.ID === studentProfile?.StudentExaminationID) 
			const grades = studentBoard?.Grades.find((grade) => grade?.ID === studentProfile?.StudentGradeID)
			const allowedTopics = grades?.topics?.filter((topic) => topic.AllowQuickCall)
			setTopics(allowedTopics);
		}

	}, [boards])

	useEffect(() => {
		dispatch(getStudentDashBoard());
		// dispatch(getTopics({ GradeID: studentProfile?.StudentGradeID, ExamID: studentProfile?.StudentExaminationID }));
	}, [dispatch]);


	useEffect(() => {
		if (openModal) {
			timerRef.current = setInterval(() => {
				setTimer((prevTimer) => {
					const newTimer = prevTimer - 1;

					if (newTimer <= 0) {
						clearInterval(timerRef.current);
						setOpenModal(false); // Close the modal after 55 seconds
						cancelRequest();
					}

					return newTimer;
				});
			}, 1000);

			return () => {
				clearInterval(timerRef.current);
			};
		}
	}, [openModal]);

	const onFavtTutorClickHandler = (value) => {
		sessionStorage.setItem('TutorID', value.ID);
		navigate('/view-all-tutors');
	}

	const handleDragOver = (event) => {
		event.preventDefault();
	};

	const handleDrop = async (event) => {
		event.preventDefault();
		const file = event?.dataTransfer.files[0];
		if (file) {
			const formData = new FormData();
			formData.append('image', file);
			formData.append('fileType', 'QuickCallImage');

			try {
				const response = await axiosPost(`${WebSocketURL}/FileUpload`, formData, {
					headers: {
						'Access-Control-Allow-Origin': '*',
						'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
					},
				});
				if (response.data) {
					setImageUrl(response.data?.FileUpload)
				}
			} catch (error) {
				console.error('Error uploading image:', error);
			}
	

		}
	}

	// const handleDrop = (event) => {
	// 	event.preventDefault();
	// 	const file = event?.dataTransfer.files[0];
	// 	if (file) {
	// 		const reader = new FileReader();
	// 		reader.onloadend = () => {
	// 			setSelectedFile(file);
	// 			setImageUrl(reader.result);
	// 		};
	// 		reader.readAsDataURL(file);
	// 	}
	// };

	const handleRemoveImage = () => {
		setSelectedFile(null);
		setImageUrl("");
		setShowDefault(true);
		setShowTopics(false);
		setShowSubTopics(false);
		setConnectButton(false);
		setDisableConnect(true);
		setDisableContinue(true);
		setSelectedTopic(null);
		setSelectedSubtopic(null);
	};

	useEffect(() => {
		if (translation) {
			setLoading(false)
		}
	}, [translation])

    useEffect(() => {
		// document.querySelector("#fc_widget").style.display = 'block'
		const fcWidget = document.querySelector("#fc_widget");
		if (fcWidget) {
			fcWidget.style.display = 'none';
		}
	}, [])

	useEffect(() => {
		if (studentProfile) {
			sessionStorage.setItem('ProfileInfo', JSON.stringify(studentProfile));
		}

	}, [studentProfile])

	useEffect(() => {
		const handleOnline = async () => {
			setIsOffline(false);
			window.location.reload();	
		};

		const handleOffline = () => {
			setIsOffline(true);
		};
	
		window.addEventListener('online', handleOnline);
		window.addEventListener('offline', handleOffline);
	
		return () => {
			window.addEventListener('online', handleOnline);
		  	window.removeEventListener('offline', handleOffline);
			let payload = {};
			payload['authorization'] = JSON.parse(sessionStorage.getItem('loginToken'))?.idToken;
			payload['DeviceID'] = localStorage.getItem('deviceId');
			socket?.emit('ClearInterval', JSON.stringify(payload));
			socket?.off('GetTutorsResponseEvent');
		};
	}, []);

	useEffect(() => {
		const handleVisibilityChange = () => {
			// if (document.visibilityState === 'visible' && !isConnected) {
			if (!isConnected) {
				console.log('awake....')
				window.location.reload();
			}
		};

		document.addEventListener('visibilitychange', handleVisibilityChange);
	
		// Cleanup function to remove event listener
		return () => {
		  	document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, [isConnected]);

	useEffect(() => {
		if (isConnected) {
			const payload = {}
			payload['DeviceID'] = localStorage.getItem('deviceId');
            payload['authorization'] = JSON.parse(sessionStorage.getItem('loginToken'))?.idToken;
			socket?.emit('FavTutor', JSON.stringify(payload));
			socket?.on('responseFavTutor', (data) => {
				const response = JSON.parse(data);
				setFavouriteTutor(response?.FavouriteTutor || []);
			})
			socket.on("QCQuestions", (response) => {
				const mysocketDataForQc = JSON.parse(response);
				console.log('mysocketDataForQc: ', mysocketDataForQc);
	
				if (mysocketDataForQc?.StatusCode === 200) {
					const QuestionQCData = mysocketDataForQc.Data
					if (QuestionQCData?.requestType == "1" && QuestionQCData?.IsDecline == false) {
						localStorage.setItem('QuickCalls', JSON.stringify(mysocketDataForQc?.Data))
						setOpenModal(true);
						setHideModal(false);
						setTimer(55);
						dispatch(activeTutorList());
					}
					else if (QuestionQCData.requestType == "2" && QuestionQCData.IsAccept == true) {
						const QuestionData = JSON.parse(localStorage.getItem('QuickCalls'))
						if (parseInt(QuestionData?.QuestionID) === parseInt(QuestionQCData?.QuestionID)) {
							sessionStorage.setItem('QuestionID', QuestionData.QuestionID);
							sessionStorage.setItem('TutorResponse', JSON.stringify(QuestionQCData))
							localStorage.setItem('QuickCalls', JSON.stringify(mysocketDataForQc.Data))
							const userUid = user?.uid;
							const channelName = `channel${QuestionData.QuestionID}`;
							generateAgoraToken(userUid, 2, channelName).then((res) => {
								localStorage.setItem("rtmToken", JSON.stringify(res.RtmToken));
								localStorage.setItem("rtcToken", JSON.stringify(res.RtcToken));
								navigate(`/quick-calls-room/${encryptData(QuestionData.QuestionID)}`);
							});
						}
					}
	
	
				} else if (mysocketDataForQc.StatusCode === 400) {
					setIsFreeExpired(true);
				} else if (mysocketDataForQc.StatusCode === 402) {
					setIsLowBalance(true);
				}
			})
		}

	}, [isConnected])
	
	return (
		<React.Fragment>
			{loading && (<Loading message={'Please wait...'} />)}
			<YasaHeader />
			<img src='../../images/cloud.png' alt='' className='cloud-img' />

			<div className='container'>
				<div className='welcome-box-wrap'>
					<Box className='welcome-user'>
						<div className='user-wallet-info'>
							<h1 className='title2'>{translation?.Hi} <strong>{studentProfile?.UserName ? studentProfile?.UserName : user?.displayName}</strong> </h1>
							<span className='wallet-box'>
								<AccountBalanceWalletIcon className='ml-5' />{studentProfile?.WalletAmount} KD

							</span>
						</div>
					</Box>

					{/* *************** Ask Question ***********************/}

					<div className='whitebox-wrap cis-tutor-cards' >
						<Grid container spacing={2}>
							<Grid item xs={12} sm={12} md={12}>
								<h3 className='cis-title-1 mb-0'>{translation?.PictureQuestion}</h3>
							</Grid>
							{ !imageUrl &&
								<Grid item xs={12} sm={12} md={7}>
									<Box >
										<TextField
											className='cis-ask-question-field'
											type='text'
											label=""
											variant="outlined"
											fullWidth
											multiline
											minRows={4}
											name="Question"
											placeholder={translation?.TypeYourQuestion}
											onChange={(e) => OnChangeHandler(e)}
											readOnly={!showDefault}
										/>
									</Box>
								</Grid>
							}

							{ (!imageUrl && !question?.Question)  &&
								<Grid item xs={12} sm={12} md={1}>
									<div className="cis-divider align-verticle"><span>{translation?.Or}</span></div>
								</Grid>
							}

							{/* ************ Upload Question ***********************/}

							{	!question?.Question &&
								<Grid item xs={12} sm={12} md={4}>
									<Box
										item
										sx={{ width: '100%', display: 'flex', alignItems: 'center' }}
										onDragOver={handleDragOver}
										onDrop={handleDrop}
									>
										{imageUrl ? (
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<img
													src={imageUrl}
													alt="Uploaded"
													style={{ maxWidth: '100%', width: '120px' }}
												/>
												<button
													onClick={handleRemoveImage}
													className="remove-button"
													style={{ marginLeft: '10px' }}
												>
													X
												</button>
											</div>
										) : (
											<Button
												variant="contained"
												component="label"
												className='upload-photo cis-uploded-box'
											>
												<input
													type="file"
													accept=".png, .jpg, .jpeg"
													style={{ display: 'none' }}
													onChange={handleFileUpload}
												/>
												<div>
													<CloudUploadIcon color='#CCCCCC' />
													<p>{translation?.DragDrop}</p>
												</div>
											</Button>
										)}
									</Box>
									<br />
								</Grid>
							}
							{
								showDefault && !connectButton && (
									<Grid item xs={12} sm={12} md={12}>
										<Button
											variant="contained"
											type="button"
											disabled={disableContinue && !imageUrl}
											size='large'
											className='cis-btn-blue'
											onClick={onContinuehandler}
										>
											{translation?.ContinueBtn}
										</Button>
									</Grid>
								)
							}


							{/* ************ Select Topics ***********************/}

							{
								showTopics && (
									<Grid item xs={12} sm={12} md={12}>
										<h2 className='cis-title-2'>{translation?.SelectSubject}</h2>
										<div className='cis-btn-wrap'>
											{topics?.map((topic, index) => (
												<span key={index} style={{ marginRight: '8px' }}>
													<Button
														variant='outlined'
														className={selectedTopic === topic.ID ? 'cis-selected' : ''}
														sx={{ px: 3 }}
														onClick={() => handleTopicClick(topic.ID, topic)}
													>
														{topic.Value}
													</Button>
												</span>
											))}
										</div>
									</Grid>
								)
							}

							{/* ************Select SubTopics ***********************/}

							{
								(showSubTopics) && (
									<Grid item xs={12} sm={12} md={12} sx={{ mt: 1 }}>
										<h2 className='cis-title-2'>{translation?.SelectChapter}</h2>
										<div className='cis-btn-wrap'>
											{selectedTopic &&
												topics?.find((topic) => topic.ID === selectedTopic)
													?.SubTopics.map((subtopic, index) => (
														<span key={index} style={{ marginRight: '8px' }}>

															<Button
																variant='outlined'
																className={selectedSubtopic === subtopic.ID ? 'cis-selected' : ''}
																sx={{ px: 3 }}
																onClick={() => handleSubtopicClick(subtopic.ID)}
															>
																{subtopic.Value}
															</Button>
														</span>
													))}
										</div>
									</Grid>
								)
							}


							{
								showTopics && (
									<Grid item xs={12} sm={12} md={4}>
										{connectButton ? (
											<Button variant="contained" type="button" disabled={disableConnect} size='large' className='cis-btn-red' onClick={handleClickOpenModal}>
												{connectTutorLoading && <CircularProgress
													size={24}
													sx={{
														color: '#ffffff',
													}}
													className='ml-5'
												/>}
												{translation?.ConnectToTutor}
											</Button>
										) : (
											<Button variant="contained" size='large' className='cis-btn-blue' onClick={onTopicsContinueHandler}>
												<CircularProgress
													size={24}
													sx={{
														color: '#ffffff',
													}}
													className='ml-5'
												/>
												{translation?.ContinueBtn}
											</Button>
										)}
									</Grid>
								)
							}

							{	favouriteTutor.length > 0 && <React.Fragment>

								<Grid item xs={12} sm={12} md={12}>
									<hr className='cis-devider' />
								</Grid>

								<Grid item xs={12} sm={12} md={12}>
									<div className='cis-title-2col mb-15'>
										<h2 className='cis-title-1 mb-0'>{translation?.FavoriteTeachers}</h2>
									</div>
								</Grid>

								{ favouriteTutor?.map((tutor, index) => (
										<Grid key={index} item xs={6} sm={4} md={3} lg={2}>
												<Card className="card favorite-circle-card" onClick={() => onFavtTutorClickHandler(tutor)}>
													<div className='cis-cadImg-wrap innr-img-squre design-circle-img'>
														<img src={tutor?.ProfilePictureUrl === '' || !tutor?.ProfilePictureUrl ? `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAAja6c9Ip37JMYpOmIIe9JGv16LvccS2OoCpr2Evz5Gv2-ImNwePvBoxNWctyWlJwYmA&usqp=CAU` : tutor?.ProfilePictureUrl} className='cis-cadImg squre-Img' alt='' />
														{tutor?.OnlineStatusType === 'Online' ? <span className='livestatus'></span> : tutor?.OnlineStatusType === 'Offline' ? <span className='offlinestatus'></span> : <span className='busystatus'></span>}
													</div>
													<CardContent className="cardContent">
														<Typography gutterBottom variant="h5" component="h2">
															{tutor?.UserName}
														</Typography>
														<Typography>
															<Rating name="read-only" value={tutor?.Rating} readOnly />
														</Typography>
													</CardContent>
												</Card>
										</Grid>
									))							
								}
							</React.Fragment> }


							{ dashboard?.RecentCalls?.length > 0 && <React.Fragment>
								<Grid item xs={12} sm={12} md={12}>
									<hr className='cis-devider' />
								</Grid>

								{/************* Start Recent Sessions ****************/}
								<Grid item xs={12}>
									<div className='cis-title-2col mb-15'>
										<h2 className='cis-title-1 mb-0'>{translation?.RecentSession}</h2>
										{ dashboard?.RecentCalls?.length > 0 &&
											<Button variant="contained" size='large' sx={{ width: '160px' }} onClick={() => navigate('/view-all-sessions')}>{translation?.ViewAll}</Button>
										}
									</div>
								</Grid>


								{ dashboard?.RecentCalls?.map((tutor, index) => (
										<Grid key={index} item xs={6} sm={4} md={3} lg={2} className='xxl-col-20'>
											<Card className="card" onClick={() => onSessionClickHandler(tutor)}>
												<div className='cis-cadImg-wrap'> {/* innr-img-squre class removed */}
												{ (tutor?.RecordedSessionURL) != null?
												<VideoThumbnail
												videoUrl={tutor?.RecordedSessionURL}
												// width={300}
												height={300}
												snapshotAtTime ={14}
												/>:<div className='video-thumbnail-placeholder' alt=''> </div>
											}
													<div className='cis-sessions-short-info'>{translation?.Upto} {!tutor?.CallDuration ? 0 : tutor?.CallDuration} {translation?.Minutes}</div>
													{
														!tutor?.CancellationStatus && (
															<span className='sessions-tuter-info'>
																<img src={tutor?.TutorProfilePicture === '' || !tutor?.TutorProfilePicture ? 'https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o=' : tutor?.TutorProfilePicture} className='cis-tuterImg' alt='' />
																{tutor?.OnlineStatusType === 'Online' ? <span className='livestatus'></span> : tutor?.OnlineStatusType === 'Offline' ? <span className='offlinestatus'></span> : tutor?.OnlineStatusType === 'Busy' && <span className='busystatus'></span>}
															</span>
														)
													}

												</div>
												<CardContent className="cardContent">
													<h3 className='title-18'>{tutor?.TopicName}</h3>
													<ul className='date-time-info'>
														<li><CalendarMonthIcon /> {new Date(tutor?.StartDateTime).toDateString()}</li>
														<li><AccessTimeIcon />{tutor?.StartDateTime?.slice(11, 19)}</li>
													</ul>
													<Typography>
														<Rating name="read-only" value={parseFloat(tutor?.Ratings)} readOnly />
													</Typography>
												</CardContent>
											</Card>
										</Grid>
									))	
								}
							</React.Fragment> }

						</Grid>
					</div>
				</div>
			</div>



			<Dialog
				open={openModal}
				keepMounted
				onClose={modalhandleClose}
				aria-describedby="alert-dialog-slide-description"
				className='cis-dialog-1'
			>
				<DialogTitle>{translation?.ConnectWithFavTeacher}</DialogTitle>
				<DialogContent className='text-center'>
					<div className="timing-box">
						<div className="timing-box_text-wrap">
							<span>
								{timer} <br /> {translation?.Seconds}
							</span>
						</div>
						<div className="ripple_ timing-box-1"></div>
						<div className="ripple_ timing-box-2"></div>
						<div className="ripple_ timing-box-3"></div>
					</div>

					<Typography variant="h5">{translation?.RequestPushed} {activeTutors?.length} {translation?.Tutors}</Typography>

				</DialogContent>
				<DialogActions sx={{ justifyContent: 'center' }}>
					<Button onClick={modalhandleClose} variant="contained" color='error' size='large' sx={{ px: 5 }}>{translation?.QuickCancel}</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={isFreeExpired}
				onClose={onDiscloseFreeCallHandler}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<h2 className='title-1 text-center mb-0'>{commonFrontEnd?.ExceededFreeCallLimit}</h2>
				</DialogContent>
				<DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
					<Button
						type="submit"
						variant="contained"
						sx={{ mt: 0, mb: 0, pt: 0.5, pb: 0.5, px: 2, width: '120px', fontSize: '18px', textTransform: "none" }}
						size="large"
						className='cis-btn m-0'
						onClick={onDiscloseFreeCallHandler}
					>
						{commonFrontEnd?.OkButton}
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={isLowBalance}
				onClose={onDiscloseLowBalanceHandler}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<h2 className='title-1 text-center mb-0'>{wallet?.LowAmountMessage}</h2>
				</DialogContent>
				<DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
					<Button
						type="submit"
						variant="contained"
						sx={{ mt: 0, mb: 0, pt: 0.5, pb: 0.5, px: 2, width: '120px', fontSize: '18px', textTransform: "none" }}
						size="large"
						className='cis-btn m-0'
						onClick={onDiscloseLowBalanceHandler}
					>
						{commonFrontEnd?.OkButton}
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={isOffline}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<div className="offline-container">
						<h2 className='title-1 text-center mt-5'>{commonFrontEnd?.NetworkDisconnected}</h2>
						<div className="image-container">
							{/* <img className='center-image' src="../../images/wifi-2.gif" alt="Network disconnected" /> */}
						</div>
						<p className='title-info text-center'>{commonFrontEnd?.NetworkLost}</p>
					</div>
				</DialogContent>
			</Dialog>

			<img src='../../images/bottom-bg.png' alt='' className='cloud-img' />
			{hideModal && <div id="myModal" class="error-modal">
				<div class="error-modal-content">
					<span class="error-modal-close" onClick={() => setHideModal(false)}>
						&times;
					</span>
					<div className="cis-modal-wrap">
						<ErrorOutlineIcon color="error" />
						<p>{quickCall[0]?.Message}</p>
					</div>
				</div>
			</div>}

		</React.Fragment>
	);
}

export default QuickCalls;