import React, { useState } from 'react';
import './ViewAllSessions.css';
import StarIcon from '@mui/icons-material/Star';
import { Button, CardMedia, CircularProgress, Pagination, Rating } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { privateTutorInformation } from '../../actions/privateTutorInformationAction';
import { getQuickCallsRecentSessions, toggleStatus } from '../../actions/quickCallsRecentSessionActions';
import { studentSessionDetails } from '../../actions/studentSessionDetailsAction';
import { format } from 'timeago.js';
import { useNavigate } from 'react-router-dom';
import { addToFavtTutor } from '../../actions/addToFavtTutorAction';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useTranslations from '../../custom-hooks/useTranslations';
import YasaHeader from '../common-components/YasaHeader';
import VideocamIcon from '@mui/icons-material/Videocam';
import { encryptData } from '../../services/encryptDecrypt';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VideoPlayer from 'simple-react-video-thumbnail'
import VideoThumbnail from 'react-video-thumbnail';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import { API_BASE_URL } from '../../config/apiConfig';
import { axiosPost } from '../../services/api';
import timeAgoDate from '../../services/timeAgoDate';

const ViewAllSessions = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [openModal2, setOpenModal2] = React.useState(false);
	const [tutorInfo, setTutorInfo] = useState(0);
	const [tutorRating, setTutorRating] = useState(0);
	const [hireTutorRatingModal, setHireTutorRatingModal] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const [sessionFeedbackMsg, setSessionFeedbackMsg] = useState();
	const [feedback, setFeedback] = useState([]);

	const translations = useTranslations();
	const translation = translations[26]?.QuickCall;
    const findTutor = translations[29]?.FindTutor;
	const sessionVideo = translations[15]?.SessionVideo;
	const commonFrontEnd = translations[21]?.CommonFrontend;

	const callDuration = localStorage.getItem('CallDuration');

	// const sessions = useSelector(({ quickCallsRecentSessionReducer }) => quickCallsRecentSessionReducer.sessions);
	const sessions = useSelector(({ studentSessionDetailsReducer }) => studentSessionDetailsReducer.sessionDetails);

	const modalhandleClose2 = () => {
		setOpenModal2(false);
	};

	const sessionsPerPage = 6; // Number of sessions to display per page
	const pageCount = Math.ceil(sessions?.length / sessionsPerPage);
	const [currentPage, setCurrentPage] = useState(1);

	// Calculate the index range for the current page
	const startIndex = (currentPage - 1) * sessionsPerPage;
	const endIndex = startIndex + sessionsPerPage;

	// Get the sessions to display on the current page
	const sessionsToDisplay = sessions?.slice(startIndex, endIndex);
	const helperData = useSelector(({ helperDataReducer }) => helperDataReducer?.helperData);
	const dashboard = useSelector(({ studentDashboardReducer }) => studentDashboardReducer.dashboard);

	const onAskAgainHandler = () => {
		navigate('/quick-calls')
	};

	const onBackButtonHandler = () => {
		navigate('/quick-calls');
	}

	const onTutorProfileClickHandler = (tutor) => {
		sessionStorage.setItem('TutorID', tutor?.TutorID);
		navigate('/view-all-tutors');
	}

	const onFavtTutorClickHandler = (tutor) => {
		dispatch(toggleStatus(tutor.TutorID))
		dispatch(addToFavtTutor({ TutorID: tutor?.TutorID }))
	}

	const onRatingClickHandler = (tutor) => {
		setTutorInfo(tutor);
		if (tutor.Type === 'QC') {
			sessionStorage.setItem('QuestionID', tutor?.ID);
			sessionStorage.setItem('TutorResponse', JSON.stringify(tutor));
			navigate('/student-ratings')
		} else {
			setHireTutorRatingModal(true)
		}
	}

	const onRecordingClickHandler = (session) => {
		if (dashboard?.userQuickCall?.IsPaid){
			toast(findTutor?.AddAmount)
		} else {
			sessionStorage.setItem('SessionID', session.ID);
			sessionStorage.setItem('QuickCallRecordedSession', JSON.stringify(session));
			navigate(`/quick-calls-details/${encryptData(session.ID)}`);
		}
	};

	const onSelectFeedBackHandler = (item) => {
		setSessionFeedbackMsg(item.ID);
		// Check if the item is already selected
		const isSelected = selectedItem && selectedItem.ID === item.ID;

		// If the clicked item is already selected, clear the selection
		if (isSelected) {
			setSelectedItem(null);
		} else {
			// If the clicked item is not selected, update the selectedItem state
			setSelectedItem(item);
		}
	}

	const handleTutorRatingChange = (event) => {
		setTutorRating(event.target.value);
	};

	const onSavePrivateTutorFeedBack = async () => {
		try {

			setHireTutorRatingModal(false)
			sessionStorage.removeItem('StudentSessionEnd');
			const payload = {
				SessionID: tutorInfo?.ID,
				Ratings: tutorRating,
				FeedbackID: sessionFeedbackMsg
			};
			const response = await axiosPost(`${API_BASE_URL}/SavePrivateTutorFeedback`, payload);
			if (response.data.IsSuccess) {
				toast(response.data.Message)
				// can add logic over here on success
			} else {
				toast(response.data.Message)
			}

		} catch (error) {
			console.error('An error occurred while saving private tutor feedback:', error);
		}
	};

	useEffect(() => {
		if (helperData) {
			const feedbackMessages = helperData.FeedbackMessage.filter((item) => item.RoleID === 3)
			setFeedback(feedbackMessages);
		}

	}, [helperData])

	useEffect(() => {
		dispatch(studentSessionDetails({
			"Page": 1,
			"PageSize": 10
		}))
		// dispatch(getQuickCallsRecentSessions());
	}, [dispatch]);

	return (
		<>
		<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="dark"
			/>
			<YasaHeader />
			<img src='../../images/cloud.png' alt='' className='cloud-img' />
			<div className="container mb-5">
				<Button
					size="large"
					startIcon={<ArrowBackIcon className="backarrow" />}
					sx={{ px: 2, py: 1.25 }}
					onClick={() => onBackButtonHandler()}
					className="back-btn"
				>
					{translation?.BackBtn}
				</Button>
			</div>

			<div className='container'>
				<div className='welcome-box-wrap'>
					<div className='whitebox-wrap cis-recent-sessions-page'>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<div className='cis-title-2col'>
									<h2 className='cis-title-1 mb-0'>{translation?.RecentSession}</h2>
									{/* <h2 className='cis-title-1 mb-0'>;iowehfegbgfDGBFsdavg</h2> */}
								</div>
							</Grid>

							{
								!sessionsToDisplay?.length ? (
									<Grid container justify="center" alignItems="center" item xs={12}>
										<CircularProgress />
									</Grid>
								) : (
									<React.Fragment>
										{
											sessionsToDisplay?.map((session, index) => (
												<React.Fragment key={index}>

													<Grid item xs={12} md={8}>
														<Card sx={{ display: 'flex' }} className="cis-card">
															<Box className="cis-cardMedia">
															{ (session?.RecordedSessionURL) != null?
														<VideoThumbnail
														videoUrl={session?.RecordedSessionURL}
														// width={300}
														height={300}
														snapshotAtTime ={20}
														// className="thumbnail-class" // optional
														/>:<div className='video-thumbnail-placeholder' alt=''> </div>
													}
															{/* <VideoThumbnail
																	videoUrl={session?.RecordedSessionURL}
																	snapshotAtTime ={14}
																	// width={300}
																	// height={300}
																	/> */}
															{/* <VideoPlayer videoUrl={session?.RecordedSessionURL} snapshotAt={10} /> */}
																{/* <CardMedia
																	component="img"
																	image={session?.ImageURL === 'No Image' ? `https://www.bizzbeesolutions.com/wp-content/uploads/2016/03/image-not-available.jpg` : session?.ImageURL}
																	alt=""
																/> */}
																<div className='cis-sessions-short-info'>{translation?.Upto} {session?.CallDuration === null ? 0 : session?.CallDuration} {translation?.Minutes}</div>
															</Box>
															<Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>
																<CardContent sx={{ flex: '1 1 auto' }}>
																	<div className='cis-Chips'>
																		<Chip icon={<WatchLaterIcon />} variant="outlined" label={timeAgoDate(session?.StartDateTime)} className='cis-chip' />
																		<br />
																		{/* <Chip label={session?.TopicName} />
																		{session?.SubTopicName !== '' && <Chip label={session?.SubTopicName} />} */}
																	</div>

																	{/* <Typography component="div" variant="h5">
																		{session?.Notes}
																	</Typography> */}
																	{
																		session?.CallDuration && (
																			<div className='cis-card-action'>
																				{/* <span className='cis-rating-box'> {!session?.Ratings ? 0 : session?.Ratings} <Rating value={parseFloat(session?.Ratings)} readOnly /></span> */}
																				<span className='cis-rating-box'><Rating value={parseFloat(session?.Ratings)} readOnly /></span>
																			</div>
																		)
																	}

																	{
																		Number(session?.Ratings) === 0 && <div className="mb-3">
																			<Button variant="contained" size='small' type="button" onClick={() => onRatingClickHandler(session)}> {commonFrontEnd?.RateNow}</Button>
																			<Button variant="contained" size='small' type="button" onClick={() => onRecordingClickHandler(session)}><span className='ml-5'><VideocamIcon /></span> {translation?.Recording}</Button>
																		</div>
																	}

																	{
																		session?.CallDuration && Number(session?.Ratings) !== 0 && (<>
																		{/* <div className='cis-recording' style={{ 'cursor': 'pointer' }} onClick={() => onRecordingClickHandler(session)}> */}
																			{/* <span><VideocamIcon /></span> */}
																			<Button variant="contained" size='small' type="button" onClick={() => onRecordingClickHandler(session)}><span className='ml-5'><VideocamIcon /></span> {translation?.Recording}</Button>
																			 
																		{/* </div> */}
																		</>)
																	}


																	{
																		session?.CancellationStatus && (<div className='cis-card-action'>
																			<Button variant="contained" size='small' type="button" onClick={onAskAgainHandler}>{translation?.AskAgain}</Button>
																		</div>)
																	}

																</CardContent>
															</Box>
														</Card>
													</Grid>

													{
														!session?.CancellationStatus && (<Grid item xs={12} md={4}>
															<Box className="cis-teacher-card">
																<div className='cis-taught-by'>{translation?.AnswerBy}</div>
																<div className='cis-teacher-info'>
																	<div className='cis-teacher-img' onClick={() => onTutorProfileClickHandler(session)}>
																		<img src={session?.TutorProfilePicture === '' ? `https://www.lagrave.org/wp-content/uploads/2017/04/No-photo-available_5.jpg` : session?.TutorProfilePicture} className='' alt='' />
																		{session?.OnlineStatusType === 'Online' ? <span className='livestatus'></span> : session?.OnlineStatusType === 'Offline' ? <span className='offlinestatus'></span> : session?.OnlineStatusType === 'Busy' && <span className='busystatus'></span>}
																	</div>
																	<div className='cis-content'>
																		<h5>{session?.TutorName === '' ? '-' : session?.TutorName}</h5>
																		<div className='cis-favourite-btn-wrap'>
																			{/* {
																				!session?.FavouriteTutor ? <Button variant="outlined" size='small' sx={{ width: '150px' }} className='favourite-button' onClick={() => onFavtTutorClickHandler(session)}><StarIcon sx={{ color: '#8e8b8b' }} /> {translation?.FavouriteTutor}</Button> :
																					<Button variant="outlined" size='small' sx={{ width: '150px' }} className='favourite-button favourited' onClick={() => onFavtTutorClickHandler(session)}><StarIcon sx={{ color: '#F3C10F' }} /> {translation?.FavouriteTutor}</Button>
																			} */}
																			{
																				!session?.FavouriteTutor ? <Button variant="outlined" size='small' sx={{ width: '150px' }} className='favourite-button' onClick={() => onFavtTutorClickHandler(session)}>{translation?.FavouriteTutor}</Button> :
																					<Button variant="outlined" size='small' sx={{ width: '150px' }} className='favourite-button favourited' onClick={() => onFavtTutorClickHandler(session)}>{translation?.FavouriteTutor}</Button>
																			}
																		</div>
																	</div>
																</div>
															</Box>
														</Grid>)
													}
													<div item xs={12} style={{ gridColumn: 'span 2' }} className='cis-space-50'></div>
												</React.Fragment>
											))
										}
									</React.Fragment>
								)
							}

							<Grid item xs={12} mt={4}>
								<Pagination
									count={pageCount || 0}
									color="primary"
									variant="outlined"
									page={currentPage}
									onChange={(event, page) => setCurrentPage(page)}
								/>
							</Grid>
						</Grid>
					</div>
				</div>
			</div>

			<Dialog
				open={openModal2}
				keepMounted
				onClose={modalhandleClose2}
				aria-describedby="alert-dialog-slide-description"
				className='cis-dialog-2'
			>
				<DialogTitle>{translation?.DidYouFind}</DialogTitle>
				<DialogContent>

					<ul className='cis-checkList'>
						<li>
							<Checkbox edge="end" /> {translation?.IssuesWithSolution}
						</li>
						<li>
							<Checkbox edge="end" /> {translation?.ReportBehaviour}
						</li>
						<li>
							<Checkbox edge="end" /> {translation?.InappropriateContent}
						</li>
						<li>
							<Checkbox edge="end" /> {translation?.LikeFour}
						</li>
					</ul>

				</DialogContent>
				<DialogActions sx={{ justifyContent: 'start' }}>
					<div className='cis-dialog-action'>
						<Button onClick={modalhandleClose2} variant="outlined" size='large' sx={{ px: 5 }}>{translation?.BackBtn}</Button>
						<Button onClick={modalhandleClose2} variant="contained" color='error' size='large' sx={{ px: 5 }}>{translation?.QuickSubmit}</Button>
					</div>
				</DialogActions>
			</Dialog>

			<Dialog
					open={hireTutorRatingModal}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogContent>
						<h2 className='title-1 text-center mb-0'> {commonFrontEnd?.SessionEndTeacher}</h2>
						{callDuration && <p className='title-info recent-session-time'><QueryBuilderIcon /> {callDuration} {translation?.Duration}</p>}
						
						<div className='mb-3 ratinbox'>
							<p className='title-info'>{commonFrontEnd?.HowStudent}</p>
							<div className='good-bad-rating' dir="ltr">
								<span className='rating_good'>{translation?.Good}</span>
								<Rating
									value={parseFloat(tutorRating)}
									onChange={handleTutorRatingChange} />
								<span className='rating_bad'>{translation?.Bad}</span>

							</div>
						</div>
						<div className='mb-3 ratinbox'>
							<div fullWidth className='mb-20'>
								<p className='title-info'>{translation?.DidYouLike}</p>
								<Stack direction="row" spacing={1} className='rating-menus'>
									{feedback?.map((item) => (
										<Chip
											key={item.ID}
											label={item.Value}
											onClick={() => onSelectFeedBackHandler(item)}
											variant="outlined"
											className={selectedItem && selectedItem.ID === item.ID ? "cis-selected" : ""}
										/>
									))}
								</Stack>
							</div>
						</div>
					</DialogContent>
					<DialogActions>
						<Button
							type="submit"
							variant="contained"
							sx={{ mt: 0, mb: 0, pt: 0.5, pb: 0.5, px: 2, fontSize: '18px', textTransform: "none" }}
							size="large"
							className='cis-btn m-0'
							onClick={onSavePrivateTutorFeedBack}
							disabled={!tutorRating || !sessionFeedbackMsg}
						>
							{translation?.QuickSubmit}
						</Button>
					</DialogActions>
				</Dialog>

			<img src='../../images/bottom-bg.png' alt='' className='cloud-img' />
		</>
	);
}

export default ViewAllSessions;