import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { API_BASE_URL } from "../../config/apiConfig";
import { axiosGet, axiosPost } from "../../services/api";
import { useNavigate } from "react-router-dom";

import refreshImg from "../../assets/images/refresh-50.png";
import useTranslations from "../../custom-hooks/useTranslations";
import useSocket from "../../custom-hooks/useSocket";
import { useUserAuth } from "../../context/UserAuthContext";

const QRCodeComponent = ({ setShowQR, QRvalue, connectId, setQRvalue, setConnectId}) => {
  const navigate = useNavigate();
  const { logIn } = useUserAuth();
  const translations = useTranslations();
  const { isConnected, socket } = useSocket();
  const translation = translations[0]?.Login;
  const [showRefreshQR, setShowRefreshQR] = useState(false);
  const [qRdata, setQRdata] = useState(true)

  function updateQRcode() {
    setTimeout(() => {
      setShowRefreshQR(true);
    }, 50000);
  }

  const getQRcode = () => {
    axiosGet(`${API_BASE_URL}/GenerateQR`)
      .then((res) => {
        setQRvalue(res.data.Code);
        setConnectId(res.data.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function generateUniqueString() {
		let result = "";
		const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		for (let i = 0; i < 16; i++) {
			result += characters.charAt(
				Math.floor(Math.random() * characters.length)
			);
		}
		return result;
	}

  const sessionSave = async (uid) => {
    const deviceId = generateUniqueString();
		localStorage.setItem("deviceId", deviceId)
		
		const data = {
			UserUID: uid,
			Maker: navigator.platform,
			Model: navigator.userAgent.split(" ")[0],
      authorization: JSON.parse(sessionStorage.getItem('loginToken')).idToken,
			DeviceID: deviceId,
			Platform: "Web",
			AppVersion: "1.0",
		};

		axiosPost(`${API_BASE_URL}/SaveLoginSession`, data)
			.then((res) => {
        if (res.StatusCode === 200) {
          const QrData = {"StatusCode": "200","Message":"Done", "QrCode" :connectId }
          socket.emit("QRLogin", JSON.stringify(QrData));
          const mySocketData = {
            authorization: JSON.parse(sessionStorage.getItem('loginToken')).idToken,
            DeviceID: deviceId,
            IsActive: "true"
				  }
          socket.emit("LoginSession", JSON.stringify(mySocketData))
          navigate("/");
        }
			})
			.catch((error) => {
				console.error(error)
			}); 
  };

  useEffect(() => {
    getQRcode();
	}, []);

  useEffect(() => {

    const handleQRConnection = async (data) => {
      const data1 = await data.slice(0, -9);
      let loginQRData;
      if (qRdata) {
        loginQRData = window.atob(data1)
        setQRdata(false)
      } else {
        loginQRData = data1;
      }
        
      const qrData = await JSON.parse(loginQRData)
      console.log('qrData: ', qrData);
  
      if (qrData) {
        const res = await logIn(qrData.email, qrData.otp)

        if (res) {
          sessionStorage.setItem("userInfo", JSON.stringify(res.user));
          sessionStorage.setItem("loginToken", JSON.stringify(res._tokenResponse));
          sessionSave(res.user.uid);
        }
      }
    }
  
    if (isConnected) {
      socket?.on(connectId, handleQRConnection)
    };

    return () => {
      socket?.off(connectId);
    };

  }, [isConnected, connectId]);

  return (
    <div>
      <Box component="form" noValidate sx={{ mt: 1 }}>
        <div className="mb-20">
          <h2 className="title2">{translation?.Login}</h2>
          {/* <p className="title-info ">{translation?.WeWillSendCode}</p> */}
          <p className="title-info ">Scan the QR Code using your Phone </p>
        </div>
        <div fullWidth className="mb-0">
          <div
            style={{ textAlign: "center" }}
            className="refresh-image-container"
          >
            {showRefreshQR === false ? (
              <Base64Image base64Image={QRvalue} />
            ) : (
              <>
                <div className="reloadbtn-inner-wrap">
                  <div
                    className="cis-reloadbtn"
                    onClick={() => {
                      setShowRefreshQR(false);
                      updateQRcode();
                    }}
                  >
                    <img src={refreshImg} width={"100px"} alt="expire QR" />
                    <h3>QR Code is expired, Click here to Refresh</h3>
                  </div>
                </div>
                <Base64Image base64Image={QRvalue} />
              </>
            )}
          </div>
        </div>

        {/* <Grid container className="cis-divider-wrap">
          <div className="cis-divider"></div>
        </Grid> */}

        <div className="mt-0">
          <Button
            variant="outlined"
            fullWidth
            sx={{ pt: 1.5, pb: 1.5 }}
            size="large"
            className="mt-20"
            onClick={() => {
              navigate("/")
              setShowQR(false);
            }}
          >
            Back
          </Button>
        </div>
      </Box>
    </div>
  );
};

const Base64Image = ({ base64Image }) => {
  const dataUrl = `data:image/png;base64,${base64Image}`;

  return (
    <div>
      <img src={dataUrl} alt="Decoded Image" width={200} />
    </div>
  );
};

export default QRCodeComponent;
