import * as React from 'react';
import { useState, useRef } from "react";
import './FindTutor.css';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Rating from '@mui/material/Rating';
import SchoolIcon from '@mui/icons-material/School';
import PersonIcon from '@mui/icons-material/Person';
import PublicIcon from '@mui/icons-material/Public';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import TuneIcon from '@mui/icons-material/Tune';
import Drawer from '@mui/material/Drawer';
import ListItemText from '@mui/material/ListItemText';
import Slider from '@mui/material/Slider';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { useNavigate } from 'react-router-dom';
import YasaHeader from '../../components/common-components/YasaHeader';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTopics } from '../../actions/quickCallsAction';
import useSocket from '../../custom-hooks/useSocket';
import Loading from '../../components/loader/Loading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useTranslations from '../../custom-hooks/useTranslations';
import { API_BASE_URL } from '../../config/apiConfig';
import { axiosPost } from '../../services/api';
import { sessionRejoined } from '../../actions/sessionRejoinedAction';
import { getFeedBackOption } from '../../actions/feedBackOptionAction';
import { getStudentDashBoard } from '../../actions/studentDashboardAction';
import generateAgoraToken from '../../services/AgoraTokenService';
import { encryptData } from '../../services/encryptDecrypt';
import CircularProgress from '@mui/material/CircularProgress';
import { getStudentProfile } from '../../actions/studentProfileAction';


function CustomTabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<div sx={{ p: 3 }}>
					{children}
				</div>
			)}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const minDistance = 10;


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};


const FindTutor = () => {
	// *****************************************************************************************************************************************
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { isConnected, socket } = useSocket();
	const [openModal, setOpenModal] = useState(false);
	const [timer, setTimer] = useState(55);
	const [tutors, setTutors] = useState([]);
	const [verifiedTutors, setVerifiedTutors] = useState([]);
	const [loading, setLoading] = useState(true);
	const [topicIds, setTopicIds] = useState();
	const [topicName, setTopicName] = useState();
	const [selectedTopics, setSelectedTopics] = useState([]);
	const [selectedTopicNames, setSelectedTopicNames] = useState();
	const [openCallRejectModal, setOpenCallRejectModal] = useState(false);
	const [tutorRating, setTutorRating] = useState(0);
	const [hireTutorRatingModal, setHireTutorRatingModal] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const [sessionFeedbackMsg, setSessionFeedbackMsg] = useState();
	const [nationality, setNationality] = useState([]);
	const [selectedNationality, setSelectedNationality] = useState('');
	const [price, setPrice] = useState([]);
	const [rating, setRating] = useState([]);
	const [duration, setDuration] = useState([]);
	const [connectNowClicked, setConnectNowClicked] = useState(false);
	const [onAgreeLoading, setOnAgreeLoading] = useState(false)
	const [isOffline, setIsOffline] = useState(false);
	const [feedback, setFeedback] = useState([]);
	const [openDrawer, setOpenDrawer] = useState(false);
	const searchValueRef = useRef('');
	const translations = useTranslations();
	const [sessionId, setSessionId] = useState();

	const [value, setValue] = React.useState(0);
	// Range slider 
	const [value1, setValue1] = React.useState([0, 600]);
	const [value2, setValue2] = React.useState([0, 5]);
	const [value3, setValue3] = React.useState([0, 24]);

	const translation = translations[26]?.QuickCall;
	const commonFrontEnd = translations[21]?.CommonFrontend;
	const lowAmountMessage = translations[31]?.Wallet?.LowAmountMessage;
	const personalInfo = translations[7]?.PersonalInfo;
	const privateClassDashboard =translations[8]?.PrivateClassDashboard
	const login =translations[0]?.Login

	const studentRequest = JSON.parse(sessionStorage.getItem('StudentRequest'));
	const studentSessionEnd = sessionStorage.getItem('StudentSessionEnd');
	const privateTutorSessionID = sessionStorage.getItem('SessionID');
	const idToken = JSON.parse(sessionStorage.getItem('loginToken'))?.idToken;
	const user = JSON.parse(sessionStorage.getItem("userInfo"));

	const studentProfile = useSelector(({ studentProfileReducer }) => studentProfileReducer.student[0] || JSON.parse(sessionStorage.getItem('ProfileInfo')));
	const topics = useSelector(({ topicsReducer }) => topicsReducer.topics);
	const helperData = useSelector(({ helperDataReducer }) => helperDataReducer?.helperData);
	const tutorDashboard = useSelector(({ studentDashboardReducer }) => studentDashboardReducer.dashboard);

	const onConnectNowClick = (tutor) => {
		// if(studentProfile?.WalletAmount > 0){
			try {
				if (isConnected) {
					setOpenModal(true);
					const payload = {};
					payload['DeviceID'] = localStorage.getItem('deviceId');
					payload['EndPoint'] = '/TutorRequest';
					payload['authorization'] = idToken;
					payload['StudentID'] = studentProfile?.ID;
					payload['StudentName'] = studentProfile?.UserName;
					payload['StudentClass'] = studentProfile?.Grade;
					payload['StudentClassID'] = studentProfile?.StudentGradeID.toString();
					payload['StudentImage'] = studentProfile?.ProfilePictureUrl;
					payload['TopicName'] = selectedTopicNames ? selectedTopicNames : topicName; // send topic name 'English,Maths'
					payload['IsDecline'] = false;
					payload['IsAccept'] = false;
					payload['TutorID'] = parseInt(tutor?.ID);
					payload['langid'] = localStorage.getItem('langId');
					payload['requestType'] = 1; // student = 1 tutor= 2
					payload['TutorName'] = tutor?.UserName;
					payload['TutorImage'] = tutor?.ProfilePictureUrl;
					payload['TutorRating'] = tutor?.Rating;
					payload['Price'] = tutor?.Price;
					payload['TutorPrice'] = tutor?.TutorPrice;
					payload['CurrencyType'] = tutor.CurrencyType;
					payload['TutorTotalSesssion'] = Number(tutor?.SessionCount);
					payload['isPaymentDone'] = 0; // default = 0  success =2  failed =3 // after payment
					payload['SessionID'] = sessionId || 0; // default send 0 // after payment send session id
					sessionStorage.setItem('StudentRequest', JSON.stringify(payload));
					console.log("hire tutor ==========>", payload)
					setConnectNowClicked(true);
					socket.emit('PTModule', JSON.stringify(payload));
				}
			
			} catch (error) {
				console.log('error');
			}
		// } else {
		// 	toast(lowAmountMessage)
		// }
	}

	const onCancelToConnect = (event, reason) => {	
		const tutorResponse = JSON.parse(sessionStorage.getItem('TutorResponse'));
		console.log('tutorResponse: ', tutorResponse);
		if (reason && (reason === "backdropClick"  || reason === "escapeKeyDown")) {
			return;
		}
		setOpenModal(false);
		const payload = {};
		payload['DeviceID'] = localStorage.getItem('deviceId');
		payload['EndPoint'] = '/TutorRequest';
		payload['authorization'] = idToken;
		payload['StudentID'] = studentRequest?.StudentID;
		payload['StudentName'] = studentRequest?.StudentName;
		payload['StudentClass'] = studentRequest?.StudentClass;
		payload['StudentClassID'] = studentRequest?.StudentClassID;
		payload['StudentImage'] = studentRequest?.StudentImage;
		payload['TopicName'] = studentRequest?.TopicName // send topic name 'English,Maths'
		payload['IsDecline'] = true;
		payload['IsAccept'] = false;
		payload['TutorID'] = studentRequest?.TutorID;
		payload['langid'] = localStorage.getItem('langId');
		payload['requestType'] = 1; // student = 1 tutor= 2
		payload['TutorName'] = studentRequest?.TutorName;
		payload['TutorImage'] = studentRequest?.TutorImage;
		payload['TutorRating'] = studentRequest?.TutorRating;
		payload['TutorPrice'] = studentRequest?.TutorPrice;
		payload['TutorTotalSesssion'] = studentRequest?.TutorTotalSesssion;
		payload['isPaymentDone'] = 0; // default = 0  success =2  failed =3 // after payment
		payload['SessionID'] = tutorResponse?.SessionID; // default send 0 // after payment send session id
		socket.emit('PTModule', JSON.stringify(payload));
		sessionStorage.removeItem("StudentRequest");
        sessionStorage.removeItem("TutorResponse");
		sessionStorage.removeItem("SessionID");
		sessionStorage.removeItem("TutorID");
		socket.on('GetTutorsResponseEvent', handleTutorResponseEvent);
		setConnectNowClicked(false);
	};

	const handleTutorResponseEvent = (response) => {
		const responseData = JSON.parse(response);
		let filterTutors;
		if (responseData?.StatusCode === 200 && responseData.Message === 'Online Tutor Fetched!') {
			console.log('responseData: ', responseData?.Data);
			setLoading(false);
			// for fetching  tutor
			const lowestPrice = parseInt(responseData?.Data?.LowestPrice) || 0;
			const highestPrice = parseInt(responseData?.Data?.HighestPrice + 1) || 600; // Assuming a default value of 100
			const lowestRating = parseInt(responseData?.Data?.LowestRating) || 0;
			const highestRating = parseInt(responseData?.Data?.HighestRating + 1) || 5;
			const lowestDuration = parseInt(responseData?.Data?.LowestDuration) || 0;
			const highestDuration = parseInt(responseData?.Data?.HighestDuration + 1) || 24;
			setValue1([lowestPrice, highestPrice]);
			setValue2([lowestRating, highestRating]);
			setValue3([lowestDuration, highestDuration]);
			setNationality(responseData?.Data?.Nationalities);
			setSessionId(responseData?.Data?.SessionID)

			if (price?.length === 0) {
				setPrice([lowestPrice, highestPrice]);
			}

			if (rating?.length === 0) {
				setRating([lowestRating, highestRating]);
			}

			if (duration?.length === 0) {
				setDuration([lowestDuration, highestDuration]);
			}
			
			if (responseData?.Data?.SearchResult && searchValueRef.current?.value) {
				filterTutors = (responseData?.Data?.SearchResult || []).filter((item) => {
					const searchValue = searchValueRef.current?.value.toString().toLowerCase();
					const username = item?.UserName?.toString().toLowerCase();
					return username.includes(searchValue);
				});
			} else {
				filterTutors = responseData?.Data?.SearchResult
			}

			setTutors(filterTutors || []);
			const verifiedTutors = filterTutors?.filter(tutor => tutor.VerificationStatus === "true");
			setVerifiedTutors(verifiedTutors);
		}
	}
	
	const fetchTutors = () => {
		if (topicIds && !connectNowClicked) {
			const payload = {
				authorization: idToken,
				DeviceID: localStorage.getItem('deviceId'),
				langid : localStorage.getItem("langId"),
				EndPoint: '/GetTutors',
				HighestDuration: "",
				HighestPrice: "",
				HighestRating: "",
				LowestDuration: "",
				LowestPrice: "",
				LowestRating: "",
				Nationalities: "",
				SearchString: "",
				TopicID: topicIds || "",
			};
            console.log("payload fetchTutors: ", payload)
			socket.emit('PTModule', JSON.stringify(payload));
			socket.on('GetTutorsResponseEvent', handleTutorResponseEvent);
		}
	};

	useEffect(() => {
		if (isConnected && topicIds) {
			fetchTutors();
		}
		return () => {
			if (isConnected) {
				console.log('GetTutorsResponseEvent socket off')
				let payload = {};
				payload['authorization'] = idToken;
				payload['DeviceID'] = localStorage.getItem('deviceId');
				socket?.emit('ClearInterval', JSON.stringify(payload));
				socket.off('GetTutorsResponseEvent');
			} 
		};
	}, [isConnected, topicIds, connectNowClicked]);

	useEffect(() => {
		if (isConnected && connectNowClicked) {
			socket.on('responseEvent', (response) => {
				const responseData = JSON.parse(response);
				console.log('responseData: ', responseData);

				if (responseData.StatusCode === 200 && responseData.Message === 'Request send' && responseData.Data[0]) {
					const tutorResponse = responseData.Data[0];
					if (tutorResponse.StudentID.toString() === studentProfile?.ID.toString()) {
						!tutorResponse?.IsDecline && sessionStorage.setItem('TutorResponse', JSON.stringify(tutorResponse));
						if (tutorResponse.requestType.toString() === '2' && tutorResponse.IsAccept === true) {
							setOpenModal(false);
							navigate('/checkout');
						} else {
							if (tutorResponse.requestType.toString() === '2' && tutorResponse.IsAccept === false) {
								setOpenModal(false);
								setOpenCallRejectModal(true);
							}
						}
						setLoading(false);
					}
				} else if (responseData.StatusCode === 402) {
					toast(lowAmountMessage)
					setOpenModal(false);
					setConnectNowClicked(false);
				}
			});
		}
	}, [isConnected, connectNowClicked]);

	const extractIds = (topics) => {
		let ids = [];
		topics?.forEach((topic) => {
			ids.push(topic.ID);
		});
		return ids;
	};

	const extractTopicsNames = () => {
		let topicNames = [];
		topics?.forEach((topic) => {
			topicNames.push(topic.Value);
		});
		return topicNames.join(',');
	}


	// ************************************************************************************************************************************
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const onNationalityHandler = (item) => {
		setSelectedNationality(item)
	}

	const handleChange1 = (event, newValue, activeThumb) => {
		// socket.close();
		socket?.off('GetTutorsResponseEvent')
		if (!Array.isArray(newValue)) {
			return;
		}

		setPrice(newValue);
        // setValue1(newValue);
		// if (activeThumb === 0) {
		// 	setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
		// } else {
		// 	setValue1([value1[0], Math.max(newValue[1], value1[0] + highestPrice)]);
		// }
	};

	const handleChange2 = (event, newValue, activeThumb) => {
		// socket.close();
		socket?.off('GetTutorsResponseEvent')
		if (!Array.isArray(newValue)) {
			return;
		}
        
		setRating(newValue);
		// setValue2(newValue);
		// if (activeThumb === 0) {
		// 	setValue2([Math.min(newValue[0], value2[1] - minDistance), value2[1]]);
		// } else {
		// 	setValue2([value2[0], Math.max(newValue[1], value2[0] + highestRating)]);
		// }
	}

	const handleChange3 = (event, newValue, activeThumb) => {
		// socket.close();
		socket?.off('GetTutorsResponseEvent')
		if (!Array.isArray(newValue)) {
			return;
		}
        
		setDuration(newValue);
		// setValue3(newValue);
		// if (activeThumb === 0) {
		// 	setValue3([Math.min(newValue[0], value3[1] - minDistance), value3[1]]);
		// } else {
		// 	setValue3([value3[0], Math.max(newValue[1], value3[0] + highestDuration)]);
		// }
	}


	const filterBySubjects = (event) => {
		socket?.off('GetTutorsResponseEvent');
		const selectedTopicIds = event.target.value?.map((subject) => subject?.ID);
		const selectedTopicNames = event.target.value?.map((subject) => subject?.Value).join(',');
		setSelectedTopics(event.target.value);
		setSelectedTopicNames(selectedTopicNames);

		const payload = {
			authorization: idToken,
			DeviceID: localStorage.getItem('deviceId'),
			langid : localStorage.getItem("langId"),
			EndPoint: '/GetTutors',
			// LowestPrice: value1[0] || 0,
			// HighestPrice: value1[1] || 600,
			// LowestRating: value2[0] || 0,
			// HighestRating: value2[1] || 5,
			// LowestDuration: value3[0] || 0,
			// HighestDuration: value3[1] || 24,

			LowestPrice: price[0] || 0,
			HighestPrice: price[1] || 600,
			LowestRating: rating[0] || 0,
			HighestRating: rating[1] || 5,
			LowestDuration: duration[0] || 0,
			HighestDuration: duration[1] || 24,

			Nationalities: selectedNationality || "",
			SearchString: "",
			TopicID: selectedTopicIds.length !== 0 ? selectedTopicIds : topicIds,
		};

		socket.emit('PTModule', JSON.stringify(payload));
		socket.on('GetTutorsResponseEvent', handleTutorResponseEvent);
	};

	const onCallRejectModalClose = () => {
		setOpenCallRejectModal(false);
		setConnectNowClicked(false);
	};

	const handleTutorRatingChange = (event) => {
		setTutorRating(event.target.value);
	};

	const onFilterData = () =>{
		socket.off('GetTutorsResponseEvent');
		const selectedTopicIds = selectedTopics?.map((topic) => topic?.ID)
		const payload = {
			authorization: idToken,
			DeviceID: localStorage.getItem('deviceId'),
			langid : localStorage.getItem("langId"),
			EndPoint: '/GetTutors',
			// LowestPrice: value1[0] || 0,
			// HighestPrice: value1[1] || 600,
			// LowestRating: value2[0] || 0,
			// HighestRating: value2[1] || 5,
			// LowestDuration: value3[0] || 0,
			// HighestDuration: value3[1] || 24,

			LowestPrice: price[0] || 0,
			HighestPrice: price[1] || 600,
			LowestRating: rating[0] || 0,
			HighestRating: rating[1] || 5,
			LowestDuration: duration[0] || 0,
			HighestDuration: duration[1] || 24,

			Nationalities: selectedNationality || "",
			SearchString: "",
			TopicID: selectedTopicIds.length > 0 && selectedTopicIds || topicIds
		};

		socket.emit('PTModule', JSON.stringify(payload));
		socket.on('GetTutorsResponseEvent', handleTutorResponseEvent);
		setOpenDrawer(false)
	}

	const onClearData = () => {
		socket.off('GetTutorsResponseEvent');
		setValue1([])
		setValue2([])
		setValue3([])
		setSelectedTopics([]);
		setSelectedNationality("");
		searchValueRef.current.value = "";
		setOpenDrawer(false);
		const payload = {
			authorization: idToken,
			DeviceID: localStorage.getItem('deviceId'),
			langid : localStorage.getItem("langId"),
			EndPoint: '/GetTutors',
			HighestDuration: "",
			HighestPrice: "",
			HighestRating: "",
			LowestDuration: "",
			LowestPrice: "",
			LowestRating: "",
			Nationalities: "",
			SearchString: "",
			TopicID: topicIds || "",
		};
		socket.emit('PTModule', JSON.stringify(payload));
		socket.on('GetTutorsResponseEvent', handleTutorResponseEvent);
	}

	const onSelectFeedBackHandler = (item) => {
		setSessionFeedbackMsg(item.ID);
		// Check if the item is already selected
		const isSelected = selectedItem && selectedItem.ID === item.ID;

		// If the clicked item is already selected, clear the selection
		if (isSelected) {
			setSelectedItem(null);
		} else {
			// If the clicked item is not selected, update the selectedItem state
			setSelectedItem(item);
		}
	}

	const onSavePrivateTutorFeedBack = async () => {
		try {

			setHireTutorRatingModal(false)
			sessionStorage.removeItem('StudentSessionEnd');
			const payload = {
				SessionID: privateTutorSessionID,
				Ratings: tutorRating,
				FeedbackID: sessionFeedbackMsg
			};
			const response = await axiosPost(`${API_BASE_URL}/SavePrivateTutorFeedback`, payload);
			if (response.data.IsSuccess) {
				toast(response.data.Message)
			} else {
				toast(response.data.Message)
			}

		} catch (error) {
			console.error('An error occurred while saving private tutor feedback:', error);
		}
	};

	useEffect(() => {
		if (studentSessionEnd) {
			dispatch(getStudentProfile())
			setHireTutorRatingModal(true)
		}
	}, [studentSessionEnd]);

	useEffect(() => {
		const handleOnline = async () => {
			setIsOffline(false);
			window.location.reload();	
		};

		const handleOffline = () => {
			setIsOffline(true);
		};
	
		window.addEventListener('online', handleOnline);
		window.addEventListener('offline', handleOffline);
	
		return () => {
			window.addEventListener('online', handleOnline);
		  	window.removeEventListener('offline', handleOffline);
		};
	}, []);

	useEffect(() => {
		if (helperData) {
			const feedbackMessages = helperData.FeedbackMessage.filter((item) => item.RoleID === 3)
			setFeedback(feedbackMessages);
		}

	}, [helperData]) 
   
	useEffect(() => {
		dispatch(getStudentDashBoard());
	}, [dispatch])

	useEffect(() => {
		if (studentProfile) {
			dispatch(getTopics({ GradeID: studentProfile?.StudentGradeID, ExamID: studentProfile?.StudentExaminationID }));
		}
	}, [studentProfile?.StudentGradeID, studentProfile?.StudentExaminationID])

	useEffect(() => {
		let countdown = 30;

		if (openModal) {
			const timerId = setInterval(() => {
				countdown -= 1;
				setTimer(countdown);

				if (countdown <= 0) {
					clearInterval(timerId);
					setOpenModal(false); // Close the modal after 55 seconds
					onCancelToConnect()
				}
			}, 1000);

			return () => {
				// Check if the modal is still open before clearing the interval
				if (openModal) {
					clearInterval(timerId);
				}
			};
		}
	}, [openModal]);

	useEffect(() => {
		if (topics) {
			const topicid = extractIds(topics);
			const topicNames = extractTopicsNames();
			setTopicIds(topicid);
			setTopicName(topicNames);
		}
	}, [topics]);

	const onAgora = (sessionId)=>{
		setOnAgreeLoading(true)
		const userUid = user?.uid;
		const channelName = `channel${sessionId}`;
		sessionStorage.setItem('SessionID', sessionId);
		const tutorResponse = sessionStorage.getItem('TutorResponse');
		if (!tutorResponse) {
			const tutorResponseData = {}
			tutorResponseData['TutorID'] = tutorDashboard?.PrivateTutorInfo[0]?.TutorID;
			tutorResponseData['TutorName'] = tutorDashboard?.PrivateTutorInfo[0]?.TutorName;
			tutorResponseData['TutorImage'] = tutorDashboard?.PrivateTutorInfo[0]?.TutorProfilePictureUrl;
			tutorResponseData['StudentID'] = studentProfile?.ID;
			tutorResponseData['StudentName'] = studentProfile?.UserName;
			tutorResponseData['StudentImage'] = tutorDashboard?.PrivateTutorInfo?.StudentProfilePictureUrl;
			sessionStorage.setItem('TutorResponse', JSON.stringify(tutorResponseData))
		}
		sessionRejoined({TutorID: tutorDashboard?.PrivateTutorInfo[0]?.TutorID, SessionID: sessionId});
		generateAgoraToken(userUid, 2, channelName).then((res) => {
			localStorage.setItem("rtmToken", JSON.stringify(res.RtmToken));
			localStorage.setItem("rtcToken", JSON.stringify(res.RtcToken));
			navigate(`/hire-class-room/${encryptData(sessionId)}`);
		});
	}

	return (
		<React.Fragment>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="dark"
			/>
			{/* {loading && (<Loading message={'Searching for tutors...'} />)} */}
			<React.Fragment>
				<YasaHeader />
				<img src='../../images/cloud.png' alt='' className='cloud-img' />
				<div className="container mb-5">
					<div className='back-btn-with-wallet'>
						<Button
							size="large"
							startIcon={<ArrowBackIcon className="backarrow" />}
							sx={{ px: 2, py: 1.25 }}
							onClick={() => navigate('/')}
							className="back-btn"
						>
							{translation?.BackBtn}
						</Button>
						<span className='wallet-box2'> <AccountBalanceWalletIcon /> {studentProfile?.WalletAmount} KD </span>
					</div>
				</div>

				<div className='container tutor-search-result-page cis-rating-page'>
					<div className='welcome-box-wrap'>
						{/* 1st Step  */}
						<div>
						{
						(tutorDashboard?.PrivateTutorInfo.length >= 1) && 
						<div>
							
						<p>{tutorDashboard?.privateTutorInfo?.TutorName}</p>
	
						<div className="" style={{display :'flex', padding: '20px 20px', background: '#fdecea', border: '1px solid #efcac6'}}>
							<div className="" style={{borderRadius:'50%',width:'60px',height: '60px'}}>
								<img src={tutorDashboard?.PrivateTutorInfo[0]?.TutorProfilePictureUrl} />
							</div>
								<div style={{padding: '0px 15px'}}>
							<h3 className='title-info'>{tutorDashboard?.PrivateTutorInfo[0]?.TutorName}</h3>
							<Button onClick={()=>onAgora(tutorDashboard?.PrivateTutorInfo[0]?.ID)}>{onAgreeLoading && <CircularProgress
								size={24}
								sx={{
									color: '#ffffff',
								}}
								className='ml-5'
							/>}{commonFrontEnd?.JoinSessions}</Button>
								</div>
						</div>
						</div>
                        }
						</div>
						<div className='whitebox-wrap'>
							<div className='app-container'>
								<div className='p-0 mb-20 cus-searchfield'>
									<TextField InputProps={{
										startAdornment: <InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>,
										endAdornment: <InputAdornment position="end" className='inpout-adroment filter-btn'>
											{['bottom'].map((anchor) => (
												<React.Fragment key={anchor}>
													<span className='filterclick-btn' onClick={() => setOpenDrawer(true)}>
														<TuneIcon />
													</span>
													<Drawer
														anchor={anchor}
														open={openDrawer}
														onClose={() => setOpenDrawer(false)} className='drawer_design'>

														<div className='drawer_title_wrap'>
															<div className='cis-rating-page container'>
																<div className='drawer_title'>
																	<h2 className='fs24'>{commonFrontEnd?.SearchFilter}</h2>
																	<Button variant="text" className='text_white'  onClick={() => setOpenDrawer(false)}>
																		<CloseIcon />
																	</Button>
																</div>
															</div>
														</div>
														<div className='filter_wrapper'>
															<div className='container cis-rating-page'>
																<div className='filter_select_items'>
																	<h2 className='fs20 mb-15'>{login?.SelectCountry}</h2>
																	<div className='select_filter_item'>
																		{
																			nationality?.map((item, index) => (
																				<label className='filter_select' key={index}>
																					<input type='radio' onChange={() => onNationalityHandler(item)} />
																					<span className='checkmark'>{item} </span>
																				</label>
																			))
																		}
																	</div>
																</div>

																<div className='filter_select_items'>
																	<h2 className='fs20 mb-15'>{commonFrontEnd?.Price}</h2>
																	<div className='select_filter_item m-0'>
																	<Slider
																			// getAriaLabel={() => 'Minimum distance'}
																			defaultValue={price}
																			onChange={handleChange1}
																			valueLabelDisplay="auto"
																			// getAriaValueText={valuetext}
																			disableSwap
																			color='secondary'
																			min={value1[0] || 0}
																			max={value1[1] || 600}
																		/>
																	</div>
																</div>

																<div className='filter_select_items'>
																	<h2 className='fs20 mb-15'>{commonFrontEnd?.FilterRating}</h2>
																	<div className='select_filter_item m-0'>
																	<Slider
																			// getAriaLabel={() => 'Minimum distance'}
																			defaultValue={rating}
																			onChange={handleChange2}
																			valueLabelDisplay="auto"
																			// getAriaValueText={valuetext}
																			disableSwap
																			color='secondary'
																			min={value2[0] || 0}
																			max={value2[1] || 5}
																		/>
																	</div>
																</div>

																<div className='filter_select_items'>
																	<h2 className='fs20 mb-15'>{commonFrontEnd?.DurationCompleted}</h2>
																	<div className='select_filter_item m-0'>
																	<Slider
																			// getAriaLabel={() => 'Minimum distance'}
																			value={duration}
																			onChange={handleChange3}
																			valueLabelDisplay="auto"
																			// getAriaValueText={valuetext}
																			disableSwap
																			color='secondary'
																			min={value3[0] || 0}
																			max={value3[1] || 24}
																		/>
																	</div>
																</div>

																{/* <div className='filter_select_items'>
																	<h2 className='fs20 mb-15'>Duration Completed</h2>
																	<div className='select_filter_item'>
																		<label className='filter_select'>
																			<input type='checkbox' />
																			<span className='checkmark'>3-8 hours</span>
																		</label>
																		<label className='filter_select'>
																			<input type='checkbox' />
																			<span className='checkmark'>4-8 hours</span>
																		</label>
																		<label className='filter_select'>
																			<input type='checkbox' />
																			<span className='checkmark'>5-8 hours</span>
																		</label>
																		<label className='filter_select'>
																			<input type='checkbox' />
																			<span className='checkmark'>3-6 hours</span>
																		</label>
																	</div>
																</div> */}

																<div className='btn_wrap'>
																	<Stack spacing={2} direction="row" flexWrap="wrap" useFlexGap justifyContent="center">
																	<Button variant="contained" className='btn-1 btn-connect' sx={{ minWidth: 120 }} onClick={onFilterData}>{commonFrontEnd?.ApplyFilter}</Button>
																		<Button variant="outlined" className='btn-1 btn-connect' color='secondary' sx={{ minWidth: 120 }} onClick={onClearData}>{commonFrontEnd?.Clear}</Button>
																	</Stack>
																</div>
															</div>
														</div>
													</Drawer>
												</React.Fragment>
											))}
											<FormControl>
												<InputLabel id="select_subject">{privateClassDashboard?.Subjects}</InputLabel>
												<Select
													labelId="select_subject"
													id="select_subject-checkbox"
													sx={{ width: 200 }}
													placeholder='Select Subject'
													multiple
													value={selectedTopics}
													onChange={filterBySubjects}
													input={<OutlinedInput label="Tag" />}
													renderValue={(selected) => selected.map((x) => x.Value).join(', ')}
												>

													<MenuItem key="default" value={null} disabled>
														<ListItemText primary="Select Subject" />
													</MenuItem>
													{topics?.map((item) => (
														<MenuItem key={item?.ID} value={item}>
															<Checkbox
																checked={selectedTopics?.some((x) => x?.ID === item?.ID)}
															/>
															<ListItemText primary={item?.Value} />
														</MenuItem>
													))}
												</Select>
											</FormControl>

										</InputAdornment>,
									}} fullWidth
										placeholder={privateClassDashboard?.FindTeachers}
										inputRef={searchValueRef}
									/>
								</div>
							</div>
							<div className='connect-to-tuter-sec'>
								<div className='app-container'>
									<div className='tab_design'>
										<Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
											textColor="secondary"
											indicatorColor="secondary">
											<Tab label={privateClassDashboard?.All} {...a11yProps(0)} />
											{/* <Tab label={privateClassDashboard?.Verified} {...a11yProps(1)} /> */}
										</Tabs>


										{/* ***************All Tutors List***************** */}
										<CustomTabPanel value={value} index={0}>
											<div className='tutor_card'>
												{
													loading ? (
														// Render your loader component here
														<p className='text-center'><b>{translation?.SearchingForTutors}</b></p>
													) : tutors?.length > 0 ? (
															// Render your tutors
															tutors?.map((item, index) => (
																<Accordion key={index}>
																	<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">

																		<table border="0" cellSpacing="0" cellPadding="0">
																			<tbody>
																				<tr>
																					<td className="teacher-img">
																						<div className='img_box'>
																							<img src={item?.ProfilePictureUrl} alt='Profile pic' />
																							{
																								item?.OnlineStatusID === '1' ? (<span className="online-status"></span>) :
																									item?.OnlineStatusID === '2' ? (<span className="busy-status"></span>) :
																										(<span className="offline-status"></span>)
																							}

																						</div>
																					</td>
																					<td className="name_rati">
																						{/* <h2 className='fs20'>{item?.UserName} {item?.VerificationStatus === 'true' && (<img src='./images/verified.png' />)}</h2> */}
																						<h2 className='fs20'>{item?.UserName}</h2>
																						<div className='rating_info'>{item?.Rating} <Rating name="read-only" value={parseInt(item?.Rating)} readOnly /></div>
																					</td>
																					<td className='exp-td-hrs-td text-center'>
																						<p className='text-center'><strong>{personalInfo?.Experience}</strong> {item?.TeachingExperience} {personalInfo?.Years}</p>
																				
																						<Chip label={` ${item?.SessionCount} sessions`} sx={{ px: 1, fontSize: 14 }} />
																					</td>
																					<td className="teach-amt"><h2 className='fs20 text-center'>{item?.Price} {item?.CurrencyType}</h2></td>
																					<td className="connect-td">
																						{
																							item?.OnlineStatusID === '1' ? (
																								<Button variant="contained"
																									className='btn-1 btn-connect'
																									onClick={() => onConnectNowClick(item)}
																									fullWidth
																								>{translation?.ConnectNow}</Button>

																							) :
																								item?.OnlineStatusID === '2' ?
																									(<Button variant="contained" className='btn-1 btn-connect busy' fullWidth>{translation?.BusyStatus}</Button>) :
																									item?.OnlineStatusID === '3' && (<Button variant="contained" className='btn-1 btn-connect offline' fullWidth>{translation?.Offline}</Button>)

																						}
																					</td>

																				</tr>
																			</tbody>
																		</table>

																	</AccordionSummary>
																	<AccordionDetails className='accordion-details'>

																		{ item?.Subjects?.length > 0 &&
																			<p className="std-teach"><SchoolIcon />{item?.Subjects.join(", ")}</p>
																		}
																		{/* <p className="std-teach"><PersonIcon />{item?.ActiveStudentsCount}{privateClassDashboard?.ActiveStudents}</p> */}
																		<p className="std-teach"><PublicIcon />{item?.Nationality}</p>
																		<p className='mt-10 px-1'>{item?.TutorDescription}</p>

																	</AccordionDetails>
																</Accordion>
															))
														) : (
															<div className="not-found">
															<p className='text-center'>{privateClassDashboard?.NotutorsFound}</p>
															</div>
														)
													
												}
											</div>
										</CustomTabPanel>

										{/* ***************Verified Tutors List***************** */}
										{/* <CustomTabPanel value={value} index={1}>
											<div className='tutor_card'>
												{	
													loading ? (
														<p className='text-center'><b>{translation?.SearchingForTutors}</b></p>
													) : verifiedTutors?.length > 0 ? (
														verifiedTutors?.map((item, index) => (
															<Accordion key={index}>
																<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">

																<table border="0" cellSpacing="0" cellPadding="0">
																	<tbody>
																		<tr>
																			<td className="teacher-img">
																				<div className='img_box'>
																					<img src={item?.ProfilePictureUrl} alt='Profile pic' />
																					{
																						item?.OnlineStatusID === '1' ? (<span className="online-status"></span>) :
																							item?.OnlineStatusID === '2' ? (<span className="busy-status"></span>) :
																								(<span className="offline-status"></span>)
																					}

																				</div>
																			</td>
																			<td className="name_rati">
																				<h2 className='fs20'>{item?.UserName} {item?.VerificationStatus === 'true' && (<img src='./images/verified.png' />)}</h2>
																				<div className='rating_info'>{item?.Rating} <Rating name="read-only" value={parseInt(item?.Rating)} readOnly /></div>
																			</td>
																			<td className='exp-td-hrs-td text-center'>
																				<p className='text-center'><strong>{personalInfo?.Experience}</strong> {item?.TeachingExperience} {personalInfo?.Years}</p>
																		
																				<Chip label={` ${item?.SessionCount} sessions`} sx={{ px: 1, fontSize: 14 }} />
																			</td>
																			<td className="teach-amt"><h2 className='fs20 text-center'>{item?.Price} {item?.CurrencyType}</h2></td>
																			<td className="connect-td">
																				{
																					item?.OnlineStatusID === '1' ? (
																						<Button variant="contained"
																							className='btn-1 btn-connect'
																							onClick={() => onConnectNowClick(item)}
																							fullWidth
																						>{translation?.ConnectNow}</Button>

																					) :
																						item?.OnlineStatusID === '2' ?
																							(<Button variant="contained" className='btn-1 btn-connect busy' fullWidth>{translation?.BusyStatus}</Button>) :
																							item?.OnlineStatusID === '3' && (<Button variant="contained" className='btn-1 btn-connect offline' fullWidth>{translation?.Offline}</Button>)

																				}
																			</td>

																		</tr>
																	</tbody>
																</table>

																</AccordionSummary>
																<AccordionDetails className='accordion-details'>

																	{ item?.Subjects?.length > 0 &&
																		<p className="std-teach"><SchoolIcon />{item?.Subjects.join(", ")}</p>
																	}
																	<p className="std-teach"><PublicIcon />{item?.Nationality ? item?.Nationality : '-'}</p>
																	<p>{item?.TutorDescription}</p>

																</AccordionDetails>
															</Accordion>
														))
													) :
														(
															<div className="not-found">
																<p className='text-center'>{privateClassDashboard?.NoVerifiedTutorsFound}</p>
															</div>
														)
												}
											</div>
										</CustomTabPanel> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* *****Connect to tutor Dailog Box*********** */}
				<Dialog
					open={openModal}
					keepMounted
					onClose={onCancelToConnect}
					aria-describedby="alert-dialog-slide-description"
					className='cis-dialog-1'
				>
					<DialogTitle>{privateClassDashboard?.ConnectingWithTutor} <span className='clr-red'>{studentRequest?.TutorName}</span></DialogTitle>
					<DialogContent className='text-center'>
						<div className="timing-box">
							<div className="timing-box_text-wrap">
								<span>{timer} <br /> {translation?.Seconds}</span>
							</div>
							<div className="ripple_ timing-box-1">
							</div>
							<div className="ripple_ timing-box-2">
							</div>
							<div className="ripple_ timing-box-3"></div>
						</div>
						<p className='title-info'>{privateClassDashboard?.WhileConnecting}</p>
					</DialogContent>
					<DialogActions sx={{ justifyContent: 'center' }}>
						<Button onClick={onCancelToConnect} variant="contained" color='error' size='large' sx={{ px: 5 }}>{translation?.QuickCancel}</Button>
					</DialogActions>
				</Dialog>

				{/* call reject by tutor  */}
				<Dialog
					open={openCallRejectModal}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogContent>
						<h2 className='title-1 text-center'>{privateClassDashboard?.RejectedByTutor}</h2>
						<div className='text-center'>
							<Button onClick={onCallRejectModalClose} variant="contained" color='error' size='large' sx={{ px: 5 }}>Ok</Button>

						</div>
					</DialogContent>

				</Dialog>

				{/* Hire Tutor Feed Back Modal  */}
				<Dialog
					open={hireTutorRatingModal}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogContent>
						<h2 className='title-1 text-center mb-0'> {commonFrontEnd?.SessionEndTeacher}</h2>
						<div className='mb-3 ratinbox'>
						</div>
						<div className='mb-3 ratinbox'>
							<p className='title-info'>{commonFrontEnd?.HowStudent}</p>
							<div className='good-bad-rating' dir="ltr">
								<span className='rating_good'>{translation?.Good}</span>
								<Rating
									value={parseFloat(tutorRating)}
									onChange={handleTutorRatingChange} />
								<span className='rating_bad'>{translation?.Bad}</span>

							</div>
						</div>
						<div className='mb-3 ratinbox'>
							<div fullWidth className='mb-20'>
								<p className='title-info'>{translation?.DidYouLike}</p>
								<Stack direction="row" spacing={1} className='rating-menus'>
									{feedback?.map((item) => (
										<Chip
											key={item.ID}
											label={item.Value}
											onClick={() => onSelectFeedBackHandler(item)}
											variant="outlined"
											className={selectedItem && selectedItem.ID === item.ID ? "cis-selected" : ""}
										/>
									))}
								</Stack>
							</div>
						</div>
					</DialogContent>
					<DialogActions>
						<Button
							type="submit"
							variant="contained"
							sx={{ mt: 0, mb: 0, pt: 0.5, pb: 0.5, px: 2, fontSize: '18px', textTransform: "none" }}
							size="large"
							className='cis-btn m-0'
							onClick={onSavePrivateTutorFeedBack}
							disabled={!tutorRating || !sessionFeedbackMsg}
						>
							{translation?.QuickSubmit}
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={isOffline}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
				<DialogContent>
					<div className="offline-container">
						<h2 className='title-1 text-center mt-5'>{commonFrontEnd?.NetworkDisconnected}</h2>
						<div className="image-container">
							{/* <img className='center-image' src="../../images/wifi-2.gif" alt="Network disconnected" /> */}
						</div>
						<p className='title-info text-center'>{commonFrontEnd?.NetworkLost}</p>
					</div>
				</DialogContent>
			</Dialog>
			</React.Fragment>


		</React.Fragment>
	);
};

export default FindTutor;