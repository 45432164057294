import React, { useState } from 'react';
import './ViewAllTutors.css';

import { Button, CardMedia } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import CircleIcon from '@mui/icons-material/Circle';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import VideocamIcon from '@mui/icons-material/Videocam';
import StarIcon from '@mui/icons-material/Star';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { getQuickCallsFavtTutors } from '../../actions/quickCallsFavtTutorAction';
import { privateTutorInformation } from '../../actions/privateTutorInformationAction';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'timeago.js';
import CircularProgress from '@mui/material/CircularProgress';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { addToFavtTutor } from '../../actions/addToFavtTutorAction';
import useTranslations from '../../custom-hooks/useTranslations';
import YasaHeader from '../common-components/YasaHeader';
import { getFavtTutorStatus } from '../../actions/getFavtTutorStatusAction';
import { encryptData } from '../../services/encryptDecrypt';
import VideoThumbnail from 'react-video-thumbnail';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import { API_BASE_URL } from '../../config/apiConfig';
import { axiosPost } from '../../services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import timeAgoDate from '../../services/timeAgoDate';
import momentDate from '../../services/momentDate';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

function ViewAllTutors() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const TutorID = sessionStorage.getItem('TutorID');
	const user = JSON.parse(sessionStorage.getItem("userInfo"));
	const callDuration = localStorage.getItem('CallDuration');
	const translations = useTranslations();
	const translation = translations[26]?.QuickCall;
	const sessionVideo = translations[15]?.SessionVideo;
	const commonFrontEnd = translations[21]?.CommonFrontend;
	const personalInfo = translations[7]?.PersonalInfo;
	const [favtTutorButton, setFavtTutorButton] = useState(false);
	const [tutorInfo, setTutorInfo] = useState(0);
	const [tutorRating, setTutorRating] = useState(0);
	const [hireTutorRatingModal, setHireTutorRatingModal] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const [sessionFeedbackMsg, setSessionFeedbackMsg] = useState();
	const [feedback, setFeedback] = useState([]);

	const onBackButtonHandler = () => {
		navigate('/quick-calls');
	}

	const onFavtTutorClickHandler = (tutor) => {
		dispatch(addToFavtTutor({ TutorID: parseInt(tutor?.ID) }));
	}

	const onRatingClickHandler = (tutor) => {
		setTutorInfo(tutor);
		if (tutor.Type === 'QC') {
			sessionStorage.setItem('QuestionID', tutor?.ID);
			sessionStorage.setItem('TutorResponse', JSON.stringify(tutor));
			navigate('/student-ratings')
		} else {
			setHireTutorRatingModal(true)
		}
	}

	const onRecordingClickHandler = (session) => {
		sessionStorage.setItem('SessionID', session.ID);
		sessionStorage.setItem('QuickCallRecordedSession', JSON.stringify(session));
		navigate(`/quick-calls-details/${encryptData(session.ID)}`);
	};

	const onSelectFeedBackHandler = (item) => {
		setSessionFeedbackMsg(item.ID);
		// Check if the item is already selected
		const isSelected = selectedItem && selectedItem.ID === item.ID;

		// If the clicked item is already selected, clear the selection
		if (isSelected) {
			setSelectedItem(null);
		} else {
			// If the clicked item is not selected, update the selectedItem state
			setSelectedItem(item);
		}
	}

	const handleTutorRatingChange = (event) => {
		setTutorRating(event.target.value);
	};

	const onSavePrivateTutorFeedBack = async () => {
		try {

			setHireTutorRatingModal(false)
			sessionStorage.removeItem('StudentSessionEnd');
			const payload = {
				SessionID: tutorInfo?.ID,
				Ratings: tutorRating,
				FeedbackID: sessionFeedbackMsg
			};
			const response = await axiosPost(`${API_BASE_URL}/SavePrivateTutorFeedback`, payload);
			if (response.data.IsSuccess) {
				toast(response.data.Message)
				// can add logic over here on success
			} else {
				toast(response.data.Message)
			}

		} catch (error) {
			console.error('An error occurred while saving private tutor feedback:', error);
		}
	};

	
	// const favtTutors = useSelector(({ quickCallsFavtTutorReducer }) => quickCallsFavtTutorReducer.tutors);
	const helperData = useSelector(({ helperDataReducer }) => helperDataReducer?.helperData);
	const favtTutors = useSelector(({ privateTutorInformationReducer }) => privateTutorInformationReducer.privateTutorInfo);
	const addedFavtTutors = useSelector(({ addToFavtTutorReducer }) => addToFavtTutorReducer.tutor[0]);
	const favtTutorStatus = useSelector(({ getFavtTutorStatusReducer }) => getFavtTutorStatusReducer.tutor);
	
	useEffect(() => {
		// dispatch(getQuickCallsFavtTutors({ TutorID: TutorID }));
		dispatch(privateTutorInformation({ TutorID: TutorID }))
		dispatch(getFavtTutorStatus({ TutorID: parseInt(TutorID) }));
	}, [dispatch]);

	useEffect(() => {
		if (helperData) {
			const feedbackMessages = helperData.FeedbackMessage.filter((item) => item.RoleID === 3)
			setFeedback(feedbackMessages);
		}

	}, [helperData])

	useEffect(() => {
		setFavtTutorButton(addedFavtTutors?.Added)
	}, [addedFavtTutors])

	useEffect(() => {
		setFavtTutorButton(favtTutorStatus?.IsFavourite);
	}, [favtTutorStatus]);

	return (
		<>
			<ToastContainer />
			<YasaHeader />
			<img src='../../images/cloud.png' alt='' className='cloud-img' />
			<div className="container mb-5">
				<Button
					size="large"
					startIcon={<ArrowBackIcon className="backarrow" />}
					sx={{ px: 2, py: 1.25 }}
					onClick={() => onBackButtonHandler()}
					className="back-btn"
				>
					{translation?.BackBtn}
				</Button>
			</div>

			<div className='container'>
				<div className='welcome-box-wrap cis-tutor-page'>
					{
						favtTutors?.TutorData?.map((tutor, index) => (
							<React.Fragment key={index}>
								<div className='cis-tutor-info'>
									<div className='welcome-user bg-light-blue border-bottom-gray'>
										<Grid container spacing={2}>
											<Grid item xs={12} sm={12} md={12}>
												<div className='cis-title-2col'>
													<h2 className='cis-title-1 mb-0'>{tutor?.UserName}</h2>
												</div>
												<Card sx={{ display: 'flex' }} className="cis-card">
													<Box className="cis-cardMedia">
														<CardMedia
															component="img"
															image={tutor?.ProfilePictureUrl === '' ? `https://www.lagrave.org/wp-content/uploads/2017/04/No-photo-available_5.jpg` : tutor?.ProfilePictureUrl}
															alt=""
														/>
													</Box>
													<Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>
														<CardContent sx={{ flex: '1 1 auto' }}>
															<h2 className='cis-title-2 mb-0 mt-0'>
																<div>
																	{
																		tutor?.OnlineStatusType === 'Online' ? <><Box component="span" sx={{ color: '#33ff33' }}>{translation?.ActiveStatus}</Box> {translation?.YasaProStudent}</> : tutor?.OnlineStatusType === 'Offline' ? <><Box component="span" sx={{ color: 'rgb(229, 218, 218)' }}>{translation?.InActiveStatus}</Box> {translation?.YasaProStudent}</> : tutor?.OnlineStatusType === 'Busy' && <><Box component="span" sx={{ color: '#EB0029' }}>{translation?.BusyStatus}</Box> {translation?.YasaProStudent}</>
																	}

																</div>
																<div>
																	{tutor?.TutorTotalSession} {translation?.SessionWithYou}</div>
																	<Rating name="simple-controlled" value={tutor?.ratings} readOnly />
															</h2>
															<div className='cis-card-action'>
																<div className='cis-favourite-btn-wrap'>
																	{
																		!favtTutorButton ? (
																			<Button variant="outlined"
																				size='small'
																				sx={{ width: '150px' }}
																				className='favourite-button'
																				onClick={() => onFavtTutorClickHandler(tutor)}>
																				<StarIcon sx={{ color: '#8e8b8b' }} /> {translation?.FavouriteTutor}
																			</Button>
																		) : (<Button
																			variant="outlined"
																			size="small"
																			sx={{
																				width: '150px',
																				borderColor: 'blue',
																			}}
																			className="favourite-button favourited"
																			onClick={() => onFavtTutorClickHandler(tutor)}
																		>
																			<StarIcon sx={{ color: '#F3C10F' }} />
																			{translation?.FavouriteTutor}
																		</Button>)


																	}

																</div>
															</div>
														</CardContent>
													</Box>
												</Card>
											</Grid>
										</Grid>
									</div>
								</div>
							</React.Fragment>
						))
					}

					{/* <TableContainer component={Paper}>
						<Table sx={{ minWidth: 650 }} aria-label="simple table">
							<TableBody>
								<TableRow
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									<TableCell component="th" scope="row">
										<p>Nationality</p>
										<p></p>
									</TableCell>
									<TableCell>
										<p>Nationality</p>
										<p></p>
									</TableCell>
									<TableCell>
										<p>Nationality</p>
										<p></p>
									</TableCell>
						
								</TableRow>
								<TableRow>
									<TableCell>
										<p>Nationality</p>
										<p></p>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>
										<p>Nationality</p>
										<p></p>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer> */}

					{
						favtTutors?.TutorData?.length > 0 && 
						<TableContainer component={Paper}>
							<Table aria-label="simple table">
								<TableBody>
									<TableRow>
										<TableCell align="center">
											<h6>{commonFrontEnd?.Nationality}</h6>
											<p style={{fontWeight: 'bold'}}>{favtTutors?.TutorData[0]?.Nationality}</p>
										</TableCell>
										<TableCell align="center">
											<h6>{personalInfo?.Experience}</h6>
											<p style={{fontWeight: 'bold'}}>{favtTutors?.TutorData[0]?.Experience}</p>
										</TableCell>
										<TableCell align="center">
											<h6>{personalInfo?.Qualification}</h6>
											<p style={{fontWeight: 'bold'}}>{favtTutors?.TutorData[0]?.Qualifications}</p>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
							<Table aria-label="simple table">
								<TableBody>
									<TableRow>
										<TableCell align="center">
											<h6>{translation?.Know}</h6>
											<p style={{fontWeight: 'bold'}}>{favtTutors?.TutorData[0]?.Topics?.map((item) => item?.Value).join(", ")}</p>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
							<Table aria-label="simple table">
								<TableBody>
									<TableRow>
										<TableCell align="center">
											<h6>{translation?.AboutTutor}</h6>
											<p style={{fontWeight: 'bold'}}>{favtTutors?.TutorData[0]?.TutorNotes}</p>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					}

					

					<div className='whitebox-wrap cis-tutor-cards'>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<div className='cis-title-2col'>
									<h2 className='cis-title-1 mb-0'>{translation?.RecentSession}</h2>
								</div>
							</Grid>

							{
								!favtTutors?.TutorCall ? (
									<Grid container justify="center" alignItems="center" item xs={12}>
										<CircularProgress />
									</Grid>
								) : (
									favtTutors?.TutorCall.length === 0 ? (
										<Grid container justify="center" alignItems="center" item xs={12}>
											<Typography variant="h6">{translation?.NotFound}</Typography>
										</Grid>
									) : (
										<React.Fragment>
											{
												favtTutors?.TutorCall?.map((session, index) => (
													<React.Fragment key={index}>
														<Grid item xs={12} md={8}>

															<Card sx={{ display: 'flex' }} className="cis-card">
																<Box className="cis-cardMedia">
																	{ session?.RecordedSessionURL ? 
																		<VideoThumbnail
																		videoUrl={session?.RecordedSessionURL}
																		snapshotAtTime ={20}
																		// width={300}
																		// height={300}
																		/> : <div className='video-thumbnail-placeholder' alt=''> </div> }
																	{/* <div className='cis-sessions-short-info'>{translation?.Upto} {session?.CallDuration === null ? 0 : session?.CallDuration} {translation?.Minutes}</div> */}
																</Box>
																<Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>
																	<CardContent sx={{ flex: '1 1 auto' }}>
																		<div className='cis-Chips'>
																			<Chip icon={<WatchLaterIcon />} variant="outlined" label={timeAgoDate(session?.CreatedDate)} className='cis-chip' />
																			<Chip icon={<CalendarTodayIcon />} variant="outlined" label={momentDate(session?.CreatedDate)} className='cis-chip' />
																			{/* {session?.TopicName.length > 0 && <Chip label={session?.TopicName.join(', ')} />}
																			{session?.SubTopicName.length > 0  && <Chip label={session?.SubTopicName.join(', ')} />} */}
																		</div>
																		{/* <Typography component="div" variant="h5" className='mb-5'>
																			{session?.Notes}
																		</Typography> */}
																		<div className='cis-card-action'>
																			<span className='cis-rating-box'><Rating value={session?.Ratings} readOnly /></span>
																		</div>
																		{
																		Number(session?.Ratings) === 0 && <div className="mb-3">
																			<Button variant="contained" size='small' type="button" onClick={() => onRatingClickHandler(session)}> {sessionVideo?.Rating}</Button>
																			<Button sx={{marginLeft: 1, margin: 1}} variant="contained" size='small' type="button" onClick={() => onRecordingClickHandler(session)}><span className='ml-5'><VideocamIcon /></span> {translation?.Recording}</Button>
																		</div>
																	}
																		{/* <div className='cis-recording' 
																		style={{ 'cursor': 'pointer' }} 
																		onClick={() => onRecordingClickHandler(session)}> */}
																			{/* <span><VideocamIcon /></span> {translation?.Recording} */}
																		{Number(session?.Ratings) !== 0 &&	<Button variant="contained" size='small' type="button" onClick={() => onRecordingClickHandler(session)}><span className='ml-5'><VideocamIcon /></span> {translation?.Recording}</Button> }
																		{/* </div> */}
																	</CardContent>
																</Box>
															</Card>
														</Grid>
														<Grid item xs={12} md={4}>
														</Grid>
														<div item xs={12} className='cis-space-50'></div>
													</React.Fragment>
												))
											}
										</React.Fragment>
									)
								)
							}

						</Grid>
					</div>
				</div>
			</div>

			<Dialog
					open={hireTutorRatingModal}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogContent>
						<h2 className='title-1 text-center mb-0'> {commonFrontEnd?.SessionEndTeacher}</h2>
						{callDuration && <p className='title-info recent-session-time'><QueryBuilderIcon /> {callDuration} {translation?.Duration}</p>}
						
						<div className='mb-3 ratinbox'>
							<p className='title-info'>{commonFrontEnd?.HowStudent}</p>
							<div className='good-bad-rating' dir="ltr">
								<span className='rating_good'>{translation?.Good}</span>
								<Rating
									value={parseFloat(tutorRating)}
									onChange={handleTutorRatingChange} />
								<span className='rating_bad'>{translation?.Bad}</span>

							</div>
						</div>
						<div className='mb-3 ratinbox'>
							<div fullWidth className='mb-20'>
								<p className='title-info'>{translation?.DidYouLike}</p>
								<Stack direction="row" spacing={1} className='rating-menus'>
									{feedback?.map((item) => (
										<Chip
											key={item.ID}
											label={item.Value}
											onClick={() => onSelectFeedBackHandler(item)}
											variant="outlined"
											className={selectedItem && selectedItem.ID === item.ID ? "cis-selected" : ""}
										/>
									))}
								</Stack>
							</div>
						</div>
					</DialogContent>
					<DialogActions>
						<Button
							type="submit"
							variant="contained"
							sx={{ mt: 0, mb: 0, pt: 0.5, pb: 0.5, px: 2, fontSize: '18px', textTransform: "none" }}
							size="large"
							className='cis-btn m-0'
							onClick={onSavePrivateTutorFeedBack}
							disabled={!tutorRating || !sessionFeedbackMsg}
						>
							{translation?.QuickSubmit}
						</Button>
					</DialogActions>
				</Dialog>

			<img src='../../images/bottom-bg.png' alt='' className='cloud-img' />
		</>
	);
}

export default ViewAllTutors;