import * as actionTypes from "../actions/actionTypes";

const initialState = {applanguage: []};

const AppLanguageReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_APP_LANGUAGE:
      return {
        ...state,
        applanguage: [action.payload.applanguage],
      };

    default:
      return state;
  }
};
export default AppLanguageReducer;
