import { List, ListItem } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import HomePageContainer from "./HomePageContainer";
import useTranslations from "../../custom-hooks/useTranslations";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStudentProfile } from "../../actions/studentProfileAction";
import Loading from "../loader/Loading";

const SelectStudyList = [
	{
		icon: "../../images/icon/1.svg",
		heading: "Quick Calls",
		description: "Lorem ipsum dolor sit amet, conetur adiping elit.",
	},
	{
		icon: "../../images/icon/3.svg",
		heading: 'Courses',
		description: "Lorem ipsum dolor sit amet, conetur adiping elit.",
	},
	{
		icon: "../../images/icon/2.svg",
		heading: "Private Classes",
		description: "Lorem ipsum dolor sit amet, conetur adiping elit.",
	},

];

const SelectStudy = () => {
	const translations = useTranslations();
	const dispatch = useDispatch();
	const translation = translations[20]?.WebDashboard;
	const commonTranslation = translations[21]?.CommonFrontend
	const studyTranslations = { ...translation, ...commonTranslation }
	const isNewUser = JSON.parse(sessionStorage.getItem("loginToken"))?.isNewUser;
	const studentProfile = useSelector(({ studentProfileReducer }) => studentProfileReducer.student);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (!isNewUser) {
			dispatch(getStudentProfile());
		}
	}, [dispatch]);

	useEffect(() => {
		if (studentProfile.length > 0) {
			// sessionStorage.setItem('studentProfile', JSON.stringify(studentProfile[0]));
			sessionStorage.setItem('ProfileInfo', JSON.stringify(studentProfile[0]));
		}
	}, [studentProfile]);

	useEffect(() => {
		if (studyTranslations.QuickCalls) {
			setLoading(false)
		}
	}, [studyTranslations])


	return (
		<React.Fragment>
			{loading && (<Loading message={'Please wait...'} />)}
			<HomePageContainer>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Box component="form" noValidate sx={{ mt: 1 }}>
						<div className="mb-20">
							<h2 className="title2">{studyTranslations?.Heading}</h2>
						</div>

						<div className="mb-20">
							<List
								sx={{ width: "100%", bgcolor: "background.paper" }}
								className="loginselectList"
							>
								{SelectStudyList?.map((item, index) => (
									<SelectStudyListItem
										key={index}
										listItem={item}
										studyTranslations={studyTranslations}
									/>
								))}
							</List>
						</div>
					</Box>
				</Box>
			</HomePageContainer>

		</React.Fragment>
	);
};

export default SelectStudy;

const SelectStudyListItem = ({ listItem, studyTranslations }) => {
	
	const route = listItem?.heading?.toLowerCase().split(" ").join("-");
	const navigate = useNavigate();
	const redirect = (route) => {
		navigate(`/${route}`);
	};

	const listItemHeading = convertPascalCase(listItem?.heading);
	return (
		<>
			{studyTranslations !== undefined && (
				<ListItem
					alignItems="flex-start"
					className="activeList"
					onClick={() => redirect(route)}
				>
					<span className="lsl-icon">
						<img src={listItem.icon} alt="" />
					</span>
					<div className="lsl-content">
						{/* <h2>{studyTranslations[capitalizeFirstLetter(listItem?.heading)]}</h2> */}
						<h2>{studyTranslations[listItemHeading]}</h2>
						<p>{studyTranslations[`ShortDesc${listItemHeading}`]}</p>
					</div>
				</ListItem>
			)}
		</>
	);
};

export const convertPascalCase = (string) => {
	const splitedWord = string?.split(" ");
	if (splitedWord?.length === 1) {
		return string;
	} else {
		return splitedWord?.join("");
	}
};
