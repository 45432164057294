import * as React from 'react';
import './Checkout.css';
import { useState } from "react";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Rating from '@mui/material/Rating';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import YasaHeader from '../../components/common-components/YasaHeader';
import { API_BASE_URL } from '../../config/apiConfig';
import { axiosGet, axiosPost } from '../../services/api';
import { useEffect } from 'react';
import useSocket from '../../custom-hooks/useSocket';
import { Dialog, DialogContent } from '@mui/material';
import generateAgoraToken from '../../services/AgoraTokenService';
import { encryptData } from '../../services/encryptDecrypt';
import useTranslations from "../../custom-hooks/useTranslations";
import CircularProgress from '@mui/material/CircularProgress';

const Checkout = () => {
    const { isConnected, socket } = useSocket();
    const navigate = useNavigate();
    const tutor = JSON.parse(sessionStorage.getItem('TutorResponse'));
    // const [openPaymentProcessModal, setPaymentProcessModal] = useState(false);
    const user = JSON.parse(sessionStorage.getItem("userInfo"));
    const translations = useTranslations();
    const translation = translations[26]?.QuickCall;
    const commonFrontend = translations[21]?.CommonFrontend;
    const[paynowLoading,setPaynowLoading] = useState(false)

    const options = {
        items: 1,
        rewind: true,
        dots: true,
        autoplay: true
    };

    const onPayClickHandler = async () => {
        setPaynowLoading(true)
        showOverlay();
        const payloadTransaction = {};
        payloadTransaction['TransactionAmount'] = tutor?.TutorPrice === '0' ? '1' : tutor.TutorPrice;
        payloadTransaction['TutorID'] = parseInt(tutor?.TutorID);

        try {
            const response = await axiosPost(`${API_BASE_URL}/PayForPrivateTutor`, payloadTransaction);

            const paymentStatus = await response?.data?.Data?.PaymentStatusBool
            const paymentResponse =  response?.data?.Data.ID
            const sessionID = paymentResponse;
            sessionStorage.setItem('SessionID', sessionID);

            const payload = {};
            payload['DeviceID'] = localStorage.getItem('deviceId');
            payload['EndPoint'] = '/TutorRequest';
            payload['authorization'] = JSON.parse(sessionStorage.getItem('loginToken'))?.idToken;
            payload['StudentID'] = tutor?.StudentID;
            payload['StudentName'] = tutor?.StudentName;
            payload['StudentClass'] = tutor?.StudentClass;
            payload['StudentClassID'] = tutor?.StudentClassID;
            payload['StudentImage'] = tutor?.StudentImage;
            payload['TopicName'] = tutor?.TopicName; // send topic name 'English,Maths'
            payload['IsDecline'] = false;
            payload['IsAccept'] = false;
            payload['TutorID'] = tutor?.TutorID;
            payload['langid'] = localStorage.getItem('langId');
            payload['requestType'] = 1; // student = 1 tutor= 2
            payload['TutorName'] = tutor?.TutorName;
            payload['TutorImage'] = tutor?.TutorImage;
            payload['TutorRating'] = tutor?.TutorRating;
            payload['TutorPrice'] = tutor?.TutorPrice;
            payload['TutorTotalSesssion'] = Number(tutor?.TutorTotalSesssion);
            payload['SessionID'] = tutor?.SessionID; // default send 0 // after payment send session id
            console.log('Accept Payload: ', payload);

            if (paymentStatus) {
                payload['isPaymentDone'] = 2;
                // setPaymentProcessModal(true);
                onConnectHireTutor();
            } else {
                payload['isPaymentDone'] = 3; // default = 0  success =2  failed =3 // after payment
            }  

            console.log('payload: ', payload);
            socket.emit('PTModule', JSON.stringify(payload));
        }
        catch(err){
            console.log('err:', err);
            alert('something went wrong!')
        }
        
    }

    const onCancelClickHandler = async () => {
        const payload = {};
        payload['DeviceID'] = localStorage.getItem('deviceId');
        payload['EndPoint'] = '/TutorRequest';
        payload['authorization'] = JSON.parse(sessionStorage.getItem('loginToken'))?.idToken;
        payload['StudentID'] = tutor?.StudentID;
        payload['StudentName'] = tutor?.StudentName;
        payload['StudentClass'] = tutor?.StudentClass;
        payload['StudentClassID'] = tutor?.StudentClassID;
        payload['StudentImage'] = tutor?.StudentImage;
        payload['TopicName'] = tutor?.TopicName; // send topic name 'English,Maths'
        payload['IsDecline'] = true;
        payload['IsAccept'] = true;
        payload['TutorID'] = tutor?.TutorID;
        payload['langid'] = localStorage.getItem('langId');
        payload['requestType'] = 1; // student = 1 tutor= 2
        payload['TutorName'] = tutor?.TutorName;
        payload['TutorImage'] = tutor?.TutorImage;
        payload['TutorRating'] = tutor?.TutorRating;
        payload['TutorPrice'] = tutor?.TutorPrice;
        payload['TutorTotalSesssion'] = Number(tutor?.TutorTotalSesssion);
        payload['SessionID'] = tutor?.SessionID; // default send 0 // after payment send session id
        payload['isPaymentDone'] = 0;
        console.log('Cancel Payload: ', payload);

        sessionStorage.removeItem("StudentRequest");
        sessionStorage.removeItem("TutorResponse")

        socket.emit('PTModule', JSON.stringify(payload));
        navigate('/courses');
    }

    const onConnectHireTutor = () => {
        const sessionID = sessionStorage.getItem('SessionID');
        const userUid = user?.uid;
        const channelName = `channel${sessionID}`;
        generateAgoraToken(userUid, 2, channelName).then((res) => {
            localStorage.setItem("rtmToken", JSON.stringify(res.RtmToken));
            localStorage.setItem("rtcToken", JSON.stringify(res.RtcToken));
            // setPaymentProcessModal(false);
            navigate(`/hire-class-room/${encryptData(sessionID)}`);
        });
    }

    const showOverlay = () => {
        var overlay = document.createElement("div");
        overlay.setAttribute("id", "overlay");
        var text = document.createElement("div");
        text.setAttribute("id", "text");
        text.textContent =
            localStorage.getItem('i18nextLng') === "en"
                ? "Loading... Please do not close the browser"
                : "جاري التحميل ... الرجاء عدم إغلاق المتصفح";
        overlay.appendChild(text);
        document.body.appendChild(overlay);
    }


    // ************** To hide the overlay **********************
    const hideOverlay = () => {
        document.getElementById("overlay").style.display = "none";
    }


    return (
        <React.Fragment>
            <YasaHeader />
            <img src='../../images/cloud.png' alt='' className='cloud-img' />
            <div className="container mb-5">
                {/* <Button
                    size="large"
                    startIcon={<ArrowBackIcon className="backarrow" />}
                    sx={{ px: 2, py: 1.25 }}
                    onClick={() => navigate(-1)}
                    className="back-btn"
                >
                    {translation?.BackBtn}
                </Button> */}
            </div>
            <div className='container cis-rating-page checkout-page'>
                <div className='app-container'>
                    <div className="welcome-box-wrap">
                        <div className='whitebox-wrap'>
                            <div className='checkout-layout'>
                                {/* <div className='white-card profile_card pc-design-2 alert-text'>
                                    {commonFrontend?.BookingMsg}
                                    </div> */}

                                <div className='profile_card pc-design-2 mt-0'>
                                    <img alt='' src={tutor?.TutorImage} />
                                    <div className='pc-content mb-15'>
                                        <h2>{tutor?.TutorName}</h2>
                                        <Rating name="simple-controlled" value={parseInt(tutor?.TutorRating)} readOnly />
                                        {/* <p>{commonFrontend?.SessionCompleted}<span className='fw-600'>{tutor?.TutorTotalSesssion}</span></p> */}
                                        <h3 className='tutor-charges-info'><span>{tutor?.Price} {tutor?.CurrencyType}</span>{translation?.PerHourCharages} </h3>
                                    </div>
                                    <Button type="button" variant="contained" onClick={onPayClickHandler} fullWidth sx={{maxWidth:'200px', margin: '0 auto'}} disabled={paynowLoading} >
                                            {/* {paynowLoading && <CircularProgress
                                                size={24}
                                                sx={{
                                                    color: '#ffffff',
                                                }}
                                                className='ml-5'
                                            />} */}
                                            {translation?.PayNow}</Button>
                                </div>
                                    <Button type="button" variant="contained" color="error" onClick={onCancelClickHandler} fullWidth sx={{maxWidth:'200px', margin: '0 auto', display: 'flex', fontWeight: 500, fontSize: '120%'}} disabled={paynowLoading} >
                                        {translation?.QuickCancel}</Button>
                                {/* <div className='checkout-bottom'>
                                    <Grid container spacing={0}>
                                      
                                        <Grid item xs={12} sx={{marginTop:5}} hidden>

                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel htmlFor="outlined-adornment-password">{commonFrontend?.CouponCodeHere}</InputLabel>
                                                <OutlinedInput
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <Button variant="contained" fullWidth className='error' edge="end" color='error'>{translation?.Remove}</Button>
                                                        </InputAdornment>
                                                    }
                                                    label="Coupon Code Here"
                                                />
                                            </FormControl>

                                        </Grid>

                                        <Grid item xs={12} hidden>
                                            <ul className='web-list remove-spacing'>
                                                <li><span >{tutor?.TutorName}</span>  <span>{tutor?.TutorPrice} د.ك</span> </li>
                                                <li><span className='color-2'>{commonFrontend?.DiscountApplied}</span>  <span className='fw-600 color-2'>{commonFrontend?.OkButton}KD</span> </li>
                                                <li className='list-total-info'><span className='fw-600'>{translation?.TotalPayment}</span>  <span className='fw-600'>{tutor?.TutorPrice} د.ك</span> </li>
                                            </ul>
                                        </Grid>
                                       
                                        
                                        <Grid item xs={12} hidden>
                                            <div className='mt-30 text-center'>
                                                <Button type="button" variant="contained" onClick={onPayClickHandler} fullWidth sx={{maxWidth:'200px', margin: '0 auto'}} >
                                                    {paynowLoading && <CircularProgress
                                                        size={24}
                                                        sx={{
                                                            color: '#ffffff',
                                                        }}
                                                        className='ml-5'
                                                    />}
                                                    {translation?.PayNow}</Button>
                                            </div>
                                        </Grid>


                                    </Grid>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {/* <Dialog
                open={openPaymentProcessModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <h2 className='title-1 text-center'>{translation?.PaymentSuccess}...</h2>
                    <div className='text-center'>
                        <Button
                            type="button"
                            variant="contained"
                            sx={{ mt: 0, mb: 0, pt: 0.5, pb: 0.5, px: 2, fontSize: '18px', textTransform: "none" }}
                            size="large"
                            className='cis-btn m-0'
                            onClick={onConnectHireTutor}
                        >
                           {translation?.ConnectToTutor}
                        </Button>

                    </div>
                </DialogContent>
            </Dialog> */}
        </React.Fragment>
    );
};

export default Checkout;