import React from "react";
import {
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Rating,
  Typography,
} from "@mui/material";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Box, Stack } from "@mui/system";
import ReactTimeAgo from "react-time-ago";
import Moment from "react-moment";
import { encryptData } from "../../services/encryptDecrypt";
import useTranslations from "../../custom-hooks/useTranslations";

const ClassDetailCard = ({
  classDetail,
  showClassDetails,
  showChatTab,
  translations,
}) => {
  const appLanguage = localStorage.getItem("I18N_LANGUAGE");
  const i18nTranslations = useTranslations();

  const i18nTranslation = i18nTranslations[8]?.PrivateClassDashboard;
  const commonTranslation = i18nTranslations[21]?.CommonFrontend;
  const yourCourseTrans = i18nTranslations[18]?.YourCourses;
  const privateClassTranslations = {
    ...i18nTranslation,
    ...commonTranslation,
    ...yourCourseTrans,
  };

  return (
    <div className="cis-private-class">
      <Grid container spacing={2}>
        <Grid item xs={12} md={7} lg={8}>
          <Card sx={{ display: "flex" }} className="cis-card">
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CardContent sx={{ flex: "1 1 auto" }}>
                <Typography component="div" variant="h5">
                  {classDetail?.Value}
                  &nbsp;&nbsp;
                  {
                    classDetail?.ClassStatus === 'Active' ? <span class="status-info status-active"> {privateClassTranslations?.ActiveCalls}  </span> : classDetail?.ClassStatus === 'Complete' ? <span class="status-info status-completed"> {privateClassTranslations?.CompletedCalls}  </span> : classDetail?.ClassStatus === 'Cancelled' && <span class="status-info status-cancelled"> {privateClassTranslations?.CancelledCalls}  </span>
                  }

                </Typography>

                <div className="cis-Chips-2 mb-0">
                  <Chip
                    icon={<WatchLaterIcon />}
                    variant="outlined"
                    label={classDetail.ClassesTimmings}
                  />
                  <Chip
                    icon={<CalendarTodayIcon />}
                    variant="outlined"
                    label={`Starts on ${classDetail?.ClassesDate}`}
                  />
                </div>
                <p className="mt-0 mb-10">{classDetail?.ShortDescription}</p>
                <div>
                  <Stack direction="row" spacing={2}>
                    {/* <Button
                      className={`cis-class-status-btn ${
                        classDetail?.ClassStatus === "Active"
                          ? "cis-active-btn"
                          : "cis-complete-btn"
                      }`}
                      size="large"
                      sx={{ px: 4 }}
                    >
                      {translations?.[classDetail?.ClassStatus]}
                    </Button> */}
                    <Button
                      className="cis-detail-btn"
                      size="large"
                      sx={{ px: 4 }}
                      onClick={() => showClassDetails(encryptData(classDetail.ID))}
                    >
                      {translations?.Detail}
                    </Button>
                  </Stack>
                </div>
              </CardContent>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
          <Box className="cis-teacher-card">
            {classDetail.IsTutorAssigned ? (
              <div className="cis-teacher-info">
                <img
                  src={classDetail.ImageURL}
                  className="cis-teacher-img"
                  alt=""
                />
                <div className="cis-content">
                  <h5>{classDetail.TutorName}</h5>
                  <Rating
                    name="read-only"
                    value={classDetail?.TutorRating}
                    readOnly
                  />
                  <Button
                    variant="outlined"
                    sx={{ px: 2 }}
                    onClick={() => showChatTab(classDetail?.ID)}
                  >
                    {translations?.Message}
                  </Button>
                </div>
              </div>
            ) : (
              <div>No tutor assigned yet</div>
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default ClassDetailCard;