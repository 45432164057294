import { useEffect } from "react";

function Timers({seconds,setSeconds}) {
  
    useEffect(() => {
		const interval = setInterval(() => {
			if (seconds > 0) {
			setSeconds(seconds - 1);
			}
		
			if (seconds === 0) {
			clearInterval(interval);
			} 

		}, 1000);
	  
		return () => {
		  clearInterval(interval);
		};

	}, [seconds]);
  
    return (
      <div>
        {(seconds>0)&&<span style={{fontWeight: 600}}>00:{seconds}</span>}
      </div>
    );
  }

  export default Timers;