import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const loginToken = sessionStorage.getItem("loginToken");
  let location = useLocation();

  if (!loginToken) {
    console.log('Return to Login')
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
};

export default ProtectedRoute;
