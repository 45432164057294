import React, { useState, useEffect, useParams } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import useTranslations from "../../custom-hooks/useTranslations";
import { CustomChat } from "./Chat";


const QuickCallsNotesSection = () => {
  const translations = useTranslations();
  const [tabValue, setTabValue] = useState(0);
	const translation = translations[26]?.QuickCall;
  const classDetail = translations[12]?.ClassDetail;

  const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

  function TabPanel(props) {
		const { children, value, index, ...other } = props;

		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}
			>
				{value === index && (
					<Box sx={{ p: 3 }}>
						<Typography>{children}</Typography>
					</Box>
				)}
			</div>
		);
	}

  function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}

  return (
    <Grid item xs={12} sm={12} md={12} className="cis-tabs">
      <Box sx={{ width: "100%" }}>
        <Box sx={{}}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            className="cis-class-details-tabs"
          >
            <Tab label={translation?.NotesLabel} {...a11yProps(0)} />
            <Tab label={classDetail?.ChatHistory} {...a11yProps(0)} />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0} >
          <NotesTab />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          {/* <div>
            Chat
          </div> */}
          <CustomChat />
        </TabPanel>
      </Box>
    </Grid>
  );
};
export default QuickCallsNotesSection;

const NotesTab = () => {
  const recordedSession = JSON.parse(sessionStorage.getItem('QuickCallRecordedSession'));

  return (
    <div>
      <iframe 
        src={recordedSession?.SessionNotesUrl}
        type="application/pdf"
        style={{ width: '100%', height: '500px' }} // Adjust dimensions as needed
        title="PDF Viewer"
      />
      {/* <a 
        href={recordedSession?.SessionNotesUrl} 
        download="SessionNotes.pdf" 
        target="_blank"
        style={{ display: 'block', marginTop: '10px' }}
      >
        Download PDF
      </a> */}
    </div>
  );
};
