import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { auth } from "./firebase.js";
import { onIdTokenChanged, signOut } from "firebase/auth";
import useSocket from "./custom-hooks/useSocket.js";

import { UserAuthContextProvider } from "./context/UserAuthContext";

import LoginPage from "./components/login/LoginPage";
import SelectStudy from "./components/login/SelectStudy";
import ProtectedRoute from "./components/login/ProtectedRoute";
import PrivateClass from "./components/private-classes/PrivateClass";
import ClassDetailMain from "./components/private-classes/ClassDetailMain.jsx";
import LaunchClassRoom from "./components/agora/LaunchClassRoom";
import InvoicePage from "./components/private-classes/payment/InvoicePage";
import QuickCalls from "./components/quick-calls/QuickCalls";
import LaunchQuickCallRoom from "./components/agora/LaunchQuickCallRoom";
import ViewAllTutors from "./components/view-all-tutors/ViewAllTutors";
import ViewAllSessions from "./components/view-all-sessions/ViewAllSessions";
import StudentRating from "./components/student-ratings/StudentRating";
import Registration from "./components/registration/Registration";
import EditRegistration from "./components/edit-profile/EditProfile";
import QuickCallsSessionDetail from "./components/quick-calls/QuickCallsSessionDetail";
import FindTutor from "./components/find-tutor/FindTutor.jsx";
import Checkout from "./components/checkout/Checkout.jsx";
import LaunchHireClassRoom from "./components/agora/LaunchHireClassRoom.jsx";
import GetPayment from "./getpayment/GetPayment.js";
import SingleSignon from "./components/singlesignon/SingleSignon.js";
import NoPage from "./components/no-page/NoPage.jsx";
import Chat from "./components/private-classes/chat/Chat.jsx";
import { axiosGet } from "./services/api.js";
import { API_BASE_URL } from "./config/apiConfig.js";

const App = () => {
  const navigate = useNavigate();
  const { socket, isConnected } = useSocket();

  // useEffect(() => {
  //   const regenerateAndStoreToken = async () => {
  //     try {
  //       const user = auth.currentUser;

  //       if (user) {
  //         // Force a token refresh
  //         const refreshedToken = await user.getIdToken(true);
  //         console.log('Refreshed Access Token:', refreshedToken);

  //         const userInfo = JSON.parse(sessionStorage.getItem('loginToken'));
  //         const payload = {
  //             "idToken": refreshedToken ,
  //             "expiresIn":userInfo?.expiresIn,
  //             "localId":userInfo?.localId,
  //             "isNewUser":userInfo?.isNewUser,
  //             "phoneNumber": userInfo?.phoneNumber
  //         };
  //         sessionStorage.setItem('loginToken',JSON.stringify(payload))
  //       } else {
  //         console.log('User not signed in');
  //       }
  //     } catch (error) {
  //       console.error('Error regenerating token:', error);
  //     }
  //   };

  //   // Set up a timer to regenerate the token every 20 mins
  //   const tokenRegenerationInterval = setInterval(regenerateAndStoreToken, 20 * 60 * 1000); // 20 mins

  //   // Clean up the interval on component unmount
  //   return () => clearInterval(tokenRegenerationInterval);
  // }, []);

  const refreshToken = async () => {
    const userInfo = JSON.parse(sessionStorage.getItem('loginToken') || '{}');
    if (userInfo && Date.now() >= (userInfo?.expireTime + 25 * 60 * 1000)) {
      try {
        const response = await axiosGet(`${API_BASE_URL}/CustomToken`);
        console.log('refresh token: ', new Date().toLocaleTimeString(), response);
        const payload = {
          idToken: response?.data?.Token,
          expiresIn: userInfo?.expiresIn,
          localId: userInfo?.localId,
          isNewUser: userInfo?.isNewUser,
          phoneNumber: userInfo?.phoneNumber,
          expireTime: Date.now()
        };
        sessionStorage.setItem('loginToken', JSON.stringify(payload));
      } catch (error) {
        console.error('Error refreshing token:', error);
      }
    }
  };

  useEffect(() => {
    const refreshInterval = 25 * 60 * 1000; // Refresh token every 25 minutes
    const refreshTimer = setInterval(refreshToken, refreshInterval);

    return () => {
      clearInterval(refreshTimer);
    };
  }, []);

  useEffect(() => {
    const handleLoginSessionResponse = (data) => {
      const loginSessionResponse = JSON.parse(data);
      const deviceId = localStorage.getItem("deviceId");
      if (loginSessionResponse?.Data?.DeviceID !== deviceId) {
        signOut(auth);
        sessionStorage.clear();
        navigate('/single-signon');
      }
    };

    socket?.on('LoginSessionResponseEvent', handleLoginSessionResponse);
    return () => {
      socket?.off('LoginSessionResponseEvent', handleLoginSessionResponse);
    };
    
  }, [isConnected]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') refreshToken();
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <Routes>
        <Route
            path="/chat"
            element={
                <Chat />
            }
          />
        <Route
            path="/PaymentStatus"
            element={
                <GetPayment />
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <SelectStudy />
              </ProtectedRoute>
            }
          />
          <Route
            path="/login"
            element={
              <UserAuthContextProvider>
                <LoginPage />
              </UserAuthContextProvider>
            }
          />
          <Route
            path="/private-classes"
            element={
              <ProtectedRoute>
                <PrivateClass />
              </ProtectedRoute>
            }
          />
          <Route
            path="/class-details/:encryptClassId"
            element={
              <ProtectedRoute>
                <ClassDetailMain />
              </ProtectedRoute>
            }
          />
          <Route
            path="/quick-calls-details/:encryptQuickCallId"
            element={
              <ProtectedRoute>
                <QuickCallsSessionDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/classroom/:encryptClassId/:encryptSessionId"
            element={
              <ProtectedRoute>
                <LaunchClassRoom />
              </ProtectedRoute>
            }
          />

          <Route
            path="/quick-calls-room/:sessionID"
            element={
              <ProtectedRoute>
                <LaunchQuickCallRoom />
              </ProtectedRoute>
            }
          />

          <Route
            path="/class-details/:encryptClassId/chat"
            element={
              <ProtectedRoute>
                <ClassDetailMain index={4} />
              </ProtectedRoute>
            }
          />

          <Route
            path="/registration"
            element={
              <ProtectedRoute>
                <UserAuthContextProvider>
                  <Registration index={4} />
                </UserAuthContextProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="/quick-calls"
            element={
              <ProtectedRoute>
                <QuickCalls index={5} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/view-all-tutors"
            element={
              <ProtectedRoute>
                <ViewAllTutors index={5} />
              </ProtectedRoute>
            }
          />

          <Route
            path="/view-all-sessions"
            element={
              <ProtectedRoute>
                <ViewAllSessions index={5} />
              </ProtectedRoute>
            }
          />

          <Route
            path="/student-ratings"
            element={
              <ProtectedRoute>
                <StudentRating index={5} />
              </ProtectedRoute>
            }
          />

          <Route
            path="/edit-profile"
            element={
              <ProtectedRoute>
                <EditRegistration index={5} />
              </ProtectedRoute>
            }
          />

          <Route
            path="/courses"
            element={
              <ProtectedRoute>
                <FindTutor index={6} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/checkout"
            element={
              <ProtectedRoute>
                <Checkout index={6} />
              </ProtectedRoute>
            }
          />

          <Route
            path="/hire-class-room/:sessionID"
            element={
              <ProtectedRoute>
                <LaunchHireClassRoom />
              </ProtectedRoute>
            }
          />
          <Route
            path="*"
            element={<NoPage />}
          />
          {/* <Route
            path="/class-details/:encryptClassId/invoice"
            element={
              <ProtectedRoute>
                <InvoicePage/>
              </ProtectedRoute>
            }
          /> */}
          <Route
            path="/single-signon"
            element={<SingleSignon/>}
          />

          <Route
            path="/class-details/:encryptClassId/invoice/:token/:deviceid"
            element={<InvoicePage />}
          />
        </Routes>
  );
}

export default App;
