import React from "react";


const YasaContainer = ({ children }) => {
  return (
    <div className="container">
      <div className="welcome-box-wrap">{children}</div>
    </div>
  );
};

export default YasaContainer;
