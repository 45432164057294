import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAyjsXuQzG2XJsbIuF6uWe9eWKzRfEtHmM",
  authDomain: "getyasa.com",
  projectId: "yasa-f94a6",
  storageBucket: "yasa-f94a6.appspot.com",
  messagingSenderId: "709673327432",
  appId: "1:709673327432:web:60e0343a5f99b449a0b17d",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;
