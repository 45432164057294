import { Button, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import YasaContainer from "../common-components/YasaContainer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import QuickCallVideoContainer from "./QuickCallVideoContainer";
import { useNavigate } from "react-router-dom";
import QuickCallsSessionDetailCard from "./QuickCallsSessionDetailCard";
import YasaHeader from "../common-components/YasaHeader";
import QuickCallsNotesSection from "./QuickCallsNotesSection";
import useTranslations from "../../custom-hooks/useTranslations";


const QuickCallsSessionDetail = () => {
  const translations = useTranslations();
	const translation = translations[26]?.QuickCall;
  const navigate = useNavigate();
  
  const onBackButtonHandler = () => {
		navigate(-1);
	}

 
  return (
    <>
    <YasaHeader/>
    <img src='../../images/cloud.png' alt='' className='cloud-img' />
    <div className="container mb-5">
				<Button
					size="large"
					startIcon={<ArrowBackIcon className="backarrow" />}
					sx={{ px: 2, py: 1.25 }}
					onClick={onBackButtonHandler}
					className="back-btn"
				>
					{translation?.BackBtn}
				</Button>
			</div>
      <YasaContainer>
        <Tabs
          aria-label="basic tabs example"
          className="cis-class-details-tabs"
        >
          <Tab
            label={translation?.OverviewLabel} className="active-border"
          />
           {/* <Tab
            label='Chat'
          />
           <Tab
            label='Schedule'
          /> */}
        </Tabs>
        <div className="whitebox-wrap">
          <div className="cis-schedule-detail">
          <QuickCallVideoContainer  />
          {/* <ButtonSection {...props} /> */}
          <div className="space-20"></div>
          <hr className="cis-devider-2" />
          <QuickCallsSessionDetailCard  />
          <div className="space-20"></div>
          <hr className="cis-devider-2" />
          <div className="space-20"></div>
          <QuickCallsNotesSection />
        </div>
        <div className="space-20"></div>
        <div className="space-20"></div>
      </div>
      
      </YasaContainer>
  

    </>
  );
};

export default QuickCallsSessionDetail;
