import {useState,useEffect} from "react";
import "./invoicePage.css";
import imgLogo from '../../../assets/images/logo.png'
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../../config/apiConfig";
import { axiosPost } from "../../../services/api";


import jsPDF from "jspdf";
import html2canvas from 'html2canvas'

const InvoicePage = () => {

const { encryptClassId, token, deviceid } = useParams();

const [invoiceData, setInvoiceData] =  useState([])
const [tokenExp, setTokenExp] = useState(false)

const printInvoice = () => {
    window.print();
  };

  
  useEffect(()=>{

  })
  const headers = {
    'Content-Type': 'application/json',
    "authorization": token,
    "langid": 1,
    "deviceid": deviceid
  };



  async function getInvoiceData(){

    const data = { "ClassID" : encryptClassId };
    axiosPost(`${API_BASE_URL}/GetClassPayments`, data)
      .then(response => {
        if(response.data.IsSuccess === false){
          setTokenExp(true)
          return
        }
        setInvoiceData([response.data.Data])
      })
      .catch(error => console.error(error))

   

}


const downloadDocument = () => {
    html2canvas(document.getElementById('row-demo'), { scale: 3 })
        .then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg', 1.0);
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'pt',
                format: [1224, 1584]
            });

            pdf.internal.scaleFactor = 1;
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
            pdf.setPage(1);
            const pdfBlob = pdf.output('blob');
            const pdfUrl = URL.createObjectURL(pdfBlob);
            const downloadLink = document.createElement('a');
            downloadLink.href = pdfUrl;
            downloadLink.download = 'invoice-001.pdf';
            downloadLink.click();
        });
};


useEffect(()=>{
    getInvoiceData()
},[])

  return (
    <>
     <div className="body" id='row-demo' dir="rtl" style={{margin: "0", padding: "0 !important"}} >
        <div className="pdfMain" style={{padding: '15px'}}>
          <div className="container">
            <div className="pdfInner">
              <table style={{border: 'none'}} width="100%" cellSpacing={0} cellPadding={0}>
                <tbody><tr>
                    <td>
                      <table width="100%" cellSpacing={0} cellPadding={0}>
                        <tbody><tr>
                            <td style={{width: '50%'}}>
                              <table width="100%" cellSpacing={0} cellPadding={0} align="center">
                                <tbody><tr>
                                    <td>
                                      <a href="#" className="rightlogo"><img src={imgLogo} alt="Logo" /> </a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <table width="100%" cellSpacing={0} cellPadding={0}>
                                        <tbody><tr>
                                            <td style={{height: '30px'}} height={30} />
                                          </tr>
                                          <tr>
                                            <td>
                                              <p>عنوان </p>
                                              <p>اد أل عبدال, جهرا </p>
                                              <p>3201 : الكويت</p>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                </tbody></table>
                            </td>
                            <td style={{width: '50%'}}>
                              <table width="100%" cellSpacing={0} cellPadding={0}>
                                <tbody><tr>
                                    <td>
                                      <p>التسجيل القانوني: xxx</p>
                                      <p>بريد إلكتروني: accounts@getyasa.com</p>
                                      <p>موقع إلكتروني: https://www.getyasa.com</p>
                                    </td>
                                  </tr>
                                </tbody></table>
                            </td>
                          </tr>
                        </tbody></table>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table width="100%" cellSpacing={0} cellPadding={0}>
                        <tbody><tr>
                            <td style={{height: '30px'}} height="30px" />
                          </tr>
                          <tr>
                            <td style={{background: '#fff', border: 'none', borderBottom: '1px dashed #ccc'}} width="100%" height={1}>
                            </td>
                          </tr>
                          <tr>
                            <td style={{height: '30px'}} height="30px" />
                          </tr>
                        </tbody></table>
                    </td>
                  </tr>
                  {
                    tokenExp === true ? (
                      <h1> YOUR LOGIN TOKEN IS EXPIRED ..!</h1>
                    ) : (
                      <>
                      <tr>
                    <td width="100%">
                      <table width="100%" cellSpacing={0} cellPadding={0}>
                        <tbody><tr>
                            <td>
                              <table width="100%" cellSpacing={0} cellPadding={0}>
                                <tbody><tr>
                                    <td width="50%">قم الفاتورة </td>
                                    <td width="50%">تاريخ </td>
                                  </tr>
                                  <tr>
                                    <td width="50%">{ invoiceData[0]?.PCUid } </td>
                                    <td width="50%"> {invoiceData[0]?.PaymentDate} </td>
                                  </tr>
                                  <tr>
                                    <td width="50%"> الة السداد </td>
                                    <td width="50%"> المبلغ الإجمالي </td>
                                  </tr>
                                  <tr>
                                    <td style={{color: '#00ed00'}} width="50%"> مدفوع </td>
                                    <td width="50%"> TotalPayments </td>
                                  </tr>
                                </tbody></table>
                            </td>
                          </tr>
                          <tr>
                            <td style={{height: '50px'}} height={50} />
                          </tr>
                        </tbody></table>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table className="tableHead cis-right-shift" width="100%" cellSpacing={0} cellPadding={0}>
                        <thead>
                          <tr>
                            <th width="20%">#</th>
                            <th width="20%">فاصيل المنتج </th>
                            <th width="20%"> معدل </th>
                            <th width="20%"> كمية </th>
                            <th width="20%"> كمية </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{borderBottom: '1px dashed #ccc', paddingRight:0}} width="20%">01</td>
                            <td style={{borderBottom: '1px dashed #ccc', paddingRight:0}} width="20%">{invoiceData[0]?.PrivateClassName} </td>
                            <td style={{borderBottom: '1px dashed #ccc', paddingRight:0}} width="20%"> KD {invoiceData[0]?.PricePerHour}</td>
                            <td style={{borderBottom: '1px dashed #ccc', paddingRight:0}} width="20%"> {invoiceData[0]?.TotalHours}</td>
                            <td style={{borderBottom: '1px dashed #ccc', paddingRight:0}} width="20%"> KD {invoiceData[0]?.TotalPayments} </td>
                          </tr>
                          <tr>
                            <td style={{borderBottom: '1px dashed #ccc'}} width="20%">المجموع الفرعي </td>
                            <td style={{borderBottom: '1px dashed #ccc'}} width="20%">KD {invoiceData[0]?.PricePerHour}</td>
                            <td width="20%" />
                            <td width="20%" />
                            <td width="20%" />
                          </tr>
                          <tr>
                            <td width="20%">المبلغ الإجمالي </td>
                            <td width="20%"> <strong>KD {invoiceData[0]?.TotalPayments} </strong> </td>
                            <td width="20%" />
                            <td width="20%" />
                            <td width="20%" />
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table className="TableSummry" width="100%" cellSpacing={0} cellPadding={0}>
                        <tbody><tr>
                            <td style={{padding: '15px 5px'}}>: بيانات الدفع </td>
                          </tr>
                          <tr>
                            <td> طريقة الدفع او السداد : متصل  </td>
                          </tr>
                          <tr>
                            <td>رقم المعاملة  : {invoiceData[0]?.PaymentRefID} </td>
                          </tr>
                          <tr>
                            <td> المبلغ الإجمالي  : KD {invoiceData[0]?.TotalPayments}</td>
                          </tr>
                        </tbody></table>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table width="100%" cellSpacing={0} cellPadding={0}>
                        <tbody><tr>
                            <td style={{height: '30px'}} height={30} />
                          </tr>
                        </tbody></table>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table className="totalAmt" width="100%" cellSpacing={0} cellPadding={0}>
                        <tbody><tr>
                            <td>لحوظات </td>
                          </tr>
                        </tbody></table>
                    </td>
                  </tr>
                      </>
                    )
                  }
                 
                </tbody></table>
            </div>
          </div>
        </div>
        <div style={{padding: '15px'}}>
          <div className="container">
            <button onClick={printInvoice} style={{display: 'inline-flex', alignItems: 'center', justifyContent: 'center', padding: '6px 15px', cursor: 'pointer', background: '#2daf2d', border: '1px solid #2daf2d', color: '#ffffff', borderRadius: '5px', marginLeft: '10px'}}>
              <svg style={{width: 'auto', height: '20px', margin: '0 5px'}} viewBox="0 0 24 24" fill="none">
                <path d="M18 13.5H18.5C19.4428 13.5 19.9142 13.5 20.2071 13.2071C20.5 12.9142 20.5 12.4428 20.5 11.5V10.5C20.5 8.61438 20.5 7.67157 19.9142 7.08579C19.3284 6.5 18.3856 6.5 16.5 6.5H7.5C5.61438 6.5 4.67157 6.5 4.08579 7.08579C3.5 7.67157 3.5 8.61438 3.5 10.5V12.5C3.5 12.9714 3.5 13.2071 3.64645 13.3536C3.79289 13.5 4.0286 13.5 4.5 13.5H6" stroke="#ffffff" />
                <path d="M6.5 19.8063L6.5 11.5C6.5 10.5572 6.5 10.0858 6.79289 9.79289C7.08579 9.5 7.55719 9.5 8.5 9.5L15.5 9.5C16.4428 9.5 16.9142 9.5 17.2071 9.79289C17.5 10.0858 17.5 10.5572 17.5 11.5L17.5 19.8063C17.5 20.1228 17.5 20.2811 17.3962 20.356C17.2924 20.4308 17.1422 20.3807 16.8419 20.2806L14.6738 19.5579C14.5878 19.5293 14.5448 19.5149 14.5005 19.5162C14.4561 19.5175 14.4141 19.5344 14.3299 19.568L12.1857 20.4257C12.094 20.4624 12.0481 20.4807 12 20.4807C11.9519 20.4807 11.906 20.4624 11.8143 20.4257L9.67005 19.568C9.58592 19.5344 9.54385 19.5175 9.49952 19.5162C9.45519 19.5149 9.41221 19.5293 9.32625 19.5579L7.15811 20.2806C6.8578 20.3807 6.70764 20.4308 6.60382 20.356C6.5 20.2811 6.5 20.1228 6.5 19.8063Z" stroke="#ffffff" />
                <path d="M9.5 13.5L13.5 13.5" stroke="#ffffff" strokeLinecap="round" />
                <path d="M9.5 16.5L14.5 16.5" stroke="#ffffff" strokeLinecap="round" />
                <path d="M17.5 6.5V6.1C17.5 4.40294 17.5 3.55442 16.9728 3.02721C16.4456 2.5 15.5971 2.5 13.9 2.5H10.1C8.40294 2.5 7.55442 2.5 7.02721 3.02721C6.5 3.55442 6.5 4.40294 6.5 6.1V6.5" stroke="#ffffff" />
              </svg>
              مطبعة</button>
            <button onClick={downloadDocument} style={{display: 'inline-flex', alignItems: 'center', justifyContent: 'center', padding: '6px 15px', cursor: 'pointer', background: '#4d7cdf', border: '1px solid #4d7cdf', color: '#ffffff', borderRadius: '5px', marginLeft: '10px'}}>
              <svg style={{width: 'auto', height: '20px', margin: '0 5px'}} viewBox="0 0 1024 1024">
                <path fill="#ffffff" d="M160 832h704a32 32 0 1 1 0 64H160a32 32 0 1 1 0-64zm384-253.696 236.288-236.352 45.248 45.248L508.8 704 192 387.2l45.248-45.248L480 584.704V128h64v450.304z" />
              </svg>
              تحميل </button>
           
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoicePage;
