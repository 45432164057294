import React from "react";
import { Button } from "@mui/material";
import './NoPage.css';
import { useNavigate  } from "react-router-dom";
import useTranslations from "../../custom-hooks/useTranslations";

function NoPage() {
	const navigate = useNavigate();
	const translations = useTranslations();
	const message = translations[2]?.EditProfile
	const navigateToDashboard = ()=>{
		navigate('/')
	}

  return (
   <>
   <div className="mul-dev">
        <div className="mul-dev-col">
          <img src="../images/no-page.png" alt="devices"/>
          <Button type="button" className="mul-btn" onClick={()=>navigateToDashboard()}>{message?.BackToHome}</Button>
        </div>
      </div>
   </>
  )
}

export default NoPage