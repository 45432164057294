import React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import SendIcon from "@mui/icons-material/Send";
import Fab from "@mui/material/Fab";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import ChatRoom from "./ChatRoom";

const Chat = () => {
  return (
    <div className="cis-chat-page">
      <ChatRoom />
    </div>
  );
};
export default Chat;
