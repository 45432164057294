import { createContext, useContext, useEffect, useState } from "react";
import {
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	onAuthStateChanged,
	signOut,
	GoogleAuthProvider,
	signInWithPopup,
	RecaptchaVerifier,
	signInWithPhoneNumber,
	OAuthProvider 
} from "firebase/auth";
import { auth } from "../firebase";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
	const [user, setUser] = useState({});
	const [recaptcha, setRecaptcha] = useState();
	const [reCaptchaToken, setReCaptchaToken] = useState("");
	const [oneopt, setOneopt] = useState(true);
	const [secopt, setSecopt] = useState(true);
	const [thirdopt, setThirdopt] = useState(true);


	function logIn(email, password) {
		return signInWithEmailAndPassword(auth, email, password);
	}

	function signUp(email, password) {
		return createUserWithEmailAndPassword(auth, email, password);
	}

	function signUpPhoneNumber(phoneNumber) {
		return signInWithPhoneNumber(auth, phoneNumber, recaptcha);
	}

	function logOut() {
		return signOut(auth);
	}
	function googleSignIn() {
		const googleAuthProvider = new GoogleAuthProvider();
		return signInWithPopup(auth, googleAuthProvider);
	}

    function appleSignIn() {
		const provider = new OAuthProvider('apple.com');
		return signInWithPopup(auth, provider);

	}

	function setUpRecaptcha() {
		const recaptchaVerifier = new RecaptchaVerifier(
			"recaptcha-container",
			{
				callback: (response) => {
					setReCaptchaToken(Boolean(response));
				},
				"expired-callback": () => {
					console.log("recaptcha expired");
					setReCaptchaToken("");
				},
			},
			auth
		);
		setRecaptcha(recaptchaVerifier);
		recaptchaVerifier.render();
	}

	function signInUsingPhoneNumber(number) {
		return signInWithPhoneNumber(auth, number, recaptcha);
	}

	// function resendOTP(number) {
	// 	console.log("opt number ",number)
	// 	signInUsingPhoneNumber(number);
	// }
	function resendOTP(number) {
		// document.getElementById("recaptcha-container1").innerHTML = "";
		// var value = document.getElementById("recaptcha-container1").value;
		// console.log("dom",value);
		if(oneopt){
		setRecaptcha("")
		// signInUsingPhoneNumber(auth, number, recaptcha);
	    setOneopt(false);

		const recaptchaVerifier = new RecaptchaVerifier(
			"recaptcha-container1",
			{
				size: "invisible",
				callback: (response) => {
					console.log("recaptchaVerifier: ", recaptchaVerifier);
					return signInWithPhoneNumber(auth, number, recaptchaVerifier);
					// signInWithPhoneNumber(auth, number, recaptchaVerifier).then(() => {
					// 	recaptchaVerifier.recaptcha.clear()
					// });
				},
				"expired-callback": () => {
					console.log("recaptcha expired");
					setReCaptchaToken("");
				},
			},
			auth
		);

		// console.log("recaptchaVerifier: ", recaptchaVerifier);
		setRecaptcha(recaptchaVerifier);
		// recaptchaVerifier.render()
		recaptchaVerifier.render().then((widgetId) => {
			console.log('widgetId: ', widgetId);
			recaptchaVerifier.recaptcha.execute(widgetId)
			setReCaptchaToken("");
		});
	   } else if (secopt){
		setSecopt(false)
		resendOTP2(number)
	   }
	   else if (thirdopt){
		setThirdopt(false)
		resendOTP3(number)
	   }
	   
	   else{
		resendOTP4(number)
	   }
		
	}
	function resendOTP2(number) {
		// document.getElementById("recaptcha-container1").innerHTML = "";
		// var value = document.getElementById("recaptcha-container1").value;
		// console.log("dom",value);
		setRecaptcha("")
		// signInUsingPhoneNumber(auth, number, recaptcha);
		const recaptchaVerifier = new RecaptchaVerifier(
			"recaptcha-container2",
			{
				size: "invisible",
				callback: (response) => {
					console.log("recaptchaVerifier: ", recaptchaVerifier);
					return signInWithPhoneNumber(auth, number, recaptchaVerifier);
					// signInWithPhoneNumber(auth, number, recaptchaVerifier).then(() => {
					// 	recaptchaVerifier.recaptcha.clear()
					// });
				},
				"expired-callback": () => {
					console.log("recaptcha expired");
					setReCaptchaToken("");
				},
			},
			auth
		);

		// console.log("recaptchaVerifier: ", recaptchaVerifier);
		setRecaptcha(recaptchaVerifier);
		// recaptchaVerifier.render()
		recaptchaVerifier.render().then((widgetId) => {
			console.log('widgetId: ', widgetId);
			recaptchaVerifier.recaptcha.execute(widgetId)
			setReCaptchaToken("");
		});
		
	}
	function resendOTP3(number) {
		// document.getElementById("recaptcha-container1").innerHTML = "";
		// var value = document.getElementById("recaptcha-container1").value;
		// console.log("dom",value);
		setRecaptcha("")
		// signInUsingPhoneNumber(auth, number, recaptcha);
		const recaptchaVerifier = new RecaptchaVerifier(
			"recaptcha-container3",
			{
				size: "invisible",
				callback: (response) => {
					console.log("recaptchaVerifier: ", recaptchaVerifier);
					return signInWithPhoneNumber(auth, number, recaptchaVerifier);
					// signInWithPhoneNumber(auth, number, recaptchaVerifier).then(() => {
					// 	recaptchaVerifier.recaptcha.clear()
					// });
				},
				"expired-callback": () => {
					console.log("recaptcha expired");
					setReCaptchaToken("");
				},
			},
			auth
		);

		// console.log("recaptchaVerifier: ", recaptchaVerifier);
		setRecaptcha(recaptchaVerifier);
		// recaptchaVerifier.render()
		recaptchaVerifier.render().then((widgetId) => {
			console.log('widgetId: ', widgetId);
			recaptchaVerifier.recaptcha.execute(widgetId)
			setReCaptchaToken("");
		});
		
	}
	function resendOTP4(){
		alert("Too many request")
		setTimeout(() => {
			window.location.reload();
			
		  }, 2000);
	}
 

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
			setUser(currentuser);
		});

		return () => {
			unsubscribe();
		};
	}, []);

	return (
		<userAuthContext.Provider
			value={{
				user,
				reCaptchaToken,
				logIn,
				signUp,
				logOut,
				googleSignIn,
				setUpRecaptcha,
				setReCaptchaToken,
				signInUsingPhoneNumber,
				resendOTP,
				signUpPhoneNumber,
                appleSignIn,
				
			}}
		>
			{children}
		</userAuthContext.Provider>
	);
}

export function useUserAuth() {
	return useContext(userAuthContext);
}
