import React from "react";
import { Button, Card, CardContent, Chip, Grid } from "@mui/material";
import { Box } from "@mui/system";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import useTranslations from "../../custom-hooks/useTranslations";
import timeAgoDate from "../../services/timeAgoDate";
import momentDate from "../../services/momentDate";

const QuickCallsSessionDetailCard = () => {
  const translations = useTranslations();
	const translation = translations[26]?.QuickCall;
  const recorderdSession = JSON.parse(sessionStorage.getItem('QuickCallRecordedSession'));
  return (
    <div className="cis-schedule-card">
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Card sx={{ display: "flex" }} className="cis-card">
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CardContent sx={{ flex: "1 1 auto" }}>
                <h2 className="cis-title-1">{recorderdSession?.TopicName}</h2>

                <div className="cis-Chips-2">
                  <Chip
                    icon={<CalendarTodayOutlinedIcon />}
                    variant="outlined"
                    // label={`${translation?.StartsOnLabel} ${new Date(recorderdSession?.StartDateTime).toDateString()}`}
                    label={momentDate(recorderdSession?.StartDateTime || recorderdSession?.CreatedDate)}
                  />
                  <Chip
                    icon={<AccessTimeOutlinedIcon />}
                    variant="outlined"
                    label={timeAgoDate(recorderdSession?.StartDateTime || recorderdSession?.CreatedDate)}
                  />
                </div>
              </CardContent>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box className="cis-teacher-card">
            <div className="cis-teacher-info">
              <div className="cis-content">
                <Button
                  variant="contained"
                  sx={{ px: 2 }}
                  size="small"
                  className="cis-complete-btn"
                >
                  {translation?.CompletedButton}
                </Button>
              </div>
            </div>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default QuickCallsSessionDetailCard;
