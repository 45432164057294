import * as actionTypes from "./actionTypes";
import * as apiCall from "../services/api";
import { API_BASE_URL } from "../config/apiConfig";

const getFavtTutorStatusSuccess = (data) => {
  return {
    type: actionTypes.GET_FAVT_TUTOR_STATUS,
    payload: {
      tutor: data.Data,
    },
  };
};

export const getFavtTutorStatus = (data) => async (dispatch) => {
  return await apiCall
    .axiosPost(`${API_BASE_URL}/IsFavourite`, data)
    .then((response) => {
      if (response) {
        dispatch(getFavtTutorStatusSuccess(response.data));  
      }
    })
    .catch((error) => {
      console.log('Error: ', error);
    });
};