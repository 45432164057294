import React, { useState } from "react";

import { Button, Chip, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import useTranslations from "../../../custom-hooks/useTranslations";

const PackageDetail = (props) => {
  const {packageDetails, studentPackageDetail} = props;
  console.log('packageDetails: ', packageDetails);
  const translations = useTranslations();
	const translation = translations[19]?.PaymentDetails;
  
//  O const [numberOfSessions, setNumberfSessions] = useState(4);
  return (
    <div className="cis-payment-detail-page">
      <div className="cis-payment-info">
        <Stack direction="row">
          {/* <Button
            size="large"
            endIcon={<ArrowBackIcon />}
            sx={{ px: 2, py: 1.25 }}
            onClick={props.handleBack}
          >
            Back
          </Button> */}
          <h2 className="cis-title-5">{packageDetails?.TotalPayments}KD</h2>
        </Stack>
        <div className="cis-Chips-2">
          {/* <Chip
            icon={<WatchLaterIcon />}
            variant="outlined"
            label="10 mins ago"
          /> */}
          <Chip
            icon={<Grid3x3Icon />}
            variant="outlined"
            label={`${translation?.PaymentRefID}: ${!packageDetails?.PaymentRefID ? '--': packageDetails?.PaymentRefID}`}
          />
          <Chip
            icon={<CalendarTodayIcon />}
            variant="outlined"
            label={`${translation?.PaymentDate}: ${!packageDetails?.PaymentDate ?  '--' : new Date( packageDetails?.PaymentDate).toDateString()}`}
          />
           <Chip
            icon={<Grid3x3Icon />}
            variant="outlined"
            label={`${translation?.PaymentStatus}: ${packageDetails?.PaymentStatus} `}
          />
        </div>
        <p>{translation?.TheAboveSum}</p>
      </div>

      <div className="space-20"></div>
      <hr className="cis-devider-2" />
      <div className="space-20"></div>

      <h3 className="cis-title-1">{translation?.CoursesLabel}</h3>
      {studentPackageDetail.map((PackageDetail,index) => (
        <PaymentPerSessionCard key={index} PackageDetail={PackageDetail} />
      ))}
    </div>
  );
};

export default PackageDetail;

const PaymentPerSessionCard = ({PackageDetail}) => {
  const translations = useTranslations();
	const translation = translations[19]?.PaymentDetails;
  return (
    <div className="cis-private-class">
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8} md={7}>
          <Card sx={{ display: "flex" }} className="cis-card">
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CardContent sx={{ flex: "1 1 auto" }}>
                <div className="cis-Chips-2">
                  {/* <Chip
                    icon={<WatchLaterIcon />}
                    variant="outlined"
                    label="10 mins ago"
                  /> */}
                  <Chip
                    icon={<CalendarTodayIcon />}
                    variant="outlined"
                    label={`Starts on ${PackageDetail?.ClassesDate}`}
                    // label="Starts on 23rd, Jan, 2023"
                  />
                </div>

                <Typography component="div" variant="h5">
                  {PackageDetail.Value}
                </Typography>

                <p>1 {translation?.HourLabel}</p>
              </CardContent>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4} md={5}>
          <Box className="cis-teacher-card">
            <div className="cis-teacher-info">
              <div className="cis-content">
                <h2 className="cis-title-5">{PackageDetail.AmountDeducted}KD</h2>
                <div className="cis-link-wrap">
                  { PackageDetail.SessionStatus === "Complete" ? ( <Button
                    variant="outlined"
                    sx={{ px: 2, minWidth: "120px" }}
                    size="small"
                    className="btn btn-success"
                  >
                    {translation?.PaymentCompleted}
                  </Button>) : ( <Button
                    variant="outlined"
                    sx={{ px: 2, minWidth: "120px" }}
                    size="small"
                    className="cis-invoice-btn"
                  >
                    {translation?.TakeMeToCourse}
                  </Button>) }
                </div>
              </div>
            </div>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
