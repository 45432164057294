import React, { useRef, useState } from "react";

import SendIcon from "@mui/icons-material/Send";
import Fab from "@mui/material/Fab";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";

import { socket } from "../../../socket";
import { API_BASE_URL } from "../../../config/apiConfig";
import { axiosPost } from "../../../services/api";

const MyForm = ({ classId }) => {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const userName = userInfo?.displayName;
  const userUID = userInfo?.uid;
  const userPic = userInfo?.photoURL;
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [valueDataType, setValueDataType] = useState(1);

  function onSubmit(event) {
    event.preventDefault();
    if (value.trim() === "") return;
    setIsLoading(true);

    socket
      .timeout(500)
      .emit(
        "chat1",
        value,
        userName,
        `C${classId}`,
        userUID,
        userPic,
        valueDataType,
        () => {
          setIsLoading(false);
        }
      );
    setValue("");
  }

  // custom file attachment
  const inputRef = useRef(null);
  const attachBtnClick = () => {
    // open file input box on click of another element
    inputRef.current.click();
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileInput = async (e) => {
    let base64images = [];
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      if (file?.name.includes(".png") || file?.name.includes(".jpg")) {
        let base64 = await convertToBase64(file);
        base64 = base64.split(",")[1];
        base64images.push(base64);
      } else {
        alert("Only images can be selecetd");
        break;
      }
    }
    if (base64images.length > 0) {
      setValueDataType(2);
      axiosPost(`${API_BASE_URL}/UploadImages`, { img_data: base64images[0] }).then((response) => {
        setValue(response.data.Data);
      });
    }
  };

  const handleOnChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <FormControl sx={{ m: 0 }} fullWidth variant="outlined">
      <form className="cis-chat-input" onSubmit={onSubmit}>
        <input
          style={{ display: "none" }}
          multiple
          ref={inputRef}
          type="file"
          onChange={handleFileInput}
        />
        <OutlinedInput
          fullWidth
          id="outlined-adornment-weight"
          value={value}
          onChange={handleOnChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="Example"
                sx={{ mx: 1 }}
                onClick={attachBtnClick}
              >
                <AttachFileIcon />
              </IconButton>

              <Fab
                size="small"
                color="primary"
                aria-label="Send"
                sx={{ backgroundColor: "#005DB8" }}
                onClick={onSubmit}
                disabled={isLoading}
              >
                <SendIcon />
              </Fab>
            </InputAdornment>
          }
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            "aria-label": "weight",
          }}
        />
      </form>
    </FormControl>
  );
};

export default MyForm;
