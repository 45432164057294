import { Button } from "@mui/material";
import React from "react";
import './SingleSignon.css';
import { useNavigate } from "react-router-dom";
import useTranslations from "../../custom-hooks/useTranslations";

function SingleSignon() {
	const navigate = useNavigate();
	const translations = useTranslations();
	const message = translations[0]?.Login;

	const loginUser = ()=>{
		navigate('/login')
	}

  return (
   <div className="mul-dev">
        <div className="mul-dev-col">
          <img src="../images/mul-dev.png" alt="devices"/>
          <h1>{message?.MultipleDevicesInform}</h1>
		      <p>{message?.MultipleDevicesInformation}</p>
          {/* <Button type="button" className="mul-btn" onClick={()=>loginUser()}>{message?.MultipleDeviceActiveBtn}</Button> */}
          <Button type="button" className="mul-btn" onClick={()=>loginUser()}>{message?.Login}</Button>
       
	    </div>
    </div>
  )
}

export default SingleSignon