import * as actionTypes from "../actions/actionTypes";

const initialState = { isOpenModal: false, errorMessage: "" };

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_MODAL:
      return {
        openModal: action.payload.openModal,
        errorMessage: action.payload.errorMessage,
      };
    case actionTypes.CLOSE_MODAL:
      return {
        openModal: action.payload.openModal,
      };

    default:
      return state;
  }
};
export default modalReducer;
