import React, { useState, useEffect } from "react";
import VideoPlayer from 'simple-react-video-thumbnail'

const QuickCallVideoContainer = () => {
  const recordedVideo = JSON.parse(sessionStorage.getItem('QuickCallRecordedSession'));
  return (
    <div className="cis-video-card">
      {/* {isLoading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </div>
      ) : ( */}
      <div className="cis-video-box">
        {/* {recordedVideos?.length > 0 ? ( */}
        {
          recordedVideo ? (<div style={{ position: "relative" }}>
            <div className="cis-watermark-video-logo">
              <img src="/favicon.ico" alt="yasa-logo" />
            </div>
            <VideoContainer url={recordedVideo?.RecordedSessionURL} />
          </div>) : (
            <div className="cis-no-recording">
              No Recording Available
            </div>
          )

        }

        {/* ) : ( */}
        {/* <div className="cis-no-recording">
              {translations?.NoRecordingAvailable}
            </div> */}
        {/* )} */}
      </div>
      {/* )} */}
    </div>
  );
};

export default QuickCallVideoContainer;

const VideoContainer = ({ url }) => {
  return (
    <>
      <video width="320" height="240" controls style={{ width: "99%" }}>
        <source src={url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </>
  );
};
