import React, { useState, useEffect } from "react";
import PackageDetail from "./PackageDetail";
import PackageDetailCard from "./PackageDetailCard";
import { API_BASE_URL } from "../../../config/apiConfig";
import { axiosPost } from "../../../services/api";
import { useParams } from "react-router-dom";
import useTranslations from "../../../custom-hooks/useTranslations";
import { decryptData } from "../../../services/encryptDecrypt";
import { useDispatch, useSelector } from "react-redux";
import { getPayments } from "../../../actions/paymentAction";

const PackageList = () => {
  const { encryptClassId } = useParams();
  localStorage.setItem("encryptClassId", encryptClassId)
  const classId = decryptData(encryptClassId);
  const dispatch = useDispatch();

  const [showPackageDetail, setShowPackageDetail] = useState(false);
  const [studentPackageDetail, setStudentPackageDetail] = useState([]);
  const translations = useTranslations();
  const paymentTranslations = translations[13]?.Payment;

  const handlePackageDetail = () => {
    setShowPackageDetail(true);
  };
  const handleBack = () => {
    setShowPackageDetail(false);
  };

  const numberOfPackages = useSelector(
    ({ paymentReducer }) => paymentReducer?.payments
  );

  const getClassPaymentsDetails = () => {
    const data = {
      ClassID: Number(classId),
    };
    axiosPost(`${API_BASE_URL}/GetClassPaymentsDetails`, data)
    .then((res) => {
      setStudentPackageDetail(res.data.Data.SessionData);
    })
    .catch((error) => {
      console.log(error);
    });;
  };

  useEffect(() => {
    getClassPaymentsDetails();
  }, []);

  useEffect(() => {
    dispatch(getPayments(classId));
  }, []);

  return (
    <>
      {showPackageDetail ? (
        <PackageDetail
          classId={classId}
          packageDetails={numberOfPackages[0]}
          studentPackageDetail={studentPackageDetail}
          translations={paymentTranslations}
          handleBack={handleBack}
        />
      ) : (
        <div className="cis-payment-page">
          {numberOfPackages?.map((coursePackage, index) => (
            <PackageDetailCard
              key={index}
              classId={classId}
              coursePackage={coursePackage}
              translations={paymentTranslations}
              handlePackageDetail={handlePackageDetail}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default PackageList;
