import React from "react";
import { CssBaseline, Grid, Paper } from "@mui/material";
import FlagDropDown from "./FlagDropDown";

const HomePageContainer = ({ children }) => {
	return (
		<div className="container">
			<Grid container className="formblock" component={Paper} elevation={6}>
				<CssBaseline />
				<Grid
					item
					xs={false}
					sm={12}
					md={6}
					sx={{
						backgroundImage: "url(../../images/loginBg.png)",
						backgroundRepeat: "no-repeat",
						backgroundColor: (t) =>
							t.palette.mode === "light"
								? t.palette.grey[50]
								: t.palette.grey[900],
						backgroundSize: "cover",
						backgroundPosition: "top center",
					}}
				/>
				<Grid item xs={12} sm={12} md={6}  className="form-wrap">
					<FlagDropDown />
					{children}
				</Grid>
			</Grid>
		</div>
	);
};

export default HomePageContainer;
