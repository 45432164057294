import React, { useEffect, useState } from "react";
import "./Login.css";
import { Alert, Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import HomePageContainer from "./HomePageContainer";
import LoginContent from "./LoginContent";
import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/UserAuthContext";
import { useDispatch, useSelector } from "react-redux";
import { API_BASE_URL } from "../../config/apiConfig";
import { axiosGet, axiosPost } from "../../services/api";
import { openModal } from "../../actions/modalAction";
import { ErrorModal } from "../private-classes/PrivateClass";
import useTranslations from "../../custom-hooks/useTranslations";
import QRCodeComponent from "./QRCodeComponent";
import Timers from "../timer/Timers";
import useSocket from '../../custom-hooks/useSocket';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Loading from "../loader/Loading";
import { browserName, isBrowser, isTablet, isAndroid } from 'react-device-detect';


const LoginPage = () => {
	const { socket, isConnected } = useSocket();
	const [isVerifyingOTP, setIsVerifyingOTP] = useState(false);
	const [error, setError] = useState("");
	const [otp, setOtp] = useState("");
	const [phoneNumber, setPhoneNumber] = useState();
	const [seconds, setSeconds] = useState(30);
	const [QRvalue, setQRvalue] = useState("");
	const [connectId, setConnectId] = useState();
	const [resendOTP , setResendOTP] = useState(false)
	const [isResendOTPAlert , setIsResendOTPAlert] = useState(false)
	const [isWhatsApp, setIsWhatsApp] = useState("");
	const [isDisabled, setIsDisabled] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [showQR, setShowQR] = useState(false);
	const [existedUser, setExistedUser] = useState(false);
	const [isApiCalling, setIsApiCalling] = useState(false);
	const [isNotChrome , setIsNotChrome] = useState(false);
	const [countryEvent , setCountryEvent] = useState({}); 
	const { logIn } = useUserAuth();

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const translations = useTranslations();
	const translation = translations[0]?.Login;

	const handleChange = (newValue) => {
		setOtp(newValue);
		if (newValue.length === 6) {
			setIsDisabled(false);
		} else {
			setIsDisabled(true);
		}
	};

	const inputStyle = {
		width: "40px",
		height: "40px",
		margin: "0px 5px",
	};

	
	// userRole will be 1 for Admin
	// userRole will be 2 for Tutor
	// userRole will be 3 for Student
	
	const isOpenModal = useSelector(({ modalReducer }) => modalReducer.openModal);


	const getOtp = async (number) => {
		setIsApiCalling(true);

		localStorage.removeItem('PhoneNumber')
		if (number === "" || number === undefined) {
			return setError("Please enter a valid phone number!");
		}

		const data = {
			userNumber: number,
		};

		axiosPost(`${API_BASE_URL}/IsRegistered`, data).then((res) => {
			const registeredResponse = res?.data?.Data;
			setIsApiCalling(false);
			setPhoneNumber(number);
			setExistedUser(registeredResponse?.IsRegistered);
			localStorage.setItem("PhoneNumber", number)

			if (registeredResponse?.IsRegistered && registeredResponse?.RoleID !== 3) {
				dispatch(openModal(translation?.PleaseLoginTutorAC));
			} else {
				sentOtpUsingApi(number)
				setIsVerifyingOTP(true);
				setIsDisabled(true);
			}
		})
	};

	function generateUniqueString() {
		let result = "";
		const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		for (let i = 0; i < 16; i++) {
			result += characters.charAt(
				Math.floor(Math.random() * characters.length)
			);
		}
		return result;
	}

	const sessionSave = async (uid) => {
		// setIsDisabled(false);
		setIsApiCalling(true);
		const deviceId = generateUniqueString();
		localStorage.setItem("deviceId", deviceId)
		
		const data = {
			UserUID: uid,
			Maker: navigator.platform,
			Model: navigator.userAgent.split(" ")[0],
			DeviceID: deviceId,
			Platform: "Web",
			AppVersion: "1.0",
		};
		axiosPost(`${API_BASE_URL}/SaveLoginSession`, data)
			.then((res) => {
				setIsApiCalling(false);
				const customToken  = res.data.Token;
				localStorage.setItem('customToken', customToken);

                const mySocketData = {
					authorization: JSON.parse(sessionStorage.getItem('loginToken')).idToken,
					DeviceID: deviceId,
					IsActive: "true"
				}
				socket.emit("LoginSession", JSON.stringify(mySocketData))
				navigate("/");
			})
			.catch((err) => {
				setError(err.message);
			});
	};
	
	const verifyOtp= async (e) => {
		e.preventDefault();
		setError("");
		if (otp === "" || otp === null) return;
		try {
			setIsDisabled(true)
			const myid = `${phoneNumber.substr(1)}@getyasa.com`
			const res = await logIn (myid, otp)
			res._tokenResponse.expireTime = Date.now();

			sessionStorage.setItem("userInfo", JSON.stringify(res.user));
			sessionStorage.setItem("loginToken", JSON.stringify(res._tokenResponse));

			if (existedUser) {
				sessionSave(res.user.uid);
			} else {
				navigate('/registration')
			}

		} catch (err) {
			setIsDisabled(false);
			setError(err.message);
		}
	};

	const changeNumber = () => {
		window.location.reload();
	};

	const onResendOTPClick = () => {
		setOtp('');
		setError('');
		setIsWhatsApp('');
		setResendOTP(true);
		setIsResendOTPAlert(false);
		setIsDisabled(true);
	}

	const handleResendOTP = () => {
		setIsResendOTPAlert(true)
		sentOtpUsingApi(phoneNumber)
	}

	const messageHandler = (e) => {
		setIsDisabled(false);
		if (e.target.value === 'sms') {
			setIsWhatsApp(false)
		} else {
			setIsWhatsApp(true)
		}
	}

	const sentOtpUsingApi = async (number) => {
		try{
			setResendOTP(false);
			setSeconds(30)
			const header = {
				"Content-Type": "application/json"
			};
			const data = { 
				PhoneNumber: number,
				OnWhatsapp: Boolean(isWhatsApp)
			}

			const response = await axiosPost(`${API_BASE_URL}/SendOTP`, data, {headers: header});
		}
		catch(e){
			console.log("error in login ", e)
		}
	}

	const getQRcode = () => {
		axiosGet(`${API_BASE_URL}/GenerateQR`)
		.then((res) => {
			setQRvalue(res.data.Code);
			setConnectId(res.data.Data);
		})
		.catch((err) => {
			console.log(err);
		});
	};

	useEffect(() => {
		if (!isBrowser && !isTablet) {
			if (isAndroid) {
				window.location.href = 'https://play.google.com/store/apps/details?id=yasa.student&hl=en_IN&gl=US';
			} else {
				window.location.href = 'https://onelink.to/39wfhz';
			}
		} 
	}, [])

	useEffect(()=>{
		// getQRcode();
		if (browserName !== 'Chrome') {
			setIsNotChrome(true);
		}
	},[])

	useEffect(()=>{
		if (translations?.length > 0) {
			setIsLoading(false);
		}
	},[translations])

	const countryEventResponse = () => {
		socket?.on('CountryEvent', (data) => {
			const response =  JSON.parse(data);
			const obj = {};
			obj['StatusCode'] = response?.StatusCode;
			obj['IsActiveStudent'] = response?.Data[0]?.IsActiveStudent;
			setCountryEvent(obj);
		})
	}

	useEffect(() => {
		isConnected && countryEventResponse();

	}, [isConnected]);

	if (isLoading) {
		return <Loading />
	}

	return (

		<HomePageContainer>
		{
			countryEvent?.StatusCode === 403 || countryEvent?.StatusCode === 503 || countryEvent?.IsActiveStudent === false ? <Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
					>
					{/* <Box component="form" noValidate sx={{ mt: 1 }}> */}
						<div className="mb-20">
							<h2 className="title2">{countryEvent?.StatusCode === 403 || countryEvent?.IsActiveStudent === false ? translation?.AppRistrictMessage : translation?.AppUnderMaintenanceMsg}</h2>
						</div>
					{/* </Box> */}
				</Box> :
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					{isVerifyingOTP ? (
						<div className="box">
							{error && <Alert className="mb-10" severity="error" onClose={() => setError("")}>{translations[1]?.OtpVerification?.InvalidCode}</Alert>}
							{isResendOTPAlert && <Alert className="mb-10" onClose={() => setIsResendOTPAlert(false)}>{translation.ResendOTP}</Alert>}
								<div className="mb-20">
									<h2 className="title2">{translation?.OTPVerification}</h2>
									<p className="title-info ">
										{translation?.SendVerficationCode}
										<br />
										<span>{phoneNumber.slice(1)}</span>
										<Link onClick={changeNumber} style={{display: 'block'}}>
											{translations[1]?.OtpVerification?.ChangeNumber}
										</Link>
									</p>
									<div>
									<p className="title-info ">
										{/* <span className="d-block">{translation?.DidNotRecieved}</span> */}
										{ seconds > 0 ? <Link disabled={seconds > 0}  data-toggle="tooltip" data-placement="top" title={`You resent OTP after ${seconds}sec`} style={{color:"#1976d2"}}>
											{translation?.ResendOTPIn}<span>&nbsp;</span>
										</Link> : <Link onClick={onResendOTPClick}  style={{color: "#FF5630"}} >
											{translation?.ResendOTP}
										</Link> }  
										<div className="inline-otp-time"> <Timers seconds={seconds} setSeconds={setSeconds}/></div>
									</p>
									
									</div>
									<div id="recaptcha-container1"></div>
									<div id="recaptcha-container2"></div>
									<div id="recaptcha-container3"></div>

								</div>
								<div fullWidth className="mb-20 cis-otp-screen">
								{
									!resendOTP ?
									<OtpInput
										value={otp}
										onChange={handleChange}
										numInputs={6}
										separator={<span>-</span>}
										inputStyle={inputStyle}
									/> : ''
								}
								</div>

								{
									resendOTP ?
									<div fullWidth className="mb-20">
										<div className="otp-check-radio-wrapper">
										<label className="otp-check-radio-box">
											<input type="radio" name="otp-check-radio-box" value="sms" checked={isWhatsApp === false} onChange={messageHandler} />
											<span className="check_bx">
												<SmsOutlinedIcon className="sms_outlined_icon" />
												{translation?.SMSMe}
											</span>
										</label>
										<label className="otp-check-radio-box">
											<input type="radio" name="otp-check-radio-box" value="whatsapp" checked={isWhatsApp === true} onChange={messageHandler} />
											<span className="check_bx">
												<WhatsAppIcon className="whats_app_icon"/>
												{translation?.WhatsAppMe}
											</span>
										</label>
										</div>
									</div> : ''
								}

								{
									resendOTP ?
										<Button
											type="button"
											fullWidth
											variant="contained"
											sx={{
												mt: 3,
												mb: 3,
												pt: 1.5,
												pb: 1.5,
												fontSize: "20px",
												textTransform: "none",
											}}
											size="large"
											className="cis-btn"
											disabled={isDisabled}
											onClick={handleResendOTP}
										>
											{translation?.ResendOTP}
										</Button> : 
										<Button
											fullWidth
											variant="contained"
											sx={{
												mt: 3,
												mb: 3,
												pt: 1.5,
												pb: 1.5,
												fontSize: "20px",
												textTransform: "none",
											}}
											size="large"
											className="cis-btn"
											disabled={isDisabled}
											onClick={verifyOtp}
										>
											{translation?.Login}
										</Button>
								}

						</div>
					) : 
					showQR ? (
						<QRCodeComponent setShowQR={setShowQR} QRvalue={QRvalue} connectId={connectId} setQRvalue={setQRvalue} setConnectId={setConnectId} />
					) : (
						<Box component="form" noValidate sx={{ mt: 1 }}>
							<div className="mb-20">
								<h2 className="title2">{translation?.Login}</h2>
								<p className="title-info ">{translation?.WeWillSendCode}</p>
							</div>
							<div fullWidth className="mb-0">
								<LoginContent
									error={error}
									getOtpFunc={getOtp}
									isApiCalling={isApiCalling}
									buttonLable={translation?.SendOTP}
								/>
							</div>

							{/* <Grid container className="cis-divider-wrap">
								<div className="cis-divider">
									<span>{translation?.OrLoginUsing}</span>
								</div>
							</Grid>
							<div className="mt-0">
								<Button
									variant="outlined"
									fullWidth
									sx={{ pt: 1.5, pb: 1.5 }}
									size="large"
									className="mt-20"
									onClick={() => {
										setShowQR(true);
									}}
								>
									<img src="../../images/Scanthecode.png" alt="alt" /> &nbsp;{" "}
									{translation?.ScanCode}
								</Button>
							</div> */}
						</Box>
					)}
				</Box>
		}
			{isOpenModal && <ErrorModal />}
			{isNotChrome && <WeRecommendChrome setIsNotChrome={setIsNotChrome} message={translation?.WeRecommendChrome} />}
		</HomePageContainer>
	);
};

export default LoginPage;

export const WeRecommendChrome = ({setIsNotChrome, message}) => {
	return (
		<div id="myModal" className="error-modal">
			<div className="error-modal-content">
				<span className="error-modal-close" onClick={() => setIsNotChrome(false)}>
					&times;
				</span>
				<div className="cis-modal-wrap">
					<ErrorOutlineIcon color="error" />
					<p>{message}</p>
				</div>
			</div>
		</div>
	);
};