import React, { useCallback, useEffect, useRef, useState } from "react";

import {
	Button,
	ClickAwayListener,
	FormControlLabel,
	Grow,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	Stack,
	Switch,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import PrivateClassCard from "../PrivateClassCard";
import SessionDetail from "./SessionDetail";
import { API_BASE_URL } from "../../../config/apiConfig";
import { axiosGet, axiosPost } from "../../../services/api";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { decryptData } from "../../../services/encryptDecrypt";

const Search = styled("div")(({ theme }) => ({
	position: "relative",
	borderRadius: theme.shape.borderRadius,
	backgroundColor: alpha(theme.palette.common.white, 0.15),
	"&:hover": {
		backgroundColor: alpha(theme.palette.common.white, 0.25),
	},
	marginLeft: 0,
	width: "100%",
	[theme.breakpoints.up("sm")]: {
		marginLeft: theme.spacing(1),
		width: "auto",
	},
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
	padding: theme.spacing(0, 2),
	height: "100%",
	position: "absolute",
	pointerEvents: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
	color: "inherit",
	"& .MuiInputBase-input": {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create("width"),
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			width: "20ch",
			height: "32px",
			lineHeight: "1.75",
			border: "1px solid #CCCCCC",
			borderRadius: "5px",
			// "&:focus": {
			//   width: "20ch",
			// },
		},
	},
}));

function SessionList({ classDeatilTranslations }) {
	const { encryptClassId } = useParams();
	const classId = decryptData(encryptClassId);
	// API call to fetch All session details

	const [classes, setClasses] = useState();
	const [sessions, setSessions] = useState();

	const getAllCourses = () => {
		axiosGet(`${API_BASE_URL}/GetCourses`).then((response) => {
			setClasses(response.data.Data);
		});
	};
	const getAllSession = () => {
		axiosPost(`${API_BASE_URL}/GetCoursesDetails`, { ClassID: classId })
		.then((response) => {
			setSessions(response.data.Data);
		})
		.catch((error) => {
			console.log(error);
		});
	};
	useEffect(() => {
		getAllCourses();
		getAllSession();
	}, []);

	const classDesc = classes?.find(
		(c) => c?.ID === classId
	)?.ShortDescriptionEnglish;

	const [anchorEl, setAnchorEl] = useState(null);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const options = ["Options 1", "Options 2", "Options 3"];

	const [openDropDown, setOpenDropDown] = useState(false);
	const anchorRef = useRef(null);
	const [selectedIndex, setSelectedIndex] = useState(1);

	const handleMenuItemClick = (event, index) => {
		setSelectedIndex(index);
		setOpenDropDown(false);
	};
	const handleToggle4 = () => {
		setOpenDropDown((prevOpenDropDown) => !prevOpenDropDown);
	};

	const [showDetail, setShowDetail] = useState(false);
	const [sessionData, setSessionData] = useState();
	const handleSessionDetail = (currentSession) => {
		setShowDetail(true);
		setSessionData(currentSession);
	};

	const handleBack = () => {
		setShowDetail(false);
	};

	const [filteredData, setFilteredData] = useState();

	const searchSession = (value) => {
		if (value === "") {
			setFilteredData(sessions);
		} else {
			setFilteredData(
				sessions.filter((item) => {
					return Object.values(item)
						.map((entry) => entry?.toString().toLowerCase())
						.find(
							(v) =>
								v?.substring(0, value.length) === value.toString().toLowerCase()
						);
				})
			);
		}
	};

	const sessionsSortedByDate = sessions?.sort(
		(a, b) => new Date(a.ClassesDate) - new Date(b.ClassesDate)
	);

	const recentlyCompleted = sessionsSortedByDate?.filter(
		(item) => item.SessionStatus === "Complete"
	);

	const upcomingSession = sessionsSortedByDate?.filter(
		(item) => item.SessionStatus === "Schedule"
	);

	const [recent, setRecent] = useState(false);
	const [orderText, setOrderText] = useState("Ascending");
	const initialSessionsOrder = useCallback(() => {
		// const newarray = sessionsSortedByDate?.slice()?.reverse();
		setFilteredData(sessionsSortedByDate);
	}, [sessionsSortedByDate]);
	const sessionInDescendingOrder = useCallback(() => {
		const newarray = sessionsSortedByDate?.slice()?.reverse();
		setFilteredData(newarray);
	}, [sessionsSortedByDate]);

	const showRecent = useCallback(
		(e) => {
			if (!recent) {
				setRecent(true);
				const onGoingSessions = sessions?.filter(
					({ SessionStatus }) => SessionStatus === "On Going"
				);
				const recentSessions = [
					recentlyCompleted[0],
					upcomingSession[0],
					...onGoingSessions,
				];
				setFilteredData(
					recentSessions.filter((session) => session !== undefined)
				);
			} else {
				setRecent(false);
				// setFilteredData(sessions);
				initialSessionsOrder();
			}
		},
		[initialSessionsOrder, recent, recentlyCompleted, upcomingSession]
	);

	const arrangeSessionsOrder = () => {
		setRecent(false);
		console.log(orderText, 'Order Text');
		if (orderText === "Ascending") {
			setOrderText("Descending");
			sessionInDescendingOrder();
		} else {
			setOrderText("Ascending");
			setFilteredData(sessionsSortedByDate);
		}
	};

	useEffect(() => {
		initialSessionsOrder();
	}, [sessions]);

	return (
		<>
			{showDetail ? (
				<SessionDetail
					translations={classDeatilTranslations}
					sessionData={sessionData}
					handleBack={handleBack}
				/>
			) : (
				<div className="schedule-page">
					<Stack direction="row-reverse" spacing={2} className="cis-tab-btns">
						<FormControlLabel
							control={
								<Switch
									size="large"
									checked={recent}
									onChange={showRecent}
									name="recent"
								/>
							}
							label={classDeatilTranslations?.Recent}
							className="cis-label-btn"
						/>
						<Button
							variant="outlined"
							endIcon={<FilterListIcon />}
							onClick={arrangeSessionsOrder}
						>
							{classDeatilTranslations?.[orderText]}
						</Button>

						<Search className="cis-search-wrap">
							<SearchIconWrapper>
								<SearchIcon />
							</SearchIconWrapper>
							<StyledInputBase
								placeholder={classDeatilTranslations?.Search}
								inputProps={{ "aria-label": "search" }}
								onChange={(e) => searchSession(e.target.value)}
							/>
						</Search>
					</Stack>
					<div className="cis-tab-schedule">
						{sessions === undefined ? (
							<div style={{ display: "flex", justifyContent: "center" }}>
								<CircularProgress />
							</div>
						) : (
							filteredData?.map((session) => (
								<PrivateClassCard
									key={session?.ID}
									classId={classId}
									session={session}
									handleSessionDetail={handleSessionDetail}
									classDeatilTranslations={classDeatilTranslations}
								/>
							))
						)}
					</div>
				</div>
			)}
		</>
	);
}

export default SessionList;
