import * as actionTypes from "./actionTypes";
import * as apiCall from "../services/api";
import { API_BASE_URL } from "../config/apiConfig";

const getQuickCallsFavtTutorsSuccess = (data) => {
  return {
    type: actionTypes.GET_FAVT_TUTOR,
    payload: {
      tutors: data.Data,
    },
  };
};

export const getQuickCallsFavtTutors = (data) => async (dispatch) => {
  return await apiCall
    .axiosPost(`${API_BASE_URL}/TutorInformation`, data)
    .then((response) => {
      if (response) {
        dispatch(getQuickCallsFavtTutorsSuccess(response.data));
      }
    })
    .catch((error) => {
      console.log('Error: ', error);
    });
};