import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getClasses } from "../../../actions/classesAction";
import useTranslations from "../../../custom-hooks/useTranslations";
import { decryptData } from "../../../services/encryptDecrypt";

const Overview = ({ messageTeacher, AskYourQuestion, MinTenChar }) => {
  const dispatch = useDispatch();
  const { encryptClassId } = useParams();
  const classId = decryptData(encryptClassId);

  useEffect(() => {
    dispatch(getClasses());
  }, [dispatch]);

  const classList = useSelector(({ classReducer }) => classReducer.classes);
  const currentClass = classList?.find((clas) => {
    return clas.ID.toString() === classId;
  });

  const appLanguage = localStorage.getItem("I18N_LANGUAGE");
  const translations = useTranslations();
  const commonTranslation = translations[21]?.CommonFrontend;

  return (
    <>
      <div className="cis-overview-class">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="cis-classinfo-card mt-0">
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <CardContent sx={{ flex: "1 1 auto", p: 0 }} className="p-0">
                  <Typography component="div" variant="h5" className="mb-5">
                    {currentClass?.Value}
                  </Typography>

                  <p className="my-0">{currentClass?.ShortDescription}</p>
                  <div className="cis-Chips-2 mb-0">
                    <Chip
                      icon={<WatchLaterIcon />}
                      variant="outlined"
                      label={currentClass?.ClassesTimmings}
                    />
                    <Chip
                      icon={<CalendarTodayIcon />}
                      variant="outlined"
                      label={`Starts on ${currentClass?.ClassesDate}`}
                    />
                  </div>

                  {/* <div>
                  <Button
                    variant="outlined"
                    size="large"
                    sx={{ px: 4 }}
                    onClick={() => showcurrentClasss(currentClass.ID)}
                  >
                    Detail
                  </Button>
                </div> */}
                </CardContent>
              </Box>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Box className="cis-teacher-card2">
              <div className="cis-teacher-info">
                <img
                  src={currentClass?.ImageURL}
                  className="cis-teacher-img"
                  alt=""
                />
                <div className="cis-content">
                  <h5>{currentClass?.TutorName}</h5>
                  <Rating
                    name="read-only"
                    value={currentClass?.TutorRating}
                    readOnly
                  />
                  <Button
                    variant="outlined"
                    sx={{ px: 2 }}
                    onClick={messageTeacher}
                  >
                    {commonTranslation?.Message}
                  </Button>
                </div>
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
      {/* <h3 className="cis-title-1 mt-30">{AskYourQuestion}</h3>
      <Box fullWidth>
        <TextField
          className="cis-ask-question-field"
          label=""
          variant="outlined"
          fullWidth
          multiline
          minRows={8}
        />
        <p className="cis-uplod-info text-align-none">
          {MinTenChar}
        </p>
      </Box> */}
    </>
  );
};

export default Overview;
