import React from "react";
import Paper from "@mui/material/Paper";

import LogoutIcon from "@mui/icons-material/Logout";
import { Button, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import useTranslations from "../../custom-hooks/useTranslations";
import useSocket from '../../custom-hooks/useSocket';
import logo from '../../assets/images/logo.png';

const YasaHeader = () => {
  const { isConnected, socket } = useSocket();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const translations = useTranslations();
  const translation = translations[3]?.MenuScreen;
  const quick_calls_header =translations[20]?.WebDashboard?.QuickCalls;
  const hire_tutor_header =translations[20]?.WebDashboard?.Courses;
  const private_classes_header = translations[21]?.CommonFrontend?.PrivateClasses;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    signOut(auth);
    navigate("/login");
  };

  const onLogoClickHandler = ()=> {
		navigate('/');
	};

  const onEditProfileClick = () => {
		navigate('/edit-profile')
	}

  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const studentProfile = JSON.parse(sessionStorage.getItem("ProfileInfo"));
  return (
    <header className="mainHeader" component={Paper} elevation={6}>
      <div className="container">
        <div className="row">
          <div className="col-left">
            <div> 
              <img src={logo} alt="" className="" onClick={onLogoClickHandler}  />
            </div>
          </div>
          <div className="col-right">
            <div className="header-options">
            {(window.location.pathname === "/quick-calls")?"":<Button variant="contained" onClick={()=>navigate("/quick-calls")}>{quick_calls_header}</Button>}
            {(window.location.pathname === "/courses")?"":<Button variant="contained" onClick={()=>navigate("/courses")}>{hire_tutor_header}</Button>}
            {(window.location.pathname === "/private-classes")?"":<Button variant="contained" onClick={()=>navigate("/private-classes")}>{private_classes_header}</Button>}

              <div className="loginuser-menu" onClick={handleClick}>
                <img
                  src={studentProfile?.ProfilePictureUrl ? studentProfile?.ProfilePictureUrl  : userInfo?.photoURL}
                  height="70px"
                  width="71px"
                  alt="profile-pic"
                  className=""
                />
              </div>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className="loginuser-menu-wrap"
              >
               	<MenuItem onClick={() => onEditProfileClick()}>
										<ListItemIcon>
											<PersonOutlineIcon />
										</ListItemIcon>
										{translation?.Profile}
									</MenuItem>
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  {translation?.Logout}
                </MenuItem>
                {/* <MenuItem disablePadding>
                  <ListItemIcon>
                    <img src="../../images/icon/4.svg" alt="" />
                  </ListItemIcon>
                  Get YASAPRO
                </MenuItem> */}
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default YasaHeader;
